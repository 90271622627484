<template>


  <div class="ui sixteen width grid">
    <div class="four wide column">
      <strong> Az árváltozás kezdete:</strong>
      <div :class="{error: 'dateOfChange' in props.errors}" class="field">
        <div class="ui mini input">
          <vee-field name="dateOfChange" type="date" placeholder="Az árváltozás kezdete"/>
        </div>
        <error-message class="error-message" name="dateOfChange"/>
      </div>
    </div>

    <div class="four wide column">
      <strong> Árelemlés mértéke:</strong>
      <div class="ui right labeled input">
        <input v-model="increasePercent" type="text" placeholder="Áremelés mértéke">
        <div class="ui basic label">%</div>
      </div>
    </div>

    <div class="five wide right aligned column">
      <button
          type="button"
          class="ui basic icon teal button"
          @click="applyIncrease">
        <i class="share square icon"/>
        Minden ár másolása
      </button>
    </div>

    <div class="three wide right aligned column">
      <button type="submit" class="ui red icon button">
        <i class="save outline icon"></i>
        Az áremelés rögzítése
      </button>
    </div>
  </div>


</template>

<script setup>

import {useContractPriceListStore} from "@/store-pinia/contractPriceList";
import {ErrorMessage, Field as VeeField} from 'vee-validate'


import {defineProps, ref} from "vue";

const props = defineProps(['errors', 'fields', 'values']);

const increasePercent = ref(0);

const store_contractPrices = useContractPriceListStore();

const applyIncrease = () => {
  const parsedPercent = parseInt(increasePercent.value);
  if (!isNaN(parsedPercent)) {
    store_contractPrices.copyingIncreasedPrices('monthly_fee', parsedPercent);
    store_contractPrices.copyingIncreasedPrices('install_cost', parsedPercent);
    store_contractPrices.copyingIncreasedPrices('service_fee', parsedPercent);
  } else {
    alert("Kérjük, adj meg egy érvényes százalékot!");
  }
}
</script>

<style scoped>
.error-message {
  color: red;
}
</style>