<template>


    <div style=" margin-right: 1rem;">
        <div class="ui sixteen width grid discounts-header">
            <div class="five wide column">Kedvezmény neve</div>
            <div class="three wide column">Kezdete</div>
            <div class="three wide column">Vége</div>
            <div class="three wide column">Mértéke</div>
            <div class="one wide column"></div>
        </div>

        <vee-form class="ui form"
                  @submit="onSubmit"
                  :validation-schema="schema"
                  :initial-values="initialValues"
                  v-slot="{ errors, values}">

            <FieldArray name="discounts" v-slot="{ fields, push, remove }">
                <div v-for="(fields_row, idx) in fields" :key="fields_row.key">
                    <site-setting-form-row :remove="remove" :idx="idx" :errors="errors" @submit="onSubmit(values)"
                                      :values="values"/>
                </div>
                <div class="discount_actions">
                    <div class="ui equal width grid">
                        <div class="column">
                            <button
                                class="ui basic green icon mini button"
                                type="button" @click="push(blankDiscount )">
                                <i class="plus green circle large icon"/>Új kedvezmény
                            </button>
                        </div>
                        <div class=" column">
                            <button
                                class="ui black icon mini button"
                                type="submit">
                                <i class="save circle  large icon"/>Rögzítés
                            </button>
                        </div>
                    </div>
                </div>
            </FieldArray>
        </vee-form>
    </div>
</template>

<script setup>
import {FieldArray, Form as VeeForm} from "vee-validate";
import SiteSettingFormRow
    from "@/components/contract/update/modals/site-setting-discounts/site-setting-discount-form-row.vue";
import * as yup from 'yup';

import {useStore as useVuexStore} from "vuex";
import {useSitesStore} from "@/store-pinia/api/site";

const store_site = useSitesStore();
const vuex_store = useVuexStore();


const schema = yup.object().shape(
    {
        discounts: yup.array().of(
            yup.object().shape(
                {
                    id: yup.number().nullable(),
                    name: yup.string().required('A kedvezmény nevének megadása kötelező'),
                    fromDate: yup.date().typeError('???').required('A kezdő dátum megadása kötelező'),
                    toDate: yup.date().nullable().typeError('???'),
                    amountRate: yup.number()
                        .required('Megadása kötelező')
                        .moreThan(0, 'Min. 0%')
                        .max(100, 'Max. 100%')

                }
            )
        )
    }
);


const initialValues = {
    discounts: vuex_store.state.updateContract.settingSite.discounts
}

const blankDiscount = {
    name: '',
    fromDate: null,
    toDate: null,
    amountRate: 5

}


async function onSubmit(values) {
    vuex_store.commit('updateContract/setShowSiteSettingDiscountsModal', false)
    await store_site.updateSiteDiscounts(vuex_store.state.updateContract.settingSite.id, values.discounts)
}
</script>


<style scoped>
.discount_actions {
    margin-top: 2rem;
}

.discounts-header {
    font-weight: bold;
    font-style: italic;
}

</style>