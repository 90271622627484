<template>

  <Teleport to="body">

    <modal :show="store_page.showModalEditPriceList" :size="'modal-medium'"
           @close="onCloseModal">
      <template #header>
        Árlista tétel szerkesztése
      </template>

      <template #body>
        <div class="modal-body">
          <edit-price-list-item/>
        </div>
      </template>
    </modal>

  </Teleport>


</template>

<script setup>
import Modal from "@/components/slots/modal.vue";
import EditPriceListItem from "@/components/quotationUpdate/edit-pricelist-item.vue"
import {useUpdateQuotationPageStore} from "@/store-pinia/pages/updateQuotationPage";


const store_page = useUpdateQuotationPageStore();

function onCloseModal() {
  store_page.setFocusPriceListElement(null)
  store_page.setShowModalEditPriceList(false)
}


</script>


<style scoped>
.modal-body {
  padding: 1rem;
  min-height: 5rem;
}
</style>