<template>

    <div class="ui sixteen width grid site-info">
        <div class="eight wide column">
            {{ store_site.costsInPeriod.site.name }} {{ store_site.costsInPeriod.site.address }}
        </div>
        <div class="two wide column">
            {{ siteStatusName(store_site.costsInPeriod.site.status) }}
        </div>

        <div class="six wide column">
            <template v-if="store_site.costsInPeriod.site.terminateDate === null">
                {{ store_site.costsInPeriod.site.activateDate }} -től
            </template>
            <template v-else>
                Működött: {{ store_site.costsInPeriod.site.activateDate }}
                - {{ store_site.costsInPeriod.site.terminateDate }} között
            </template>
        </div>
    </div>

</template>


<script setup>

import {siteStatusName} from "@/_helper/siteStatusName";
import {useSitesStore} from "@/store-pinia/api/site";

const store_site = useSitesStore();





</script>

<style scoped>
.site-info{
    font-size: larger;
    font-style: italic;
    color: red;
}

</style>