<template>

  <div class="ui segment">

    <div class="ui negative message">
      <div class="header">
        Kiléptél, nem tudom, hogy kit tisztelhetek benned!
      </div>
      <p>Ezt az oldalt most már nyugodtan bezárhatod!</p>
    </div>


  </div>
</template>

<script>
export default {
  name: "HomePage"
}
</script>

<style scoped>

</style>
