<template>
    <div class="ui celled list mr-1">
        <template v-for="discountSegment in store_site.costsInPeriod.discountSegments" :key="discountSegment.id">
            <discount-segment-row :discountSegment="discountSegment"/>
        </template>

    </div>
</template>


<script setup>
import {useSitesStore} from "@/store-pinia/api/site";
import DiscountSegmentRow from "@/components/contract/update/modals/site-costs-at-period/discount-segment-row.vue";


const store_site = useSitesStore();


</script>

<style scoped>
.mr-1 {
    margin-right: 1rem;
    margin-bottom: 2rem;
}

.table-header {
    font-weight: bold;
    font-style: italic;
}
</style>