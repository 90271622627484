<template>
    <div class="item">
        <div class="ui sixteen width grid">
            <div class="four wide column">
                {{ props.costSegment.name }}
            </div>
            <div class="one wide right aligned column">
                {{ props.costSegment.quantity }} db
            </div>
            <div class="two wide right aligned column">
                {{ numberFormat(props.costSegment.unitPrice, '0') }} Ft/hó
            </div>
            <div class="three wide column">
                {{ props.costSegment.periodFirstDay }} - {{ props.costSegment.periodLastDay }}
            </div>
            <div class="four wide left aligned column">
                <template v-if="props.costSegment.periodType === 'less_than_a_month'">
                    <div class="ui sixteen wide grid">
                        <div class="eight wide right aligned column ">
                            {{ props.costSegment.daysCount }} nap
                        </div>
                        <div class="eight wide right aligned column ">
                            {{ numberFormat(props.costSegment.periodUnitPrice, '0', 1) }} Ft/db
                        </div>
                    </div>
                </template>

                <template v-if="props.costSegment.periodType === 'exactly_months'">
                    <div class="ui sixteen wide grid">
                        <div class="eight wide right aligned column ">
                            {{ props.costSegment.wholeMonthsCount }} hónap
                        </div>
                        <div class="eight wide column">

                        </div>
                    </div>
                </template>
            </div>
            <div class="two wide right aligned column">
                {{ numberFormat(props.costSegment.payablePriceOfPeriod, '0', 1) }} Ft
            </div>
        </div>
    </div>


</template>


<script setup>
import {defineProps} from "vue";
import {numberFormat} from "@/_helper/numberFormat";


const props = defineProps(['costSegment'])


</script>

