<template>

    <vee-form class="ui form"
              @submit="onSubmit"
              :validation-schema="schema"
              :initial-values="initialValues"
              v-slot="{ errors, values}">

        <FieldArray name="need_costs" v-slot="{ fields, push, remove }">
            <div v-for="(fields_row, idx) in fields" :key="fields_row.key">
                <add-new-cost-form-row :remove="remove" :idx="idx" :errors="errors" @submit="onSubmit(values)"
                                       :values="values"/>

            </div>

            <div class="need_actions">
                <button
                        class="ui basic green icon mini button"
                        type="button" @click="push({ priceListItem: '', quantity: 1} )">
                    <i class="plus green circle large icon"/>Új tétel
                </button>

                <button
                        class="ui basic orange icon mini button"
                        type="button"
                        @click="onSubmit(values)">
                    <i class="calculator orange large icon"/>Újraszámol
                </button>


            </div>


        </FieldArray>


        <div v-if="false">
            <pre>{{ errors }}</pre>
        </div>


    </vee-form>

</template>

<script setup>
import {FieldArray, Form as VeeForm} from "vee-validate";
import AddNewCostFormRow from "@/components/contract/update/modals/add-new-cost/add-new-cost-form-row.vue"

import * as yup from "yup";

import {useCalculationStore} from "@/store-pinia/calculation";

const schema = yup.object({
    need_costs: yup.array().of(
        yup.object().shape(
            {
                priceListItem: yup.string().required('Megadása kötelező!'),
                quantity: yup.string().required('Megadása kötelező')
            })
    ),
})

const initialValues = {
    need_costs: [
        {
            priceListItem: '',
            quantity: 1,
        }
    ],
}

function onSubmit(values) {

    console.log('values', values)

    const store_calculation = useCalculationStore()
    store_calculation.setNeeds(values.need_costs)
    store_calculation.calculate()

    //store.calculate(values)
}


</script>


<style scoped>

.need_actions {
    padding-top: 1rem;
}

</style>