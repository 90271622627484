<template>
  <div style="margin-top: 2rem;">
    <div class="field" :class="{error: 'mainEmail' in errors}">
      <label>Email-cím</label>
      <div class="ui input">
        <vee-field type="email" placeholder="Email-cím" name="mainEmail"/>
      </div>
      <error-message name="mainEmail" class="error-message"/>
    </div>
  </div>
</template>

<script>
import {Field, ErrorMessage} from 'vee-validate'
export default {
  name: "customerMainEmailFormField",
  props: ['errors', 'fields'],
  components: {
    VeeField: Field, ErrorMessage
  }
}
</script>

<style scoped>
.error-message {
  color: red;
}
</style>
