<template>


    <div style=" margin-right: 1rem;">
        <div class="ui sixteen width grid commissions-header">
            <div class="five wide column">Üzletkötő</div>
            <div class="four wide column">Kezdete</div>
            <div class="four wide column">Vége</div>
            <div class="two wide column">Mértéke</div>
            <div class="one wide column"></div>
        </div>

        <vee-form class="ui form"
                  @submit="onSubmit"
                  :validation-schema="schema"
                  :initial-values="initialValues"
                  v-slot="{ errors, values}">

            <FieldArray name="commissions" v-slot="{ fields, push, remove }">
                <div v-for="(fields_row, idx) in fields" :key="fields_row.key">
                    <form-row :remove="remove" :idx="idx" :errors="errors" @submit="onSubmit(values)"
                              :values="values"
                    />
                </div>
                <div class="commission_actions">
                    <div class="ui equal width grid">
                        <div class="column">
                            <button
                                class="ui basic green icon mini button"
                                type="button" @click="push(blankCommission )">
                                <i class="plus green circle large icon"/>Új jutalék
                            </button>
                        </div>
                        <div class=" column">
                            <button
                                class="ui black icon mini button"
                                type="submit">
                                <i class="save circle  large icon"/>Rögzítés
                            </button>
                        </div>
                    </div>
                </div>
            </FieldArray>
        </vee-form>
    </div>
</template>

<script setup>
import {FieldArray, Form as VeeForm} from "vee-validate";
import FormRow from "@/components/contract/update/modals/site-setting-commissions/site-setting-commission-from-row.vue";
import * as yup from 'yup';

import {useStore as useVuexStore} from "vuex";
import {useSitesStore} from "@/store-pinia/api/site";

const store_site = useSitesStore();
const vuex_store = useVuexStore();


const schema = yup.object().shape(
    {
        commissions: yup.array().of(
            yup.object().shape(
                {
                    id: yup.number().nullable(),
                    salesManId: yup.number().typeError('???').required('Az üzletkötő nevét meg kell admin'),
                    fromDate: yup.date().typeError('???').required('A kezdő dátum megadása kötelező'),
                    toDate: yup.date().nullable().typeError('???'),
                    amountRate: yup.number()
                        .required('Megadása kötelező')
                        .moreThan(0, 'Min. 0%')
                        .lessThan(100, 'Max. 100%')
                }
            )
        )
    }
);


const initialValues = {
    commissions: vuex_store.state.updateContract.settingSite.commissions
}


const blankCommission = {
    salesManId: null,
    fromDate: null,
    toDate: null,
    amountRate: 5

}


async function onSubmit(values) {
    vuex_store.commit('updateContract/setShowSiteSettingCommissionsModal', false)
    await store_site.updateSiteCommissions(vuex_store.state.updateContract.settingSite.id, values.commissions)
}
</script>


<style scoped>
.discount_actions {
    margin-top: 2rem;
}

.commissions-header {
    font-weight: bold;
    font-style: italic;
}

</style>


