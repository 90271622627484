<template>

    <Teleport to="body">

        <modal :show="show()" :size="'modal-medium'"
               @close="onCloseModal">
            <template #header>Jutalék beállítások</template>
            <template #body>
                <div class="ui segment">
                    <site-setting-commission-form/>
                </div>

            </template>
        </modal>

    </Teleport>


</template>

<script setup>
import Modal from "@/components/slots/modal.vue"
import {useStore as useVuexStore} from "vuex";
import SiteSettingCommissionForm from "@/components/contract/update/modals/site-setting-commissions/site-setting-commission-form.vue";

const vuex_store = useVuexStore()


function show() {
    return vuex_store.state.updateContract.showSiteSettingCommissionsModal;
}

function onCloseModal() {
    vuex_store.commit('updateContract/setShowSiteSettingCommissionsModal', false)
}


</script>


le>