<template>

    <vee-form class="ui form"
              @submit="onConditionsSubmit"
              :validation-schema="schema"
              :initial-values="initialValues"
              v-slot="{errors}">

        <div class="ui sixteen width grid">
            <div class="eight wide column">
                <div class="ui segment">
                    <div class="field" :class="{error: `dateOfContract` in errors}">
                        <h5 class="ui header">Szerződéskötési dátum</h5>
                        <div class="ui input">
                            <vee-field type="date" name="dateOfContract"/>
                        </div>
                        <error-message name="dateOfContract" class="error-message"/>
                    </div>

                    <div class="two fields">
                        <div class="field" :class="{error: 'isTrial' in errors}">
                            <h5 class="ui header">Próba szerződés?</h5>
                            <div class="ui input">
                                <vee-field as="select" class="ui fluid dropdown" name="isTrial">
                                    <option value="false">Nem</option>
                                    <option value="true">Igen, próba szerződés</option>

                                </vee-field>
                            </div>
                            <error-message name="isTrial" class="error-message"/>
                        </div>
                        <div class="field" :class="{error: 'loyaltyName' in errors}">
                            <h5 class="ui header">Hűségidő</h5>
                            <div class="ui input">
                                <vee-field as="select" class="ui fluid dropdown" name="loyaltyName">
                                    <option value="loyalty_year_1">1 év</option>
                                    <option value="loyalty_year_2">2 év</option>
                                    <option value="loyalty_year_3">3 év</option>
                                </vee-field>
                            </div>
                            <error-message name="loyaltyName" class="error-message"/>
                        </div>
                    </div>
                </div>
            </div>

            <div class="eight wide column">
                <div class="ui segment">

                    <div class="field" :class="{error: 'updateCustomerFromOfficialSource' in errors}">
                        <h5 class="ui header">Frissítés (Opten/Bisnode)/</h5>
                        <div class="ui input">
                            <vee-field as="select" class="ui fluid dropdown"
                                       name="updateCustomerFromOfficialSource">
                                <option value="true">Frissítve lesz</option>
                                <option value="false">Nincs frissítés</option>

                            </vee-field>
                        </div>
                        <error-message name="updateCustomerFromOfficialSource" class="error-message"/>
                    </div>
                    <div class="field" :class="{error: 'mainEmail' in errors}">
                        <h5 class="ui header">A cég hivatalos e-mail címe:</h5>
                        <div class="ui input">
                            <vee-field type="text" name="mainEmail"/>
                        </div>
                        <error-message name="mainEmail" class="error-message"/>
                    </div>

                </div>
            </div>

        </div>


        <div class="ui segment">
            <div class="actions">
                <button class="ui black deny button" @click="onCloseModal">Mégsem</button>

                <button type="submit" class="ui positive right labeled icon button">
                    Mentés <i class="checkmark icon"></i>
                </button>

            </div>
        </div>


    </vee-form>


</template>


<script setup>
import {computed, defineEmits} from 'vue';
import {useStore} from 'vuex';
import {ErrorMessage, Field as VeeField, Form as VeeForm} from 'vee-validate';
import * as yup from 'yup';

const store = useStore();
const emit = defineEmits(['close']);

const contract = computed(() => store.state.updateContract.contract);

const initialValues = computed(() => ({
    dateOfContract: contract.value.dateOfContract,
    isTrial: contract.value.isTrial,
    billingDayOfMonth: contract.value.billingDayOfMonth,
    loyaltyName: contract.value.loyalty,
    updateCustomerFromOfficialSource: contract.value.updateCustomerFromOfficialSource,
    mainEmail: contract.value.customer.mainEmail
}));

const schema = yup.object().shape(
    {
        dateOfContract: yup.date().required('A szerződéskötés dátuma szükséges'),
        isTrial: yup.string().required('Megadása kötelező'),
        loyaltyName: yup.string().required('A hűségidő megadása kötelező!'),
        updateCustomerFromOfficialSource: yup.boolean(),
        mainEmail: yup.string().email('Ez nem  jó e-mail cím!').required('Az email cím megadása kötelező')
    }
);


function onCloseModal() {
    store.commit('updateContract/setShowEditConditionsAModal', false)
    emit('close')
}


function onConditionsSubmit(values) {
    const send_data_contract = {
        id: store.state.updateContract.contract.id,
        ...values
    }

    store.dispatch('updateContract/updateContract', send_data_contract)
    store.commit('updateContract/setShowEditConditionsAModal', false)
    emit('close')
}
</script>


<style scoped>
.form {
    margin-right: 2rem;
}

.higher-permission {
    color: blue
}
</style>