<template>
    <template v-if="store.quotation">

        <condition-list-item>
            <template v-slot:name>Árajánlat száma:</template>
            <template v-slot:value>
                <strong>
                    {{ store.quotation.registrationNumber }}
                </strong>
            </template>
        </condition-list-item>

        <condition-list-item>
            <template v-slot:name>Ügyfél:</template>
            <template v-slot:value>{{ store.quotation.opportunity.account.customer.name }}
            </template>
        </condition-list-item>

        <condition-list-item>
            <template v-slot:name>Székhelye:</template>
            <template v-slot:value>
                {{ store.quotation.opportunity.account.customer.mainAddress.postalCode }}
                {{ store.quotation.opportunity.account.customer.mainAddress.city }}
                {{ store.quotation.opportunity.account.customer.mainAddress.street }}
            </template>
        </condition-list-item>

        <condition-list-item>
            <template v-slot:name>Készült:</template>
            <template v-slot:value>
                <template v-if="store.quotation.source === 'contract-prices' ">
                    Egyedi árlista alapján
                </template>
                <template v-if="store.quotation.source === 'mother-prices' ">
                    Anya árlista alapján
                </template>
            </template>
        </condition-list-item>

        <condition-list-item>
            <template v-slot:name>Árajánlat típusa:</template>
            <template v-slot:value>
                <div class="ui big yellow label" style="color: black !important">
                    {{ store.quotation.product.name }}
                </div>
            </template>
        </condition-list-item>

    </template>
</template>

<script setup>

import {useApiQuotationStore} from "@/store-pinia/api/quotations";
import ConditionListItem from "@/components/contract/create/checkOneCondition.vue";


const store = useApiQuotationStore();

</script>

<style scoped>

</style>
