<template>

    <div class="ui segment">
        <div class="ui middle aligned divided list">
            <condition-item @click.stop="editPersonRole('delegatePerson')">
                <template v-slot:name>
                    <i class="eye dropper icon"/> Aláíró személy
                </template>
                <template v-slot:value>
                    <person-info :person="contract.delegatePerson"/>
                </template>
            </condition-item>

            <condition-item @click.stop="editPersonRole('financialPerson')">
                <template v-slot:name>
                    <i class="dollar sign icon"/> Pénzügyi kapcsolattartó
                </template>
                <template v-slot:value>
                    <person-info :person="contract.financialPerson"/>
                </template>
            </condition-item>

            <condition-item @click.stop="editPersonRole('technicalPerson')">
                <template v-slot:name>
                    <i class="wrench icon"/>Műszaki kapcsolattartó
                </template>
                <template v-slot:value>
                    <person-info :person="contract.technicalPerson"/>
                </template>
            </condition-item>
        </div>
    </div>




</template>




<script setup>

import ConditionItem from "@/components/contract/create/checkOneCondition.vue";
import PersonInfo from "@/components/contract/update/person-info.vue";
import {useStore as useVuexStore} from "vuex";
import {computed} from "vue";

const store_vuex = useVuexStore();

const contract = computed(() => store_vuex.state.updateContract.contract);

function editPersonRole(role) {
    store_vuex.commit('updateContract/setEditedPersonRole', role);
    store_vuex.commit('updateContract/setShowEditPersonModal', true)
}




</script>


<style scoped>

</style>