<template>
  <div v-if="cost.hasPriceChange" class="ui mini yellow label">
    <span style="color:black;">
    Árváltozás!
    </span>
    <i class="ui exclamation triangle red icon"/>
  </div>

  <div v-if="cost.status ==='new' " class="ui tiny grey label">
    {{ costStatusName(props.cost.status) }}
  </div>

  <div v-if="cost.status ==='active' || cost.status === 'terminate_requested'" class="ui tiny green label">
    {{ costStatusName('active') }}
    {{ props.cost.activateDate }}
  </div>

  <div v-if="cost.status === 'terminate_requested'" class="ui tiny purple label">
    {{ costStatusName(props.cost.status) }}
    {{ props.cost.earliesTerminateDate }}
  </div>


  <div v-if="cost.status ==='terminated' " class="ui tiny red label">
    {{ costStatusName(props.cost.status) }}
    {{ props.cost.activateDate }} {{ props.cost.terminateDate }}
  </div>


  <div v-if="cost.status ==='completed' " class="ui tiny blue label">
    {{ costStatusName(props.cost.status) }}
    {{ props.cost.completedDate }}
  </div>


  <div v-if="cost.status ==='billed' " class="ui tiny green label">
    {{ costStatusName(props.cost.status) }}
    {{ props.cost.billedDate }}
  </div>

</template>

<script setup>
import {costStatusName} from "@/_helper/costStatusName";
import {defineProps} from "vue";

const props = defineProps(['cost']);

</script>
