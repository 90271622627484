import {defineStore} from "pinia";
import axios from "axios";
import setupAuthHeaderFor from "@/_helper/setupAxiosHeader";
import {useErrorsStore} from "../errors";
import {useOroStore} from "@/store-pinia/oro";


const url_products = '/api/adminproducttypeentities'

export const useProductsStore = defineStore({
    id: 'products',
    state: () => {
        return {
            product: null,
            products: []
        };
    },

    actions: {
        setProduct(product) {
            this.product = product
        },
        setProducts(products_arr) {
            this.products = products_arr
        },

        async fetchProducts() {
            const store_oro = useOroStore();
            const store_errors = useErrorsStore();

            const url = `${store_oro.host}${url_products}`;

            const axiosConfig = setupAuthHeaderFor('wsse');

            await axios.get(url, axiosConfig)
                .then((response) => {
                    this.setProducts(response.data)
                })
                .catch(err => {
                    const error = {
                        code: err.response.status,
                        message: err.message,
                        message_public: 'A termékek elérése sikertelen!'
                    }
                    store_errors.addError(error)
                })
        },

    },

    getters: {},
});
