import {defineStore} from "pinia";
import axios from "axios";
import {useErrorsStore} from "./errors";
import {useOroStore} from "@/store-pinia/oro";
import setupAuthHeaderFor from "@/_helper/setupAxiosHeader";


const url_sites = '/api/contractitems';
const url_contract_info = '/api/contract-info';
const url_re_contract = '/api/re-contract-site-to-another-customer';


export const useReContractSiteStore = defineStore({
    id: 'reContractSite',
    state: () => {
        return {
            searchSite: '',
            activeStep: 1,
            foundSites: [],
            oldSite: null,
            oldSiteContract: null,
            newSiteContract: null,
            showModal: false,
            reContractDate: null,
            sending: false,
        };
    },
    actions: {
        setSearchSite(search_site_str) {
            this.searchSite = search_site_str
        },
        setActiveStep(active_step) {
            this.activeStep = active_step;
        },
        setFoundSites(sites_arr) {
            this.foundSites = sites_arr
        },
        setShowModal(show_modal) {
            this.showModal = show_modal
            if (!show_modal) {
                this.resetValues()
            }
        },
        setReContractDate(re_contract_date) {
            this.reContractDate = re_contract_date
        },
        setSending(sending_value) {
            this.sending = sending_value
        },
        selectNewSite() {
            this.setActiveStep(1);
            this.setSearchSite('');
            this.setFoundSites([]);
        },
        setOldSite(old_site) {

            this.oldSite = old_site
            if (this.oldSite) {
                this.setActiveStep(2)
                this.fetchContract(old_site.contract)
            }
        },
        setNewSiteContract(new_site_contract) {
            this.newSiteContract = new_site_contract
        },
        setOldSiteContract(old_site_contract) {
            this.oldSiteContract = old_site_contract
        },

        resetValues() {
            if (this.showModal) {
                this.setShowModal(false);
            }
            this.setActiveStep(1);
            this.setSearchSite('');
            this.setFoundSites([]);
            this.setSending(false);
            this.setOldSite(null);
            this.setOldSiteContract(null);
            this.setReContractDate(null);
        },

        async fetchSites() {

            const store_errors = useErrorsStore();
            const store_oro = useOroStore();


            const q = {
                'filter[nameAtContract][contains]': this.searchSite
            }

            const queryString = new URLSearchParams(q).toString();

            const url = `${store_oro.host}${url_sites}?${queryString}`

            const axiosConfig = setupAuthHeaderFor('wsse');
            await axios.get(url, axiosConfig)
                .then((response) => {
                    this.setFoundSites(response.data)
                })
                .catch(err => {
                    const error = {
                        code: err.response.status,
                        message: err.message,
                        message_public: 'A telephelyek elérése sikertelen!'
                    }
                    store_errors.addError(error)
                })
        },

        async fetchContract(contract_id) {
            const store_errors = useErrorsStore();
            const store_oro = useOroStore();

            const url = `${store_oro.host}${url_contract_info}/${contract_id}`

            const axiosConfig = setupAuthHeaderFor('wsse');
            axios.get(url, axiosConfig)
                .then((response) => {
                    this.setOldSiteContract(response.data)
                })
                .catch(err => {
                    const error = {
                        code: err.response.status,
                        message: err.message,
                        message_public: 'A telephelyhez tartozó szerződés elérése sikertelen!'
                    }
                    store_errors.addError(error)
                })
        },


        async createReContract(re_contract_data) {
            const store_errors = useErrorsStore();
            const store_oro = useOroStore();

            const url = `${store_oro.host}${url_re_contract}`

            const axiosConfig = setupAuthHeaderFor('wsse');
            this.setSending(true);

            await axios.post(url, re_contract_data, axiosConfig)
                .then((response) => {
                    if (response.data.error) {
                        store_errors.addError({
                            code: 400,
                            message: '',
                            message_public: 'Hibás bemeneti adatok miatt az átszerződés sikertelen!'
                        })
                    }
                    this.resetValues()
                })
                .catch(err => {
                    const error = {
                        code: err.response.status,
                        message: err.message,
                        message_public: 'Az átszerződés sikertelen!'
                    }
                    store_errors.addError(error)
                    this.resetValues()
                })
        }
    },
    getters: {},
});
