<template>
    <div class="ui segment">
        <div class="ui two column very relaxed stackable grid">
            <div class="column">
                <person-form-for-role
                    :action="{label:'Módosítás'}"
                    :person="editedPerson()"
                />

            </div>
            <div class="middle aligned column">
                <person-form-for-role
                    :action="{label:'Létrehozás'}"
                    :person="{name: '',emails: [],phones: []}"
                />
            </div>
        </div>
        <div class="ui vertical divider">
            VAGY
        </div>
    </div>
</template>


<script setup>
import PersonFormForRole from "@/components/contract/update/person-form-for-role.vue";
import {useStore as useVuexStore} from "vuex";

const vuex_store = useVuexStore();

function editedPerson() {
    const role = vuex_store.state.updateContract.editedPersonRole;
    return vuex_store.state.updateContract.contract[role]
}


</script>


<style scoped>


.segment {
    margin-right: 2rem;
    background-color: rgb(249,250,251)
}


</style>