<template>
  <div class="ui segment">

    <h3>Telepítési hely</h3>
    <div class="ui three fields">
      <div class="field" :class="{error: `name` in props.errors}">
        <label>Név</label>
        <div class="ui input">
          <vee-field type="text" name="name"/>
        </div>
        <error-message name="name" class="error-message"/>
      </div>

      <div class="field" :class="{error: 'city' in props.errors}">
        <label>Település</label>
        <div class="ui input">
          <vee-field type="text" name="city"/>
        </div>
        <error-message name="city" class="error-message"/>
      </div>

      <div class="field" :class="{error: `street` in props.errors}">
        <label>Utca, házszám</label>
        <div class="ui input">
          <vee-field type="text" name="street"/>
        </div>
        <error-message name="street" class="error-message"/>
      </div>

    </div>
  </div>
</template>

<script setup>
import {Field as VeeField, ErrorMessage} from 'vee-validate'
import {defineProps} from "vue";

const props = defineProps(['errors']);


</script>

<style scoped>
.error-message {
  color: red;
}
</style>
