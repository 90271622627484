<template>
  <div class="ui secondary  menu">

    <div class="item">

    </div>


<!--    <div class="item">-->
<!--      <router-link to="/quotation/create">-->
<!--        Árajánlat készítése-->
<!--      </router-link>-->
<!--    </div>-->


<!--    <div class="item">-->
<!--      <router-link to="/contract/create">-->
<!--        Szerződés készítése-->
<!--      </router-link>-->
<!--    </div>-->

<!--    <div class="item">-->
<!--      <router-link to="/contract/update/">-->
<!--        Szerződés szerkesztése-->
<!--      </router-link>-->
<!--    </div>-->

<!--    <div class="item">-->
<!--      <router-link to="/test/">-->
<!--        TESZ OLDAL-->
<!--      </router-link>-->
<!--    </div>-->


    <div class="right aligned item">
      {{ currentUserName }}
    </div>

    <div class="right aligned item">
      <button class="ui red mini button" @click="logout">
        <i class="sign-out icon"/>
        Kijelentkezés
      </button>
    </div>

  </div>


</template>

<script>

import {mapState} from "vuex";

export default {
  name: "MainMenu",
  computed: {
    ...mapState({
      currentUser: state => state.app.currentUser
    }),
    currentUserName() {
      return this.currentUser
          ? this.currentUser.lastName + ' ' + this.currentUser.firstName + ' (' + this.currentUser.email + ') '
          : ''
    }
  },
  methods:{
    logout(){
      this.$store.dispatch('app/logout')
    }
  }
}
</script>

<style scoped>

</style>
