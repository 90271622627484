<template>

    <vee-form class="ui form"
              @click.stop
              @submit="onShowContractAppendix"
              :validation-schema="schema"
              :initial-values="initialValues"
              v-slot="{errors}">


        <div class="ui sixteen wide  middle aligned grid">

            <div class="six wide column">
                <div class="field" :class="{error: 'periodStart' in errors}">
                    <h5 class="ui header">Időszak kezdete</h5>
                    <div class="ui input">
                        <vee-field type="date" name="periodStart"/>
                    </div>
                    <error-message name="periodStart" class="error-message"/>
                </div>
            </div>

            <div class="six wide column">
                <div class="field" :class="{error: 'periodEnd' in errors}">
                    <h5 class="ui header">Időszak vége</h5>
                    <div class="ui input">
                        <vee-field type="date" name="periodEnd"/>
                    </div>
                    <error-message name="periodEnd" class="error-message"/>
                </div>
            </div>

            <div class="four wide  right aligned column">
                <button type="submit" class="ui primary mini basic button">Megtekint</button>
            </div>

        </div>


    </vee-form>
</template>


<script setup>
import {ErrorMessage, Field as VeeField, Form as VeeForm} from 'vee-validate';
import * as yup from "yup";
import {computed} from "vue";
import {useContractStore} from "@/store-pinia/api/contract";
import {useStore as useVuexStore} from 'vuex';

const store_contract = useContractStore()
const vuex_store = useVuexStore();

const initialValues = computed(() => ({
    periodStart: '',
    periodEnd: ''
}));


const schema = yup.object().shape({
        periodStart: yup.date()
            .typeError('???')
            .required('Megadása kötelező!'),
        periodEnd: yup.date()
            .typeError('???')
            .required('Megadása kötelező!')
            .min(yup.ref('periodStart'), 'A befejező dátum nem lehet korábbi, mint a kezdő dátum!'),
    }
);


function onShowContractAppendix(values) {
    store_contract.fetchSitesAtPeriod(values);
    vuex_store.commit('updateContract/setShowSitesInPeriodModal', true);
}

</script>


<style scoped>
.error {
    color: red;
}

</style>