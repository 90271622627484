<template>

    <div class="ui grid ">
        <div class="eleven wide column">


            <div class="field">
                <div class="ui input">
                    <vee-field as="select"
                               class="ui fluid dropdown"
                               :class="{error: `need_items[${props.idx}].id` in props.errors}"
                               :name="`need_items[${props.idx}].id`"
                               @change="$emit('submit')"
                               @blur="$emit('submit')">
                        <option value=""></option>

                        <option v-for="priceList_item in store_detailing.availableCosts" :value="priceList_item.id"
                                :key="priceList_item.id">

                            <template>
                                {{ priceList_item.name }}&nbsp;&nbsp;
                            </template>

                            <template v-if="false">
                                source: {{ priceList_item.source }}
                                deprecated: {{ priceList_item.deprecated }}
                                used: {{ priceList_item.used}}
                                optional: {{priceList_item.optional}}
                            </template>

                            <template v-if="priceList_item.categoryName === 'monthly_fee'">&nbsp;-----&nbsp;Havidíj:
                                {{ priceList_item.unitPriceMonthly }} Ft/hó&nbsp;&nbsp;
                            </template>

                            <template v-if="priceList_item.categoryName === 'install_cost'">&nbsp;------&nbsp;
                                Felszerelési díj: {{ priceList_item.unitPriceOneTime }} Ft/db&nbsp;&nbsp;
                            </template>

                            <template v-if="priceList_item.source === '2'">
                                &nbsp;-----&nbsp; (saját felvitel) &nbsp;&nbsp;
                            </template>

                            <template v-if="priceList_item.source === '3'">
                                &nbsp;-----&nbsp; HASZNÁLATA KERÜLENDŐ &nbsp;-----&nbsp;
                            </template>

                        </option>
                    </vee-field>
                </div>

                <error-message :name="`need_items[${props.idx}].id`" class="error-message"/>


            </div>
        </div>

        <div class="four wide column">

            <div class="field">
                <div class="ui input">
                    <vee-field
                            :class="{error: `need_items[${props.idx}].quantity` in props.errors}"
                            :name="`need_items[${props.idx}].quantity`"
                            @change="$emit('submit')"
                            @blur="$emit('submit')">
                    </vee-field>
                </div>
                <error-message :name="`need_items[${props.idx}].quantity`" class="error-message"/>
            </div>
        </div>

        <div class="one wide center aligned column">
            <i class="trash red  icon" @click="removeItem(props.idx)"/>
        </div>


    </div>


    <pre v-if="false">
        {{ store_detailing.availableCosts }}
    </pre>

</template>

<script setup>
import {ErrorMessage, Field as VeeField} from "vee-validate";

import {defineProps} from "vue";
import {useCostDetailingStore} from "@/store-pinia/costsDetailing";

const props = defineProps(['remove', 'idx', 'errors', 'values']);
const store_detailing = useCostDetailingStore()


function removeItem(idx) {
    props.remove(idx)
    store_detailing.calculate(props.values)
}


</script>

<style scoped>
.error-message {
    color: red
}

</style>