<template>

    <div class="title"
         :class="{
          active: index === this.$store.state.updateContract.activeAccordionIndex,
          active_accordion: index === this.$store.state.updateContract.activeAccordionIndex
     }"
         @click="toggleActive(index)">

        <div class="ui sixteen width grid">
            <div class="five wide column">
                <i class="dropdown icon"></i>
                {{ site.nameAtContract }} {{ site.address.city }}, {{ site.address.street }}
            </div>

            <div class="three wide center aligned column">
                <div class="ui equal width grid">
                    <div class="right aligned column">
                        {{ site.priceOneTimeToday.toLocaleString() }} Ft
                    </div>

                    <div class="right aligned column">
                        <span class="monthly-fee">
                            {{ site.priceMonthlyToday.toLocaleString() }} Ft/hó
                        </span>
                    </div>
                </div>
            </div>

            <div class="five wide column">
                <div class="ui sixteen width grid">
                    <div class="eight wide center aligned column">
                        <div v-if="site.status==='new' " :class="statusClass">
                            {{ siteStatusName(site.status) }}
                        </div>

                        <div v-if="site.status==='active' " :class="statusClass">
                            {{ siteStatusName(site.status) }}: {{ site.activateDate }}
                        </div>

                        <div v-if="site.status==='terminated' " :class="statusClass">
                            {{ siteStatusName(site.status) }}: {{ site.activateDate }} - {{ site.terminateDate }}
                        </div>
                    </div>

                    <div class="eight wide center aligned column">
                        <div class="ui tiny yellow label my-label" style="color: red !important">
                            <template
                                v-if="site.billingInfo.lastInvoicedPeriodStartDate && site.billingInfo.lastInvoicedPeriodEndDate">
                                {{ site.billingInfo.lastInvoicedPeriodStartDate }} -
                                {{ site.billingInfo.lastInvoicedPeriodEndDate }}
                            </template>
                            <template v-else>
                                Még nem volt
                            </template>
                        </div>
                    </div>
                </div>
            </div>

            <div class="three wide column">
                <div class="ui sixteen width grid">
                    <div class="nine wide column">

                        <template v-if="site.service && false">
                            <i class="thumbs up outline green icon pointerCursor"
                               @click.stop="toServiceEdit(site)"></i>
                        </template>

                        <template v-if="site.status === 'new'">
                            <i class="trash icon pointerCursor"
                               @click.stop="deleteNewSite(site)"></i>
                        </template>

                        <template v-if="site.status === 'active' && !site.terminateRequested">
                            <i class="thumbs down outline red icon pointerCursor"
                               @click.stop="siteTerminateRequest(site)"></i>
                        </template>

                        <i class="edit icon pointerCursor"
                           @click.stop="editSite(site)"></i>

                        <i class="user icon pointerCursor"
                           @click.stop="setStartCommissionSetting(site)"></i>

                        <i class="euro sign icon pointerCursor"
                           @click.stop="setStartDiscountSetting(site)"></i>
                    </div>

                    <div class="seven wide right aligned column">
                        <div class="ui teal horizontal mini label ">#{{ site.billingInfo.invoiceGroup }}</div>

                        <span class="pointer" @click.stop="toggleHideTerminatedCosts(site)">
                                <i v-if="site.hideTerminatedCosts" class="expand blue icon pointer"/>
                                <i v-else class="check squared blue icon pointer"/>
                            </span>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="index === this.$store.state.updateContract.activeAccordionIndex"
             class="sixteen wide  column">
            <site-title-second-line :site="site"/>
        </div>

    </div>
</template>

<script>
import {siteStatusName} from "@/_helper/siteStatusName";
import {mapState} from "vuex";
import SiteTitleSecondLine from "@/components/contract/update/site-tite-second-line.vue";

export default {
    name: "accordion-title",
    props: ['site', 'index'],
    emits: ['openEditSiteModal'],
    components: {SiteTitleSecondLine},
    computed: {

        ...mapState({
            contract: state => state.updateContract.contract,
            preferredProductTypeId: state => state.app.preferredProductTypeId
        }),
        statusClass() {
            const today = new Date();
            const activateDate = new Date(this.site.activateDate);
            const terminateDate = this.site.terminateDate ? new Date(this.site.terminateDate) : null;

            if (this.site.status === 'new') {
                return 'ui tiny grey label';
            }

            if (this.site.status === 'active') {
                if (activateDate > today) {
                    return 'ui tiny blue label';
                }
                return 'ui tiny green label';
            }

            if (this.site.status === 'terminated') {
                if (terminateDate && terminateDate > today) {
                    return 'ui tiny grey label';
                }
                return 'ui tiny red label';
            }

            return '';
        }
    },
    methods: {
        toggleActive(index) {
            if (this.$store.state.updateContract.activeAccordionIndex === index) {
                this.$store.commit('updateContract/setActiveAccordionIndex', -1)
            } else {
                this.$store.commit('updateContract/setActiveAccordionIndex', index)
            }
        },
        toggleHideTerminatedCosts(site) {
            site.hideTerminatedCosts = !site.hideTerminatedCosts;
        },

        onBillingInfoSubmit(values) {
            alert(JSON.stringify(values, null, 2));
        },


        deleteNewSite(site) {
            this.$store.dispatch('updateContract/deleteSite', site)
        },
        siteTerminateRequest(site) {
            this.$store.commit('updateContract/setShowSiteTerminateRequestModal', true)
            this.$store.commit('updateContract/setEditedSite', site)

        },
        editSite(site) {
            this.$store.commit('updateContract/setEditedSite', site);
            this.$emit('openEditSiteModal')
        },

        setSettingSite(site) {
            this.$store.commit('updateContract/setSettingSite', site);
            this.$store.commit('updateContract/setShowSettingsSiteModal', true)
        },

        setStartCommissionSetting(site) {
            this.$store.commit('updateContract/setSettingSite', site);
            this.$store.commit('updateContract/setShowSiteSettingCommissionsModal', true)
        },
        setStartDiscountSetting(site) {
            this.$store.commit('updateContract/setSettingSite', site);
            this.$store.commit('updateContract/setShowSiteSettingDiscountsModal', true)
        },

        onSendApproveRequest() {
            this.$router.push('/approve-request')
        },
        approveRequestSite(site) {
            this.$store.commit('approve/setApproveRequestInfo', {site})
            this.$router.push('/approve-request')
        },

        toServiceEdit(site) {
            this.$store.dispatch('updateContract/toServiceEdit', site.service.id)
        },
        siteStatusName(status_name) {
            return siteStatusName(status_name)
        },


    },

}
</script>

<style>

.active_accordion {
    background-image: linear-gradient(to right, #d2f1a7, #c4d9dc) !important;
}

.monthly-fee {
    color: red;
    font-weight: bolder;
    font-style: italic;
}

</style>
