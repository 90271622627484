import axios from 'axios'
import setupAuthHeaderFor from "@/_helper/setupAxiosHeader";

const state = () => ({
  opportunities: []
})

const getters = {}

const mutations = {
  setOpportunities (state, opportunities) {
    state.opportunities = opportunities
  },


}

const actions = {
  fetchOpportunities ({rootState, commit}) {
    const axiosConfig = setupAuthHeaderFor('wsse');
    const url = rootState.app.hostServer + '/api/opportunities'

    axios.get(url, axiosConfig)
      .then(resp => {
        commit('setOpportunities', resp.data)
      })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
