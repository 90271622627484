<template>

    <vee-form class="ui form"
              @submit="onConditionsSubmit"
              :validation-schema="schema"
              :initial-values="initialValues"
              v-slot="{errors}">


        <div class="ui equal width grid">
            <div class="column">
                <div class="ui segment">
                    <div class="two fields">
                        <div class="field" :class="{error: 'payMethod' in errors}">
                            <h5 class="ui header">Fizetési mód <span class="higher-permission">( + )</span></h5>
                            <div class="ui input">
                                <vee-field as="select" class="ui fluid dropdown" name="payMethod">
                                    <option value="bank">Átutalás útján</option>
                                    <option value="cash">Kp-ben</option>
                                </vee-field>
                            </div>
                            <error-message name="payMethod" class="error-message"/>
                        </div>
                        <div class="field" :class="{error: 'prompt' in errors}">
                            <h5 class="ui header">Fizetési határidő<span
                                class="higher-permission">( + )</span></h5>
                            <div class="ui input">
                                <vee-field as="select" class="ui fluid dropdown" name="prompt">
                                    <option value="8">8 nap</option>
                                    <option value="15">15 nap</option>
                                    <option value="28">28 nap</option>
                                    <option value="45">45 nap</option>
                                    <option value="60">60 nap</option>
                                </vee-field>
                            </div>
                            <error-message name="prompt" class="error-message"/>
                        </div>
                    </div>

                    <div class="two fields">

                        <div class="field" :class="{error: 'billingPeriod' in errors}">
                            <h5 class="ui header">Gyakoriság</h5>
                            <div class="ui input">
                                <vee-field as="select" class="ui fluid dropdown" name="billingPeriod">
                                    <option value="-1">Utólag 1 hónap</option>
                                    <option value="1">Előre 1 hónap</option>
                                    <option value="2">Előre 2 hónap</option>
                                    <option value="3">Előre negyed év (3 hónap)</option>
                                    <option value="6">Előre fél év (6 hónap)</option>
                                    <option value="12">Előre egy év (12 hónap)</option>
                                    <option value="24">Előre KETTŐ év (24 hónap)</option>
                                    <option value="36">Előre HÁROM év (36 hónap)</option>
                                </vee-field>
                            </div>
                            <error-message name="billingPeriod" class="error-message"/>
                        </div>

                        <div class="field" :class="{error: 'billingDayOfMonth' in errors}">
                            <h5 class="ui header">A számlázás napja</h5>
                            <div class="ui input">
                                <vee-field as="select" class="ui fluid dropdown" name="billingDayOfMonth">
                                    <option value="1">1-én</option>
                                    <option value="15">15-én</option>
                                    <option value="28">28-án</option>
                                </vee-field>
                            </div>
                            <error-message name="billingDayOfMonth" class="error-message"/>
                        </div>

                    </div>

                    <div class="two fields">
                        <div class="field">
                            <div class="field under-construction" :class="{error: 'xxx' in errors}">
                                <h5 class="ui header">Számlafogadás</h5>
                                <div class="ui input">
                                    <vee-field as="select" class="ui fluid dropdown" name="xxx">
                                        <option value="email">E-mail</option>
                                        <option value="post">Posta</option>
                                        <option value="both">Mindkettő</option>
                                    </vee-field>
                                </div>
                                <error-message name="xxx" class="error-message"/>
                            </div>
                        </div>

                        <div class="field" :class="{error: 'billingNote' in errors}">
                            <h5 class="ui header">Megrendelői hivatkozás</h5>
                            <div class="ui input">
                                <vee-field type="text" name="billingNote"/>
                            </div>
                            <error-message name="billingNote" class="error-message"/>
                        </div>
                    </div>
                </div>
            </div>

            <div class="column">
                <div class="ui segment">
                    <div class="field">
                        <h5 class="ui header">Számlafogadó e-mail címek</h5>
                        <FieldArray name="billRecipientEmails" v-slot="{ fields, push, remove }">
                            <div v-for="(field, idx) in fields" :key="field.key">
                                <error-message name="`billRecipientEmails[${idx}].email`"
                                               class="error-message"/>
                                <div class="ui grid">
                                    <div class="fourteen wide column">
                                        <vee-field :name="`billRecipientEmails[${idx}].email`" type="email"/>
                                    </div>
                                    <div class="two wide column">
                                        <div class="two wide column" @click="remove(idx)">
                                            <i class="trash icon"/>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div style="margin-top: 1rem;">
                                <button type="button"
                                        class="ui basic green button newEmailBtn"
                                        @click="push({id: '', email: ''} )">Új számlafogadó e-mail cím
                                </button>
                            </div>
                        </FieldArray>
                    </div>

                </div>
            </div>

        </div>


        <div class="ui segment">
            <div class="actions">
                <button class="ui black deny button"
                        @click="onCloseModal">Mégsem</button>

                <button type="submit" class="ui positive right labeled icon button">
                    Mentés <i class="checkmark icon"></i>
                </button>

            </div>
        </div>


    </vee-form>


</template>


<script setup>
import {computed, defineEmits} from 'vue';
import {useStore} from 'vuex';
import {ErrorMessage, Field as VeeField, FieldArray, Form as VeeForm} from 'vee-validate';
import * as yup from 'yup';

const store = useStore();
const emit = defineEmits(['close']);

const contract = computed(() => store.state.updateContract.contract);

const initialValues = computed(() => ({
    payMethod: contract.value.payMethod,
    prompt: contract.value.prompt,
    billingPeriod: contract.value.billingPeriod,
    billingNote: contract.value.billingNote,
    billingDayOfMonth: contract.value.billingDayOfMonth,
    billRecipientEmails: contract.value.billRecipientEmails,
}));

const schema = yup.object().shape(
    {
        payMethod: yup.string().required('A fizetési mód megadása kötelező'),
        prompt: yup.number().required('A fizetési napok számát meg kell adni'),
        billingPeriod: yup.number().required('A fizetési határidő megadása kötelező'),
        billingNote: yup.string(),
        billingDayOfMonth: yup.number().required('1-én, 15-én vagy 28-án'),

    }
);


function onCloseModal() {
    store.commit('updateContract/setShowEditConditionsBModal', false)
    emit('close')
}




function onConditionsSubmit(values) {
    const send_data_contract = {
        id: store.state.updateContract.contract.id,
        ...values
    }

    store.dispatch('updateContract/updateContract', send_data_contract)
    store.commit('updateContract/setShowEditConditionsBModal', false)
    emit('close')
}
</script>


<style scoped>
.form {
    margin-right: 2rem;
}

.higher-permission {
    color: blue
}
</style>