<template>

    <div class="ui segment">
        <div class="ui sixteen width grid">
            <div class="nine wide column">
                <site-billing-info-form :site="props.site"/>
            </div>
            <div class="seven wide column">
                <site-period-for-appendix :site="props.site"/>
            </div>
        </div>
    </div>

</template>


<script setup>
import {defineProps} from "vue";
import SiteBillingInfoForm from "@/components/contract/update/site-billingInfo-form.vue";
import SitePeriodForAppendix from "@/components/contract/update/site-period-for-appendix.vue";

const props = defineProps(['site']);
</script>


<style scoped>

</style>