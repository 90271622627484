

export function costStatusName(cost_status_name) {
    let statusName = cost_status_name
    if (cost_status_name === 'new') {
        statusName = 'Új'
    } else if (cost_status_name === 'active') {
        statusName = 'Aktív'
    } else if (cost_status_name === 'terminate_requested') {
            statusName = 'Kért lemondás'
    } else if (cost_status_name === 'terminated') {
        statusName = 'Megszűnt'
    }
    else if(cost_status_name === 'completed'){
        statusName = 'Elvégezve'
    } else if(cost_status_name === 'billed'){
        statusName = 'Kiszámlázva'
    }

    return statusName
}

