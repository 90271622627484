<template>
    <Teleport to="body">
        <Modal :show="show()" :size="'modal-large'" @close="onCloseClick">
            <template #header>
                <div v-if="store_site.costsInPeriod"></div>
            </template>

            <template #body>
                <div v-if="store_site.costsInPeriod">


                    <div class="ui equal width grid">
                        <div class="column">
                            <request-period-info/>
                        </div>
                        <div class="column">
                            <modal-actions/>
                        </div>
                    </div>

                    <site-info/>

                    <costs-block class="mr-1"/>

                    <discounts-block class="mr-1"/>

                </div>
            </template>
        </Modal>
    </Teleport>
</template>

<script setup>

import Modal from "@/components/slots/modal.vue";
import {useStore as useVuexStore} from "vuex";
import {useSitesStore} from "@/store-pinia/api/site";
import RequestPeriodInfo from "@/components/contract/update/modals/site-costs-at-period/request-period-info.vue";
import SiteInfo from "@/components/contract/update/modals/site-costs-at-period/site-info.vue";
import CostsBlock from "@/components/contract/update/modals/site-costs-at-period/costs-block.vue";

import DiscountsBlock from "@/components/contract/update/modals/site-costs-at-period/discounts-block.vue";
import ModalActions from "@/components/contract/update/modals/site-costs-at-period/modal-actions.vue";


const vuex_store = useVuexStore();
const store_site = useSitesStore();


function onCloseClick() {
    vuex_store.commit('updateContract/setShowSiteCostsInPeriodModal', false)
}



function show() {
    return vuex_store.state.updateContract.showSiteCostsInPeriodModal;
}


</script>

<style scoped>
.mr-1 {
    margin-right: 1rem;
}

</style>