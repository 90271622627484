<template>
    <Teleport to="body">
        <modal :show="show()" :size="'modal-medium'"
               @close="onCloseModal">
            <template #header>Szerződés beállítások</template>
            <template #body>
                    <conditions-form-a/>
            </template>
        </modal>
    </Teleport>
</template>


<script setup>
import Modal from "@/components/slots/modal.vue"
import {useStore as useVuexStore} from "vuex";
import ConditionsFormA from "@/components/contract/update/modals/contract-conditions-a/conditions-form-a.vue";

const vuex_store = useVuexStore()

function show() {
    return vuex_store.state.updateContract.showEditConditionsAModal;
}

function onCloseModal() {
    vuex_store.commit('updateContract/setShowEditConditionsAModal', false)
}

</script>

<style scoped>

</style>