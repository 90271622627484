<template>
  <vee-form class="ui form"
            @submit="onCostTerminateRequestSubmit"
            :validation-schema="schema"
            :initial-values="initialValues"
            v-slot="{errors}">


    <div class="eight wide column">
      <div class="field" :class="{error: `dateOfTerminateRequest` in errors}">
        <label>Lemondási napja</label>
        <div class="ui input">
          <vee-field type="date" name="dateOfTerminateRequest"/>
        </div>
        <error-message name="dateOfTerminateRequest" class="error-message"/>
      </div>

    </div>


    <div class="ui segment">
      <div class="actions">
        <button class="ui black deny button"
                @click="cancelSubmit">Mégsem
        </button>

        <button type="submit"
                class="ui positive right labeled icon button">
          A lemondás rögzítése
          <i class="checkmark icon"></i>
        </button>

      </div>
    </div>


  </vee-form>


</template>

<script>
import {Form, Field} from 'vee-validate';
import * as yup from "yup";
import {mapState} from "vuex";

import {add, format} from 'date-fns'

export default {
  name: "cost-terminate-request-form",
  components: {
    VeeForm: Form,
    VeeField: Field
  },
  computed: {
    ...mapState({
      contract: state => state.updateContract.contract,

    })

  },

  data() {

    const accept_terminate_date = format(add(new Date(),
        {
          days: this.$store.state.updateContract.acceptTerminateDays
        }
    ), 'yyyy-MM-dd')

    return {
      schema: yup.object().shape(
          {
            dateOfTerminateRequest: yup.date().required('A megszüntetés dátumának megadása kötelező'),
          }
      ),
      initialValues: {
        dateOfTerminateRequest: accept_terminate_date,
      },
    }

  },

  methods: {
    onCostTerminateRequestSubmit(values) {

      const data = {
        cost: this.$store.state.updateContract.editedCost,
        terminateRequestDate: values.dateOfTerminateRequest,
        contract_id: this.$store.state.updateContract.contract.id,
        scope: 'cost'
      }
      this.$store.dispatch('updateContract/terminateRequest', data)
    },
    cancelSubmit() {
      this.$store.commit('updateContract/setShowCostTerminateRequestModal', false)
    }
  }
}
</script>

<style scoped>

</style>
