<template>
    <div class="ui  message">
        <div class="ui sixteen width grid">
            <div class="four wide column">
                Vizsgált időszak:
            </div>
            <div class="six wide center aligned column">
                <strong>
                    {{ contract_store.sitesAtPeriod.requestedPeriod.periodStart }}&nbsp;-&nbsp;
                    {{ contract_store.sitesAtPeriod.requestedPeriod.periodEnd }}
                </strong>
            </div>
            <div class="six wide column">
                <div class="pointer" @click.stop="toggleShowCostSegments()">
                    <template v-if="contract_store.sitesAtPeriod.showMergedCostSegments">
                        <i class="check squared  icon pointer"/>
                        Hónapok <strong>összevonva</strong>
                    </template>
                    <template v-else>
                        <i class="expand icon pointer"/>
                        Hónapok <strong>kibontva</strong>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {useContractStore} from "@/store-pinia/api/contract";

const contract_store = useContractStore()

function toggleShowCostSegments() {
    contract_store.sitesAtPeriod.showMergedCostSegments = !contract_store.sitesAtPeriod.showMergedCostSegments
}

</script>
