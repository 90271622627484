<template>
  <Teleport to="body">

    <modal :show="show" :size="'modal-medium'"
           @close="onCloseModal">
      <template #header>A telephely lemondásának a kérése</template>
      <template #body>
        <message-terminate-warning/>
        <site-terminate-request-form/>
      </template>
    </modal>

  </Teleport>
</template>

<script>
import Modal from "@/components/slots/modal.vue"
import {mapState} from "vuex";

import MessageTerminateWarning from "@/components/contract/update/message-site-terminate-warning.vue";
import SiteTerminateRequestForm from "@/components/contract/update/site-terminate-request-form.vue"

export default {
  name: "modal-site-terminate-request",
  components: {
    Modal,
    SiteTerminateRequestForm,
    MessageTerminateWarning

  },
  methods: {
    onCloseModal() {
      this.$store.commit('updateContract/setShowSiteTerminateRequestModal', false)
      this.$store.commit('updateContract/setEditedSite', null)
      this.$emit('close')

    }
  },
  computed: {
    ...mapState({
      show: state => state.updateContract.showSiteTerminateRequestModal,
    }),

  }

}
</script>

<style scoped>

</style>
