<template>
    <div class="ui fluid steps">
        <div class="step" :class="{ active: store_page.activeStep===1 }">
            <div class="content">
                <div class="icon_container">
                    <i class="history big icon"/>
                </div>
                <div class="title">Előzmények</div>
                <div class="description">Opportunity, stb</div>
            </div>
        </div>

        <div class="step" :class="{ active: store_page.activeStep===2 }">
            <div class="content">
                <div class="icon_container">
                    <i class="users big icon"/>
                </div>
                <div class="title">Szerződő felek</div>
                <div class="description">Kik kötnek szerződést?</div>
            </div>
        </div>

        <div class="step" :class="{ active: store_page.activeStep===3 }">
            <div class="content">
                <div class="icon_container">
                    <i class="dollar big icon"/>
                </div>
                <div class="title">Feltételek</div>
                <div class="description">Milyen feltételeekkel szerződünk?</div>
            </div>
        </div>
    </div>
</template>

<script setup>


import {usePageCreateContractStore} from "@/store-pinia/pages/pageCreateContract";

const store_page = usePageCreateContractStore()


</script>

<style scoped>
.icon_container {
    text-align: center;
    margin-bottom: 10px;
}
</style>
