<template>
  <div class="ui segment container">
    <h3 class="ui header">Jóváhagyja:</h3>

    <vee-form class="ui form"
              @submit="onSubmit"
              :validation-schema="schema"
              :initial-values="initialValues"
              v-slot="{errors, fields}">

      <approve-form-fields :errors="errors" :fields="fields"/>


      <div class="ui segment">


          <button class="ui red button"
                  type="submit"
                  :disabled="sending">Elküldöm jóváhagyásra
          </button>

          <button class="ui black button"
                  type="button"
                  @click="returnToCRM"
                  :disabled="sending">Nem küldöm el, visszamegyek a szerződések listájához
          </button>
          <button class="ui black button"
                  type="button"
                  @click="returnToContractEdit"
                  :disabled="sending">Nem küldöm el, visszamegyek a szerződés szerkeszéséhez
          </button>



      </div>


    </vee-form>

  </div>
</template>

<script>
import {Form} from "vee-validate";
import ApproveFormFields from "@/components/contract/approve-request/approveFormFields";
import * as yup from "yup";

export default {
  name: "ApproveForm",
  props: ['sending'],
  components: {
    ApproveFormFields,
    VeeForm: Form
  },
  data() {
    return {
      schema: yup.object({
        personName: yup.string().required("A személy nevét meg kell adni!"),
        email: yup.string().required("A személy e-mail címét meg kell adni!").email('Nem megfelelő e-mail cím'),
        phone: yup.string(),
      }),
      initialValues: {
        personName: '',
        email: '',
        phone: ''
      }
    }
  },
  methods: {
    onSubmit(values) {
      this.$store.dispatch('approve/sendApproveRequest', values)
    },
    returnToCRM() {
      this.$store.dispatch('app/returnToCRM', {}, {root: true})
    },
    returnToContractEdit() {
      this.$store.dispatch('updateContract/fetchContract', this.$store.state.app.hostServerParams.values.contract)
      this.$router.push({path: 'contract/update'})
    }
  }

}
</script>

<style scoped>

</style>
