import {defineStore} from "pinia";
import {useContractPriceListStore} from "@/store-pinia/contractPriceList";
import _ from "lodash"

import setupAuthHeaderFor from "@/_helper/setupAxiosHeader";
import axios from "axios";
import {useErrorsStore} from "@/store-pinia/errors";
import {useOroStore} from "@/store-pinia/oro";
import {useNotificationsStore} from "@/store-pinia/notifications";

import vuex_store from '@/store'


//Declaring here stores throw ERROR
//const store_errors = useErrorsStore();
//const store_oro = useOroStore();

export const useCostDetailingStore = defineStore({
    id: 'costDetailing',
    state: () => {
        return {
            priceList: [],
            site: null,
            oldCost: null,
            newCosts: [],
            sending: false,
            vuexStore: null
        }
    },
    actions: {

        setPriceList() {
            const store_priceList = useContractPriceListStore();
            this.priceList = store_priceList.priceList;
        },

        setOldCost(old_cost) {
            this.oldCost = old_cost
        },

        setSite(site) {
            this.site = site
        },

        setNewCosts(new_costs_arr) {
            this.newCosts = new_costs_arr
        },


        setCancelOperation() {

            this.setOldCost(null);
            this.setNewCosts([]);
        },

        setSending(sending_value) {
            this.sending = sending_value
        },

        getPriceListItemById(price_list_arr, search_id) {
            return price_list_arr.find((el) => el.id === search_id)
        },


        callVuexTest() {

            vuex_store.commit('updateContract/setTestElek', 'Megváltozott a Teszt Elek értéke')
        },


        calculate(values) {


            if (!values) {
                this.setNewCosts([])
                return;
            }

            const calculatedNewCosts = values.need_items.map(el => {

                const foundPriceListItem = this.getPriceListItemById(this.priceList, el.id)

                const lastUnitPrice = foundPriceListItem.priceChanges[foundPriceListItem.priceChanges.length - 1].price

                const lastPrice = parseInt(lastUnitPrice) * parseInt(el.quantity);

                const category_name = foundPriceListItem.categoryName;

                let priceOneTime = 0;
                let priceMonthly = 0;
                if (category_name === 'install_cost') {
                    priceOneTime = lastPrice;
                }
                if (category_name === 'monthly_fee') {
                    priceMonthly = lastPrice;
                }

                return {
                    id: el.id,
                    name: foundPriceListItem.name,
                    quantity: el.quantity,
                    priceOneTime: priceOneTime,
                    priceMonthly: priceMonthly,
                    category: foundPriceListItem.categoryName,
                    priceListItem: foundPriceListItem.id
                }
            });


            this.setNewCosts(calculatedNewCosts)
        },


        //############################

        async callDetailingCost() {

            const store_oro = useOroStore();

            const store_errors = useErrorsStore();

            const store_notifications = useNotificationsStore();

            const axiosConfig = setupAuthHeaderFor('wsse');

            const url = `${store_oro.host}/api/detailing-site-costs/${this.site.id}`;


            await axios.post(url, this.newCosts, axiosConfig)
                .then(() => {
                    store_notifications.addNotification('A költség kibontása sikeres. Kérlek frissítsd az oldalt!')
                })
                .then(async () => {
                    const contract_id = vuex_store.state.updateContract.contract.id
                    vuex_store.commit('updateContract/setShowCostsDetailingModal', false, {root: true});
                    await vuex_store.dispatch('updateContract/fetchContract', contract_id, {root: true})
                })
                .catch(err => {
                    const error = {
                        code: err.response.status,
                        message: err.message,
                        message_public: 'A költségek kibontása sikertelen volt!'
                    }
                    store_errors.addError(error)
                })
        },

    },

    getters: {
        availableCosts: (state) => {
            return state.priceList.filter(el => (el.categoryName === 'monthly_fee' || el.categoryName === 'install_cost') && !el.deprecated)
        },

        sumMonthly: (state) => {
            return _.sumBy(state.newCosts, 'priceMonthly')
        },
        sumOneTime: (state) => {
            return _.sumBy(state.newCosts, 'priceOneTime')
        },
        isEqualMonthly: (state) => {
            return state.site && state.site.priceMonthly === state.sumMonthly
        },
        isEqualOneTime: (state) => {
            return state.site && state.site.priceOneTime === state.sumOneTime
        }
    }
});


