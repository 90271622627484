<template>
  <div class="item">
    <div class="content">
      <div class="ui equal width grid">
        <div class="column header"><slot name="name"></slot></div>
        <div class="column right aligned"><slot name="value"></slot></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CheckOneCondition"
}
</script>

<style scoped>

</style>
