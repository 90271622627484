<template>
  <div class="ui segment">

    <h3>Telepítési hely</h3>
    <div class="ui three fields">
      <div class="field" :class="{error: 'name' in errors}">
        <label>Név</label>
        <div class="ui input">
          <vee-field type="text" name="name"/>
        </div>
        <error-message name="name" class="error-message"/>
      </div>

      <div class="field" :class="{error: 'city' in errors}">
        <label>Település</label>
        <div class="ui input">
          <vee-field type="text" name="city"/>
        </div>
        <error-message name="city" class="error-message"/>
      </div>

      <div class="field" :class="{error: 'street' in errors}">
        <label>Utca, házszám</label>
        <div class="ui input">
          <vee-field type="text" name="street"/>
        </div>
        <error-message name="street" class="error-message"/>
      </div>
    </div>
  </div>
</template>

<script>
import {Field, ErrorMessage} from 'vee-validate'

export default {
  name: "IdentitySegment",
  props: ['item', 'errors'],
  computed: {},
  components: {
    VeeField: Field,
    ErrorMessage
  }
}
</script>

<style scoped>
.error-message {
  color: red;
}
</style>
