<template>
    <vee-form class="ui form"
              @submit="onSubmit"
              :validation-schema="schema"
              :initial-values="initial_values"
              v-slot="{ errors}"
    >

        <div class="ui sixteen widht grid">
            <div class="sixteen wide column">

                <div class="three fields">
                    <div class="field" :class="{error: 'name' in errors}">
                        <label for="name">Név</label>
                        <vee-field type="text" placeholder="Neve" name="name"/>
                        <error-message name="name" class="error-message"/>
                    </div>

                    <div class="field" :class="{error: 'unitPrice' in errors}">
                        <label for="unitPrice">Egységár</label>
                        <vee-field type="number" placeholder="Egységára" name="unitPrice"/>
                        <error-message name="unitPrice" class="error-message"/>
                    </div>

                    <div class="field" :class="{error: 'categoryName' in errors}">
                        <label for="categoryName">Kategóra:</label>
                        <vee-field as="select"
                                   class="ui fluid dropdown"
                                   name="categoryName"
                                   placeholder="Kategória">
                            <option value=""></option>
                            <option value="monthly_fee">Havidíj</option>
                            <option value="install_cost">Felszerelési költség</option>
                            <option value="service_fee">Szervízköltség</option>
                        </vee-field>
                        <error-message name="categoryName" class="error-message"/>
                    </div>
                </div>

            </div>

            <div class="four wide column">


                <div class="ui buttons">
                    <button type="submit"
                            class="ui positive mini button">Mentés
                    </button>
                    <div class="or" data-text="?"></div>
                    <button type="button"
                            @click="cancel"
                            class="ui negative mini  button">Mégsem
                    </button>
                </div>
            </div>
        </div>

    </vee-form>


</template>

<script setup>
import {ErrorMessage, Field as VeeField, Form as VeeForm} from 'vee-validate'
import * as yup from "yup";
import {useApiQuotationStore} from "@/store-pinia/api/quotations";
import {useUpdateQuotationPageStore} from "@/store-pinia/pages/updateQuotationPage";


const initial_values = {
    name: '',
    unitPrice: 0,
    categoryName: ''
}


const schema = yup.object({
    name: yup.string().required('Megadása kötelező!'),
    unitPrice: yup.number('Ez nem megfelelő érték').min(0, 'Ugye nem mi fizetünk az ügyfélnek?').required('Megadása kötelező'),
    categoryName: yup.string().required('Megadása kötelező')
})

const store_page = useUpdateQuotationPageStore()
const store_api = useApiQuotationStore();


function onSubmit(values) {

    const send_data = {
        name: values.name,
        unitPrice: parseInt(values.unitPrice),
        categoryName: values.categoryName
    }

    store_api.addNewPriceListElement(store_api.quotation.id, send_data)
    store_page.setShowModalCreatePriceList(false);
}

function cancel() {
    store_page.setShowModalCreatePriceList(false);
}

</script>

<style scoped>
.error-message {
    color: red
}
</style>