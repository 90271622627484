<template>

  <Teleport to="body">

    <modal :show="show" :size="'modal-large'"
           @close="onCloseModal">
      <template #header>
        <div class="ui equal width grid">
          <div class="column">
            Árak kibontása
          </div>
          <div class="column">
            {{ site().nameAtContract }}
          </div>
          <div class="column">
            <div class="ui brown big label">
              Egyszeri díj: {{ site().priceOneTime }} Ft
            </div>
          </div>

          <div class="column">
            <div class="ui teal big label">
              Havidíj: {{ site().priceMonthly }} Ft/hó
            </div>
          </div>


        </div>
      </template>

      <template #body>

        <notifications-component/>

        <detailing-form />

        <detailing-costs/>
        <detailing-actions @close="onCloseModal" />


      </template>
    </modal>

  </Teleport>
</template>


<script>
import Modal from "@/components/slots/modal.vue";
import {mapState} from "vuex";
import DetailingCosts from "@/components/contract/update/modals/costs-detailing/detailing-new-costs.vue";
import DetailingActions from "@/components/contract/update/modals/costs-detailing/detailing-actions.vue";
import DetailingForm from "@/components/contract/update/modals/costs-detailing/detailing-form.vue"

import NotificationsComponent from "@/components/NotificationsComponent.vue";


import {useCostDetailingStore} from "@/store-pinia/costsDetailing";

export default {
  name: "modal-costs-detailing",


  components: {
    DetailingActions,
    DetailingCosts,
    Modal,
    DetailingForm,
    NotificationsComponent
  },
  methods: {
    onCloseModal() {
      this.$store.commit('updateContract/setShowCostsDetailingModal', false)
    },

    site() {
      const store_costs_detailing = useCostDetailingStore();
      return store_costs_detailing.site
    },

  },
  computed: {
    ...mapState({
      show: state => state.updateContract.showCostsDetailingModal,
    })

  }

}

</script>


<style scoped>

</style>
