<template>
  <div class="ui grid">
    <div class="five wide column">
      Név
    </div>

    <div class="two wide right aligned column">
      Egységár
    </div>

    <div class="one wide right aligned column">
      Menny.
    </div>

    <div class="two wide right aligned column">
      Ár
    </div>

    <div class="six wide column">
      Státusz
    </div>
  </div>
</template>

<script setup>

</script>

<style scoped>
.column {
  font-size: larger;
  font-style: italic;
  font-weight: bold;
  margin: 1rem 0;
  color: darkcyan;
}
</style>