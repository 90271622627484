<template>
  <Teleport to="body">
    <modal :show="show" :size="'modal-medium'"
           @close="onCloseModal">
      <template #header>{{ header }}</template>
      <template #body>
        <message-terminate-warning/>
        <cost-terminate-request-form/>
      </template>
    </modal>

  </Teleport>
</template>

<script>
import Modal from "@/components/slots/modal.vue"
import {mapState} from "vuex";

import MessageTerminateWarning from "@/components/contract/update/message-cost-terminate-warning.vue";
import CostTerminateRequestForm from "@/components/contract/update/cost-terminate-request-form.vue"

export default {
  name: "modal-cost-terminate-request",
  components: {
    Modal,
    CostTerminateRequestForm,
    MessageTerminateWarning

  },
  methods: {
    onCloseModal() {
      this.$store.commit('updateContract/setShowCostTerminateRequestModal', false)
      this.$store.commit('updateContract/setEditedCost', null)
    }
  },
  computed: {
    ...mapState({
      show: state => state.updateContract.showCostTerminateRequestModal,
      cost: state => state.updateContract.editedCost,
    }),
    header: () => `A tétel lemondásának a kérése`

  }

}
</script>

<style scoped>

</style>
