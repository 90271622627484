<template>

    <Teleport to="body">

        <modal :show="show()" :size="'modal-medium'"
               @close="onCloseModal">
            <template #header>Kedvezménybeállítások</template>
            <template #body>
                <site-setting-discount-form/>
            </template>
        </modal>

    </Teleport>


</template>

<script setup>
import Modal from "@/components/slots/modal.vue"
import {useStore as useVuexStore} from "vuex";
import SiteSettingDiscountForm
    from "@/components/contract/update/modals/site-setting-discounts/site-setting-discount-form.vue";

const vuex_store = useVuexStore()


function show() {
    return vuex_store.state.updateContract.showSiteSettingDiscountsModal;
}

function onCloseModal() {
    vuex_store.commit('updateContract/setShowSiteSettingDiscountsModal', false)
}


</script>

