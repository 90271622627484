<template>
  <div class="ui segment">
    <div class="ui middle aligned divided list">
      <condition-list-item>
        <template v-slot:name>Ügyfél:</template>
        <template v-slot:value>{{ store_opportunity.customer ? store_opportunity.customer.name : '' }}</template>
      </condition-list-item>

      <condition-list-item>
        <template v-slot:name>Székhely:</template>
        <template v-slot:value>
          {{ store_opportunity.customer ? store_opportunity.customer.mainAddress.postalCode : '' }}
          {{ store_opportunity.customer ? store_opportunity.customer.mainAddress.city : '' }},
          {{ store_opportunity.customer ? store_opportunity.customer.mainAddress.street : '' }}
        </template>
      </condition-list-item>

      <condition-list-item>
        <template v-slot:name>Adószám:</template>
        <template v-slot:value>{{ store_opportunity.customer ? store_opportunity.customer.taxNumber : '' }}</template>
      </condition-list-item>
    </div>
  </div>
</template>

<script setup>
import ConditionListItem from "@/components/contract/create/checkOneCondition.vue";
import {useOpportunitiesStore} from "@/store-pinia/api/opportunities";

const store_opportunity = useOpportunitiesStore()

</script>

<style scoped>

</style>