<template>
  <div class="ui segment">
    <h2>Ügyfél</h2>
    <div class="ui grid">
      <div class="six wide column">
        <h3>{{ customer().name }}</h3>
        <div class="ui grid">
          <div class="eight wide column">Adószám:</div>
          <div class="eight wide column">{{ customer().taxNumber }}</div>
        </div>
        <div class="ui grid">
          <div class="eight wide column">Cégjegyzékszám:</div>
          <div class="eight wide column">{{ customer().registrationNumber }}</div>
        </div>
        <main-email-form-field :errors="errors" :fields="fields"/>
      </div>

      <div class="ui four wide column">
        <h3>Székhely</h3>
        <p>{{ customer().mainAddress.postalCode }} {{ customer().mainAddress.city }}, {{ customer().mainAddress.street }}</p>
      </div>

      <posting-address-form-fields :errors="errors" :fields="fields"/>

    </div>


  </div>
</template>

<script>
import PostingAddressesFormFields from "@/components/contract/create/customerPostingAddressesFormFields";
import MainEmailFormField from "@/components/contract/create/customerMainEmailFormField";
import {useOpportunitiesStore} from "@/store-pinia/api/opportunities";

export default {
  name: "CustomerFormComponent",
  props: ['errors', 'fields'],

  methods:{
     customer(){
         const store_opportunity = useOpportunitiesStore()
         return store_opportunity.opportunity ? store_opportunity.opportunity.account.customer: null
     }
  } ,

  components: {
    PostingAddressFormFields: PostingAddressesFormFields,
    MainEmailFormField
  }
}
</script>

<style scoped>
.error-message {
  color: red;
}
</style>
