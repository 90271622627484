<template>
    <div class="ui grid">

    <pre v-if="false">
      {{ props.cost }}
    </pre>


        <div class="five wide column">
            {{ props.cost.name }}
        </div>

        <div class="two wide right aligned column">
      <span v-if="props.cost.categoryType==='pay_once'">
        {{ props.cost.unitPriceOneTimeToday.toLocaleString() }} Ft
      </span>
            <span v-else>
        {{ props.cost.unitPriceMonthlyToday.toLocaleString() }} Ft/hó
      </span>
        </div>

        <div class="one wide right aligned column">
            {{ props.cost.quantity.toLocaleString() }} db
        </div>

        <div class="two wide right aligned column">
      <span v-if="props.cost.categoryType==='pay_once'">
        {{ props.cost.priceOneTimeToday.toLocaleString() }} Ft
      </span>
            <span v-else class="monthly-fee">
        {{ props.cost.priceMonthlyToday.toLocaleString() }} Ft/hó
      </span>
        </div>

        <div class="six wide column">
            <div class="ui sixteen width grid">
                <div class="twelve wide column">
                    <statuses :cost="props.cost"/>
                </div>
                <div class="four wide right aligned column">
                    <actions :cost="props.cost"/>
                </div>
            </div>
        </div>

    </div>
</template>

<script setup>
import {defineProps} from "vue";
import Statuses from "@/components/contract/update/site-content-cost-statuses.vue";
import Actions from "@/components/contract/update/site-content-cost-actions.vue";


const props = defineProps(['cost']);


</script>

<style scoped>
.monthly-fee {
    color: red;
}
</style>