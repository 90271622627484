<template>
    <vee-form class="ui form "
              @click.stop
              @submit="onBillingInfoSubmit"
              :validation-schema="schema"
              :initial-values="initialValues"
              v-slot="{errors, meta}">

        <div class="ui sixteen width middle aligned grid">
            <div class="seven wide column">
                <div class="field" :class="{error: 'siteComment' in errors}">
                    <label>Mellékletre kerülő megjegyzés</label>
                    <div class="ui input">
                        <vee-field type="text" name="siteComment"/>
                    </div>
                    <error-message name="siteComment" class="error-message"/>
                </div>
            </div>

            <div class="six wide column">
                <div class="field" :class="{error: 'invoiceGroup' in errors}">
                    <label>Melyik számlára fog kerülni?</label>
                    <div class="ui input">
                        <vee-field type="text" name="invoiceGroup"/>
                    </div>
                    <error-message name="invoiceGroup" class="error-message"/>
                </div>
            </div>
            <div class="three wide  right aligned column">
                <button type="submit" class="ui primary mini button" v-if="meta.dirty">Mentés</button>
            </div>
        </div>
    </vee-form>


</template>

<script setup>

import {ErrorMessage, Field as VeeField, Form as VeeForm} from 'vee-validate';
import * as yup from "yup";
import {computed, defineProps} from "vue";
import {useStore as useVuexStore} from 'vuex';
import {useSitesStore} from "@/store-pinia/api/site";

const props = defineProps(['site']);


const initialValues = computed(() => ({
    siteComment: props.site.billingInfo.siteComment,
    invoiceGroup: props.site.billingInfo.invoiceGroup
}));

const schema = yup.object().shape({
        siteComment: yup.string(),
        invoiceGroup: yup.string().required('Tudnunk kell, hogy melyik számlára kerüljön!'),
    }
);

const vuex_store = useVuexStore();

function onBillingInfoSubmit(values) {

    const store_site = useSitesStore();

    const send_data = {
        billingInfo: values
    }

    store_site.updateSite(vuex_store.state.updateContract.contract.id, props.site.id, send_data);
}

</script>


<style scoped>
.error {
    color: red;
}

</style>
