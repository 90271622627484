<template>
  <div class="ui warning message">
    <div class="header">
      Figyelem!
    </div>
    <p>
      A tétel lemondása <strong> havidíj változásával jár.</strong>
    </p>
    <p>
      Leghamarabb
      <strong>
        {{ this.$store.state.updateContract.acceptTerminateDays }} nap
      </strong>
      elteltével kerül ki a számlázásból (ha a szükséges egyéb feltételek teljesülnek)!
    </p>
    <p>
      A lemondás rögzítése után az ügyféllel
      <strong>jóvá kell hagyatni a lemondás kérését!</strong>
      Jóváhagyás hiányában a lemondás kérését <strong>nem dolgozzuk fel.</strong>
    </p>

  </div>

</template>

<script>
export default {
  name: "message-cost-terminate-warning"
}
</script>

<style scoped>

</style>
