<template>
    <div v-if="store.notifications.length"
         class="ui positive message">
        <i class="close icon" @click.stop="store.clearNotifications"></i>

        <div class="ui list">
            <div v-for="(notification, index) in store.notifications" :key="index"
                 class="item">
                {{ notification }}
            </div>
        </div>
    </div>
</template>

<script setup>
import {useNotificationsStore} from "@/store-pinia/notifications";

const store = useNotificationsStore()

</script>

