<template>
    <div class="ui segment contract-info-block">
        <div class="ui middle aligned divided list">

            <condition-list-item>
                <template v-slot:name>Szerződésszám:</template>
                <template v-slot:value><strong>{{ contract ? contract.registrationNumber : '' }}</strong></template>
            </condition-list-item>

            <condition-list-item>
                <template v-slot:name>Terméktípus</template>
                <template v-slot:value>
                    <div class="ui sixteen width width grid">
                        <div class="twelve wide column">
                            <div class="ui yellow big label" style="color: black !important">
                                {{ contract ? contract.productType.name : '' }}
                            </div>
                        </div>
                        <div class="four wide column">
                            <button class="ui primary button" @click="showPriceList">Árlista</button>
                        </div>
                    </div>

                </template>
            </condition-list-item>

            <condition-list-item>
                <template v-slot:name>Szolgáltató</template>
                <template v-slot:value>{{ contract ? contract.serviceCompany.name : '' }}</template>
            </condition-list-item>

            <condition-list-item>
                <template v-slot:name>Megrendelő</template>
                <template v-slot:value>
                    <div class="ui sixteen width grid">
                        <div class="two wide column">
                            <div class= "pointer">
                                <i class="edit icon" @click="showCustomerEdit"/>
                            </div>
                        </div>
                        <div class="fourteen wide column">
                            <span class="customer-name">{{ contract ? contract.customer.name : '' }}</span>

                        </div>

                    </div>
                </template>
            </condition-list-item>

            <condition-list-item v-if="false">
                <template v-slot:name>Üzletkötő</template>
                <template v-slot:value>
                    <div class="ui sixteen width grid">
                        <div class="sixteen wide column">
                            <span class="customer-name">{{ contract ? contract.customer.name : '' }}</span>

                            <pre>{{ contract.customer  }}</pre>

                        </div>

                    </div>
                </template>
            </condition-list-item>

        </div>
    </div>

</template>

<script>
import {mapState} from "vuex";
import CheckOneCondition from "@/components/contract/create/checkOneCondition";

export default {
    name: "ContractInfoComponent",
    components: {
        ConditionListItem: CheckOneCondition
    },
    computed: {
        ...mapState({
            contract: state => state.updateContract.contract,
        }),
        product() {
            const p = this.$store.state.updateContract.contract.productType
            return this.$store.state.app.products.find((el) => el.id === p)
        },
        serviceCompany() {
            const sc = this.$store.state.updateContract.contract.serviceCompany
            return this.$store.state.app.serviceCompanies.find((el) => el.id === sc)
        },


    },

    methods: {
        showPriceList() {
            this.$store.commit('updateContract/setShowPriceListModal', true);
        },
        showCustomerEdit() {
            this.$store.commit('updateContract/setShowEditCustomerModal', true);
        }
    }


}
</script>

<style scoped>

.contract-info-block {
    background-image: linear-gradient(to right, rgb(229, 255, 249), #f0f0f0);
}

.customer-name {
    font-weight: bold;
    color: red;
}

.pointer {
    cursor: pointer;
}

</style>
