<template>
    <div class="ui segment">

        <pre v-if="false">
            {{ site.status }}
        </pre>


        <div class="ui grid ">
            <div class="row">
                <div class="eight wide column">
                    <h4 class="ui header itemsHeader">Tételek</h4>
                </div>
                <template v-if="site.status !=='terminated'">
                    <div v-if="false"
                         class="four wide right aligned column">
                        <button class="ui red basic small button " style="margin-bottom: 1rem"
                                @click.stop="openCostsDetailingModal(site)">
                            <i class="tasks red icon"></i>
                            Kibontás
                        </button>
                    </div>
                    <div class="eight wide right aligned column">
                        <button class="ui basic green small button " style="margin-bottom: 1rem"
                                @click.stop="addNewCost(site)">
                            <i class="plus green icon"></i>
                            Új tétel hozzáadása
                        </button>
                    </div>
                </template>
            </div>
        </div>

        <div class="row">
            <div class="sixteen wide column">
                <div class="ui celled list">

                    <template v-if="site.status ==='terminated' && site.hideTerminatedCosts">
                        <div class="ui info message">
                            Minden költség megszűnt! Ha látni szeretnéd, kapcsold be, hogy azok is látszódjanak!
                        </div>
                    </template>

                    <template v-else>
                        <div class="item">
                            <cost-list-header/>
                        </div>
                        <template v-for="cost in costs" :key="cost.id">
                            <div class="item"
                                 v-if="!site.hideTerminatedCosts || (cost.status !== 'terminated' && cost.status !== 'completed')">
                                <cost-list-row :cost="cost"/>
                            </div>
                        </template>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {useCostDetailingStore} from "@/store-pinia/costsDetailing";
import {useCostsStore} from "@/store-pinia/api/costs";

import CostListHeader from "@/components/contract/update/site-content-cost-row-header.vue"
import CostListRow from "@/components/contract/update/site-content-cost-row.vue"


export default {
    name: "site-content-column-site-costs-list-component",
    props: ['site', 'costs'],
    components: {
        CostListHeader,
        CostListRow
    },
    methods: {

        addNewCost(site) {
            const store_costs = useCostsStore();
            store_costs.setSite(site);

            this.$store.commit('updateContract/setEditedSite', site)
            this.$store.commit('updateContract/setShowAddCostModal', true)

        },

        openCostsDetailingModal(site) {
            const store_cost_detailing = useCostDetailingStore();
            store_cost_detailing.setSite(site);
            this.$store.commit('updateContract/setShowCostsDetailingModal', true)
        },

    },

}
</script>

<style scoped>
.itemsHeader {
    margin-bottom: 1rem !important;
}

</style>
