<template>
  <table class="ui celled table">
    <thead>
    <tr>
      <th>Megnevezés</th>
      <th class="center aligned">Egyszeri díj</th>
      <th class="center aligned">Havidíj</th>
    </tr>
    </thead>
    <tbody>

    <tr v-for="(cost, index) in costs" :key="index">
      <td data-label="Megnevezés">{{ cost.name }}</td>
      <td data-label="Egyszeri díj" class="right aligned">{{ cost.priceOneTime }} Ft</td>
      <td data-label="Havidíj" class="right aligned">{{ cost.priceMonthly }} Ft/hó</td>
    </tr>
    </tbody>
    <tfoot class="full-width">
    <tr>
      <th class="sum_row">Összesen</th>
      <th class="right aligned sum_row">{{ sum.priceOneTime }} Ft</th>
      <th class="right aligned sum_row">{{ sum.priceMonthly }} Ft/hó</th>
    </tr>
    </tfoot>
  </table>
</template>

<script>

export default {
  name: 'PricesTable',
  props: ['calculation'],
  computed: {
    costs() {
      return this.calculation && this.calculation.costs
          ? this.calculation.costs
          : []
    },
    sum() {
      return this.calculation && this.calculation.sum
          ? this.calculation.sum
          : {priceOneTime: '---', priceMonthly: '---'}
    }
  }
}
</script>

<style scoped>
.sum_row {
  color: red;
  font-weight: bold !important;

}

</style>
