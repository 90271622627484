<template>
    <div class="column">
        <div class="field" :class="{error: 'dateOfContract' in props.errors}">
            <label>Szerződéskötési dátum</label>
            <div class="ui input">
                <vee-field type="date" placeholder="Szerződéskötési dátum" name="dateOfContract"/>
            </div>
            <error-message name="dateOfContract" class="error-message"/>
        </div>
    </div>

    <div class="column">
        <div class="field" :class="{error: 'serviceCompany' in props.errors}">
            <label>Szolgáltató</label>
            <div class="ui input">
                <vee-field as="select" placeholder="Szolgáltató" name="serviceCompany">
                    <option value="">Válaszd ki a szolgáltatót</option>
                    <option v-for="sCompany in store_service_company.serviceCompanies" :value="sCompany.id"
                            :key="sCompany.id">{{
                        sCompany.name
                        }}
                    </option>
                </vee-field>
            </div>
            <error-message name="serviceCompany" class="error-message"/>
        </div>
    </div>
</template>

<script setup>
import {ErrorMessage, Field as VeeField} from 'vee-validate'

import {defineProps} from "vue"
import {useServiceCompanyStore} from "@/store-pinia/api/serviceCompany";

const props = defineProps(['fields', 'errors'])

const store_service_company = useServiceCompanyStore()

</script>

<style scoped>
.error-message {
    color: red;
}
</style>
