<template>

    <div class="ui grid ">
        <div class="eleven wide column">

            <div class="field">
                <div class="ui input">
                    <vee-field as="select"
                               class="ui fluid dropdown"
                               :class="{error: `need_costs[${props.idx}].priceListItem` in props.errors}"
                               :name="`need_costs[${props.idx}].priceListItem`"
                               @change="calculate"
                               @blur="calculate">
                        <option value=""></option>
                        <option v-for="priceList_item in store_calculation.availableCosts(store_contract.contract.productType.id)"
                                :value="priceList_item.id" :key="priceList_item.id">


                            {{ priceList_item.name }}

                            <template v-if="false">
                                source: {{ priceList_item.source }}
                                deprecated: {{ priceList_item.deprecated }}
                                used: {{ priceList_item.used}}
                                optional: {{priceList_item.optional}}
                            </template>

                            <template v-if="priceList_item.categoryName === 'monthly_fee'">&nbsp;-----&nbsp;Havidíj:
                                {{ priceList_item.unitPriceMonthly }} Ft/hó&nbsp;&nbsp;
                            </template>

                            <template v-if="priceList_item.categoryName === 'install_cost'">&nbsp;------&nbsp;
                                Felszerelési díj: {{ priceList_item.unitPriceOneTime }} Ft/db&nbsp;&nbsp;
                            </template>

                            <template v-if="priceList_item.source === '2'">
                                &nbsp;-----&nbsp; (saját felvitel) &nbsp;&nbsp;
                            </template>

                            <template v-if="priceList_item.source === '3'">
                                &nbsp;-----&nbsp; HASZNÁLATA KERÜLENDŐ &nbsp;-----&nbsp;
                            </template>




                        </option>
                    </vee-field>
                </div>
                <error-message :name="`need_costs[${props.idx}].priceListItem`" class="error-message"/>
            </div>
        </div>

        <div class="four wide column">

            <div class="field">
                <div class="ui input">
                    <vee-field
                            :class="{error: `need_costs[${props.idx}].quantity` in props.errors}"
                            :name="`need_costs[${props.idx}].quantity`"
                            @change="calculate"
                            @blur="calculate">
                    </vee-field>
                </div>
                <error-message :name="`need_costs[${props.idx}].quantity`" class="error-message"/>
            </div>
        </div>

        <div class="one wide center aligned column">
            <i class="trash red  icon" @click="removeItem(props.idx)"/>
        </div>


    </div>

</template>

<script setup>
import {ErrorMessage, Field as VeeField} from "vee-validate";

import {defineProps} from "vue";

import {useCalculationStore} from "@/store-pinia/calculation";
import {useContractStore} from "@/store-pinia/api/contract";

const props = defineProps(['remove', 'idx', 'errors', 'values']);


const store_calculation = useCalculationStore()
const store_contract = useContractStore()

function calculate() {
    store_calculation.setNeeds(props.values.need_costs)
    store_calculation.calculate()
}


function removeItem(idx) {
    props.remove(idx)
    store_calculation.calculate()
}

</script>

<style scoped>
.error-message {
    color: red
}

</style>