<template>
  <div class="ui segment">
    <h3>Milyen terméktípusra készül az árajánlat?</h3>

    <div class=" ui five cards">
      <div v-for="product in store_products.products" :key="product.id"
           class="card pointer"
           :class="{selected: store_page.product && product.id ===store_page.product.id}"
           @click="store_page.setProduct(product)">
        <div class="content center aligned">
          {{ product.name }}
        </div>
      </div>
    </div>


  </div>


</template>

<script setup>
import {useProductsStore} from "@/store-pinia/api/products";
import {useCreateQuotationPageStore} from "@/store-pinia/pages/createQuotationPage";

const store_products = useProductsStore()

const store_page = useCreateQuotationPageStore()



</script>

<style scoped>
.pointer {
  cursor: pointer;
}
.selected{
  font-size: larger;
  font-weight: bolder;
  color:red;
}
</style>