<template>
    <div class="ui segment">
        <div class="ui equal width middle aligned grid">
            <div class="center aligned column">
                <button class="ui red tiny button"
                        @click="showPDF('site-reverse')">Helyesbítő melléklet
                </button>
            </div>

            <div class="center aligned column">
                <button class="ui yellow tiny button"
                        @click="showPDF('site-view')">Megtekintés
                </button>
            </div>

            <div class="center aligned  column">
                <button class="ui green tiny button"
                        @click="showPDF('site-appendix')">Számla melléklet
                </button>
            </div>
        </div>


    </div>
</template>


<script setup>
import {useSitesStore} from "@/store-pinia/api/site";

const store_site = useSitesStore();


function showPDF(pdfType) {

    const site_id = store_site.costsInPeriod.site.id

    const period = {
        periodStart: store_site.costsInPeriod.requestedPeriod.periodStart,
        periodEnd: store_site.costsInPeriod.requestedPeriod.periodEnd
    }

    store_site.downloadPDF(site_id, pdfType, period)
}


</script>

