import {defineStore} from "pinia";

export const usePageCreateContractStore = defineStore({
    id: 'pageCreateContract',
    state: () => {
        return {
            dateOfContract: (new Date()).toISOString().split('T')[0],
            isTrial: false,
            signed: false,
            noticePeriodDay: 30,
            reActivateFee: 15000,
            billingPeriod: -1,
            payMethod: "bank",
            prompt: 8,
            dueOn: 1,
            quotation: null,
            product: null,
            salesMan: null,
            assignedSalesMan: null,
            createdBy: null,
            updatedBy: null,
            loyaltyName: "loyalty_year_1",
            serviceCompany: null,
            customer: null,
            delegatePerson: null,
            financialPerson: null,
            technicalPerson: null,
            billRecipientEmails: [],
            activeStep: 1,
            showPersonsList: false,
            intentSelectPerson: null,
            showNewPersonForm: false
        }
    },
    actions: {

        setDateOfContract(date_of_contract) {
            this.dateOfContract = date_of_contract
        },
        setIsTrial(is_trial) {
            this.isTrial = is_trial
        },
        setSigned(signed) {
            this.signed = signed
        },
        setNoticePeriodDay(notice_period_day) {
            this.noticePeriodDay = notice_period_day
        },
        setReActivateFee(reActivateFee) {
            this.reActivateFee = reActivateFee
        },
        setBillingPeriod(billingPeriod) {
            this.billingPeriod = billingPeriod
        },
        setPayMethod(payMethod) {
            this.payMethod = payMethod
        },
        setPrompt(prompt) {
            this.prompt = prompt
        },
        setDueOn(dueOn) {
            this.dueOn = dueOn
        },
        setQuotation(quotation_id) {
            this.quotation = quotation_id
        },
        setProduct(product_id) {
            this.product = product_id
        },
        setLoyaltyName(loyaltyName) {
            this.loyaltyName = loyaltyName
        },
        setServiceCompany(serviceCompany_id) {
            this.serviceCompany = serviceCompany_id
        },
        setCustomer(customer_id) {
            this.customer = customer_id
        },
        setDelegatePerson(delegatePerson) {
            this.delegatePerson = delegatePerson
        },
        setFinancialPerson(financialPerson) {
            this.financialPerson = financialPerson
        },
        setTechnicalPerson(technicalPerson) {
            this.technicalPerson = technicalPerson
        },
        addBillRecipientEmails(email_value) {
            this.billRecipientEmails.push({email: email_value})
        },
        setActiveStep(active_step) {
            this.activeStep = active_step
        },
        setShowPersonsList(show) {
            this.showPersonsList = show
        },
        setIntentSelectPerson(intent){
            this.intentSelectPerson = intent
        },
        setShowNewPersonForm(show_value){
            this.showNewPersonForm = show_value
        }

    },
    getters: {},
});

