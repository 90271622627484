import wsseToken from "@/_helper/wsse";
import {useOroStore} from "@/store-pinia/oro";

export default function setupAuthHeaderFor(authType) {
    const store_oro = useOroStore();
    const config = {
        headers: {
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': '0'
        }
    };

    switch (authType) {
        case 'jwt':
            config.headers['X-CHK-JWT-TOKEN'] = store_oro.admin3.token;
            break;
        case 'wsse':
            config.headers.Authorization = 'WSSE profile="UsernameToken"';
            config.headers['X-WSSE'] = wsseToken(store_oro.user, store_oro.apiKey);
            break;
        default:
            console.warn('Unsupported auth type');
            break;
    }
    return config;
}
