

const state = () => ({
    activeStep: 1,
    serviceCompany: '',
    customer: null,
    accountCustomers: [],
    quotations: [],
    quotation: null,
    opportunity: null,
    opportunityIsReady: false,
    dateOfContract: (new Date()).toISOString().split('T')[0],
    payMethod: 'bank',
    prompt: 8,
    billingPeriod: "-1",
    isTrial: false,
    noticePeriodDay: 30,
    reActivateFee: 15000,
    loyaltyName: 'loyalty_year_1',

    billEmails: [""],
    contractItems: [
        {
            "name": "",
            "city": "",
            "street": "",
            "createdAddress": null,
            "needs": [
                {"costType": "", "quantity": 1},
            ],
            "costs": []

        }
    ],

    calculationResults: [],

    productTypeInfo: null,
    productTypeCosts: [],
    contactPersons: [],
    delegatePerson: '',
    financialPerson: '',
    technicalPerson: '',
    intentSelectPerson: null,
    showPersonsList: false,
    productType: '',
    salesMan: null,
    account: null,
    contact: null,
    sending: false,
    createdContract: null,
    zipCode: '',
    city: '',
    street: '',
    email: '',
    approvePerson: {
        name: '',
        title: '',
        email: '',
        phone: ''
    }

})

const getters = {}

const mutations = {
    setActiveStep(state, newStep) {
        state.activeStep = newStep
    },
    setServiceCompany(state, serviceCompany) {
        state.serviceCompany = serviceCompany
    },
    setQuotations(state, quotations) {
        state.quotations = quotations
    },
    setQuotation(state, quotation) {
        state.quotation = quotation
    },
    setOpportunity(state, opportunity) {
        state.opportunity = opportunity
    },
    setAccount(state, account) {
        state.account = account
    },
    setContact(state, contact) {
        state.contact = contact
    },
    setDateOfContract(state, dateOfContract) {
        state.dateOfContract = dateOfContract
    },
    setCustomer(state, customer) {
        state.customer = customer
    },
    setPayMethod(state, payMethod) {
        state.payMethod = payMethod
    },
    setPrompt(state, prompt) {
        state.prompt = prompt
    },
    setBillingPeriod(state, period) {
        state.billingPeriod = period
    },
    setIsTrial(state, isTrial) {
        state.isTrial = isTrial
    },
    setNoticePeriodDay(state, noticePeriodDay) {
        state.noticePeriodDay = noticePeriodDay
    },
    setReActivateFee(state, reActivateFee) {
        state.reActivateFee = reActivateFee
    },
    setLoyaltyName(state, loyalty) {
        state.loyaltyName = loyalty
    },
    setBillEmails(state, billEmails) {
        state.billEmails = billEmails
    },
    setItems(state, items) {
        state.items = items
    },
    setSending(state, value) {
        state.sending = value
    },
    setCreatedContract(state, createdContract) {
        state.createdContract = createdContract
    },
    setProductType(state, productType) {
        state.productType = productType
    },
    setAccountCustomers(state, accountCustomers) {
        state.accountCustomers = accountCustomers
    },
    setDelegatePerson(state, person) {
        state.delegatePerson = person
    },
    setFinancialPerson(state, person) {
        state.financialPerson = person
    },
    setTechnicalPerson(state, person) {
        state.technicalPerson = person
    },
    setEmail(state, email) {
        state.email = email
    },
    setZipCode(state, zipCode) {
        state.zipCode = zipCode
    },
    setCity(state, city) {
        state.city = city
    },
    setStreet(state, street) {
        state.street = street
    },
    setCreatedAddress(state, createdAddress) {
        state.contractItems[createdAddress.index] = createdAddress.id
    },
    addContactPerson(state, contactPerson) {
        state.contactPersons.push(contactPerson)
    },
    setProductTypeInfo(state, info) {
        state.productTypeInfo = info
    },
    setProductTypeCosts(state, costs) {
        state.productTypeCosts = costs
    },
    setIntentSelectPerson(state, intent) {
        state.intentSelectPerson = intent
    },
    setShowPersonsList(state, show) {
        state.showPersonsList = show
    },
    setCalculationResult(state, calculationResult) {
        state.calculationResults[calculationResult.idx] = calculationResult.result
    },
    saveItems(state, items) {
        state.contractItems = items
    },
    setOpportunityIsReady(state, isReady) {
        state.opportunityIsReady = isReady
    },
    setApprovePerson(state, approvePerson) {
        state.approvePerson = approvePerson
    }

}

const actions = {

    setQuotation({commit}, quotation) {
        commit('setQuotation', quotation)

    },

    setProductType({commit, dispatch}, product_type_id) {
        commit('setProductType', product_type_id)
        dispatch('fetchProductType', product_type_id)
    },


}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

