<template>

  <div id="main" class="ui container segment ">
    <h2 class="ui header">Telephely szerkesztése</h2>

    <vee-form class="ui form"
              @submit="onSubmit"
              :validation-schema="schema"
              :initial-values="initialValues"
              v-slot="{errors}">


      <edited-item-widget-component :errors="errors"/>


      <div class="sixteen wide column right aligned saveBtn">
        <button class="ui teal button" type="submit">Telephely mentése</button>
      </div>

    </vee-form>

  </div>


</template>

<script>
import {Form} from 'vee-validate';

import {mapState} from "vuex";

import EditedItemWidgetComponent from "@/components/contract/update/item-edited/itemWidgetComponent";
import * as yup from "yup";

export default {
  name: "ContractItemUpdatePage",
  data() {
    return {
      schema: yup.object().shape(
          {
            name: yup.string().required('A telepítés helyét el kell nevezni!'),
            city: yup.string().required('A település nevét meg kell adni!'),
            street: yup.string().required('Megadása kötelező!'),
            needs: yup.array().of(
                yup.object().shape({
                      costType: yup.string().required('Meg kell adni!'),
                      quantity: yup.number('Csak szám lehet!').required('A mennyiséget meg kell adni!')
                    }
                )
            )
          }),
      initialValues: {
        'name': this.$store.state.updateContract.editedItem.nameAtContract,
        'city': this.$store.state.updateContract.editedItem.address.city,
        'street': this.$store.state.updateContract.editedItem.address.street,
        'needs': [
          {
            costType: '',
            quantity: 1
          }
        ]
      },
    }
  },
  components: {
    VeeForm: Form,
    EditedItemWidgetComponent
  },
  computed: {
    ...mapState({
      contract: state => state.updateContract.contract,
      item: state => state.updateContract.editedItem
    })
  },
  methods: {
    async onSubmit(values) {

      const calculation = this.$store.state.updateContract.editedCalculationResult;

      //1. Update name (nameAtContract)
      const item = {...this.item}
      item.nameAtContract = values.name
      this.$store.dispatch('updateContract/updateItem', item)

      //2. Update address
      this.$store.dispatch('address/updateAddress',
          {
            id: this.item.address.id,
            city: values.city,
            street: values.street
          })

      //3. delete old costs
      for (let i = 0; i < this.item.costs.length; i++) {
        this.$store.dispatch('updateContract/deleteCost', this.item.costs[i].id)
      }

      //4. create new costs
      for (let i = 0; i < calculation.costs.length; i++) {
        const cost_data = calculation.costs[i]
        this.$store.dispatch('updateContract/createItemCost', {item: this.item.id, ...cost_data})
      }

      this.$router.push('/contract/update')

    },

  }

}
</script>

<style scoped>
.saveBtn {
  margin-top: 1rem;
}
</style>
