<template>
    <form @submit.prevent="onCostDeepTestingSubmit" class="ui form">
        <div class="ui sixteen width grid">
            <div class="eight wide column">
                <div class="field" :class="{error: `startDate` in errors}">
                    <label>Bekapcsolás</label>
                    <div class="ui input">
                        <vee-field type="date" name="startDate"/>
                    </div>
                    <error-message name="startDate" class="error-message"/>
                </div>
            </div>
            <div class="eight wide column">
                <div class="field" :class="{error: `stopDate` in errors}">
                    <label>Kikapcsolás</label>
                    <div class="ui input">
                        <vee-field type="date" name="stopDate"/>
                    </div>
                    <error-message name="stopDate" class="error-message"/>
                </div>
            </div>
        </div>

        <div class="ui segment">
            <div class="ui eight width grid">
                <div class="eight wide column">
                    <button type="button" class="ui basic blue button" @click="clearBothDates">
                        Új - (Nincs bekapcsolás, nincs kikapcsolás)
                    </button>
                </div>
                <div class="eight wide column">
                    <button type="button" class="ui basic blue button" @click="clearStopDate">
                        Aktív - (Nincs kikapcsolás)
                    </button>
                </div>
            </div>
        </div>

        <div class="ui segment">
            <div class="actions">
                <button class="ui black deny button" @click="$emit('close')">Mégsem</button>

                <button type="submit" class="ui positive right labeled icon button">
                    Dátumok beállítása <i class="checkmark icon"></i>
                </button>

            </div>
        </div>
    </form>
</template>


<script setup>
import {computed, defineEmits} from 'vue';
import {useStore} from 'vuex';
import {ErrorMessage, Field as VeeField, useForm} from 'vee-validate';
import * as yup from 'yup';

const vuex_store = useStore();
const emit = defineEmits(['close']);


const cost = computed(() => vuex_store.state.updateContract.editedCost);

const initialValues = computed(() => ({
    startDate: cost.value.activateDate,
    stopDate: cost.value.terminateDate
}));


const schema = yup.object({
    startDate: yup.date().nullable(),
    stopDate: yup.date().nullable(),
});

const {handleSubmit, errors, setFieldValue} = useForm({
    validationSchema: schema,
    initialValues: initialValues
});

const clearBothDates = () => {
    setFieldValue('startDate', null);
    setFieldValue('stopDate', null);
};

const clearStopDate = () => {
    setFieldValue('stopDate', null);
};


const onCostDeepTestingSubmit = handleSubmit(async (values) => {

    const send_data = {
        cost_id: vuex_store.state.updateContract.editedCost.id,
        contract_id: vuex_store.state.updateContract.contract.id,
        ...values
    }

    await vuex_store.dispatch('updateContract/costDeepTest', send_data)

    emit('close')
});


</script>


<style scoped>
.form {
    margin-right: 2rem;
}

</style>