<template>
    <div class="ui  message">
        <div class="ui sixteen width grid">
            <div class="four wide column">
                <strong>
                    Vizsgált időszak:
                </strong>
            </div>
            <div class="six wide center aligned column">
                <strong>
                    {{ store_site.costsInPeriod.requestedPeriod.periodStart }} -
                    {{ store_site.costsInPeriod.requestedPeriod.periodEnd }}
                </strong>
            </div>
            <div class="six wide column">
                <div class="pointer" @click.stop="toggleShowCostSegments()">
                    <template v-if="store_site.costsInPeriod.showMergedCostSegments">
                        <i class="check squared  icon pointer"/>
                        Hónapok <strong>összevonva</strong>
                    </template>
                    <template v-else>
                        <i class="expand icon pointer"/>
                        Hónapok <strong>kibontva</strong>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {useSitesStore} from "@/store-pinia/api/site";

const store_site = useSitesStore();

function toggleShowCostSegments() {
    store_site.costsInPeriod.showMergedCostSegments = !store_site.costsInPeriod.showMergedCostSegments
}


</script>
