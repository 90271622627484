<template>
    <div class="ui sixteen width grid sum">
        <div class="twelve wide column ">

        </div>

        <div class="four wide right aligned column">
            <hr>
            <div class="ui equal width grid ">
                <div class="column">
                    Összesen:
                </div>
                <div class="column sum-value">
                    {{ numberFormat(props.siteAtPeriod.sumCostPrices, '0', 0) }} Ft
                </div>
            </div>


        </div>
    </div>
</template>


<script setup>

import {numberFormat} from "@/_helper/numberFormat";

import {defineProps} from "vue";
const props = defineProps(['siteAtPeriod'])

</script>


<style scoped>
.sum {
    font-weight: bold;
    font-style: italic;
}

.sum-value {
    color: red;
}
</style>