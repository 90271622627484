<template>

  <Teleport to="body">

    <modal :show="store_page.showModalCreatePriceList" :size="'modal-medium'"
           @close="onCloseModal">
      <template #header>
        Új tétel hozzáadása az árlistához
      </template>

      <template #body>
        <div class="modal-body">
          <create-new-price-list-item/>
        </div>
      </template>
    </modal>

  </Teleport>


</template>

<script setup>
import Modal from "@/components/slots/modal.vue";
import CreateNewPriceListItem from "@/components/quotationUpdate/create-new-pricelist-item.vue"
import {useUpdateQuotationPageStore} from "@/store-pinia/pages/updateQuotationPage";


const store_page = useUpdateQuotationPageStore();

function onCloseModal() {
  store_page.setFocusPriceListElement(null)
  store_page.setShowModalCreatePriceList(false)
}


</script>


<style scoped>
.modal-body {
  padding: 1rem;
  min-height: 5rem;
}
</style>