<template>
  <Teleport to="body">
    <modal :show="show" :size="'modal-large'"
           @close="onCloseModal">
      <template #header>A cég egyedi árlistája</template>
      <template #body>
        <div class="ui sixteen width grid">
          <div class="eight wide column">
            <financial-contact-person/>
          </div>
          <div class="seven wide right aligned column">


            <button class="ui basic red  button"
                    @click="navigateToShowPDF">
              <i class="file pdf outline icon"></i>
              PDF az árelemésről
            </button>


          </div>

        </div>
        <hr class="divider">

        <vee-form class="ui form"
                  @submit="onSubmit"
                  :initial-values="initialValues"
                  :validation-schema="schema"
                  v-slot="{values, fields, errors}">


          <price-list-actions :fields="fields" :errors="errors" :values="values"/>
          <hr class="divider">
          <price-list-monthly-fees :fields="fields" :errors="errors"/>
          <hr class="divider">
          <price-list-install-costs :fields="fields" :errors="errors"/>
          <hr class="divider">
          <price-list-service-costs :fields="fields" :errors="errors"/>
        </vee-form>

      </template>
    </modal>
  </Teleport>
</template>

<script>
import {mapState} from "vuex";
import Modal from "@/components/slots/modal.vue"
import PriceListMonthlyFees from "@/components/contract/update/contract-priceList/price-list-monthly-fees.vue";
import PriceListInstallCosts from "@/components/contract/update/contract-priceList/price-list-install-costs.vue";
import PriceListServiceCosts from "@/components/contract/update/contract-priceList/price-list-service-costs.vue";
import FinancialContactPerson
  from "@/components/contract/update/contract-priceList/price-list-financial-contact-person.vue";
import PriceListActions from "@/components/contract/update/contract-priceList/price-list-actions.vue";
import {Form as VeeForm} from 'vee-validate'
import * as yup from "yup";
import {useContractPriceListStore} from "@/store-pinia/contractPriceList";


export default {
  name: "modal-price-list",


  components: {
    PriceListActions,
    FinancialContactPerson,
    PriceListServiceCosts,
    PriceListMonthlyFees,
    PriceListInstallCosts,
    VeeForm,

    Modal
  },

  data() {

    return {
      schema: yup.object({
        dateOfChange: yup.date('Érvényes dátumra van szükség').required('Az érvényesség kezdetét meg kell admin'),
        contractPrices: yup.array().of(
            yup.object().shape({
                  id: yup.string().required('Meg kell adni!'),
                  newPrice: yup.number('Csak szám lehet!').required('A mennyiséget meg kell adni!')
                }
            )
        )
      }),
      initialValues: {
        dateOfChange: null
      },
    }

  },

  methods: {
    onCloseModal() {
      this.$store.commit('updateContract/setShowPriceListModal', false)
    },
    onSubmit(values) {
      const store_priceList = useContractPriceListStore()
      store_priceList.callCreateContractPriceChange(values)

    },
    navigateToShowPDF() {
      window.location.href = this.$store.state.app.serviceAdminServer + '/price-increase-pdf/' + this.$route.params.contract_id;
    },

  },
  computed: {
    ...mapState({
      show: state => state.updateContract.showPriceListModal,
    })

  }

}
</script>


<style scoped>
.divider {
  margin: 2rem 0;
}
</style>
