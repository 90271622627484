<template>
    <Teleport to="body">
        <Modal :show="show()" :size="'modal-medium'" @close="onCloseClick">
            <template #header>
                <span class="role">{{ roleName() }}</span>
                szerkesztése, új felvétele vagy cseréje
            </template>

            <template #body>
                <person-edit-or-add-for-role/>
                <div class="ui horizontal divider">VAGY</div>
                <person-select-for-role/>
            </template>
        </Modal>
    </Teleport>
</template>

<script setup>

import {useStore as useVuexStore} from "vuex";
import Modal from "@/components/slots/modal.vue";
import PersonSelectForRole from "@/components/contract/update/person-select-for-role.vue";
import PersonEditOrAddForRole from "@/components/contract/update/person-edit-or-add-for-role.vue";


const vuex_store = useVuexStore();

function show() {
    return vuex_store.state.updateContract.showEditPersonModal;
}

function onCloseClick() {
    vuex_store.commit('updateContract/setShowEditPersonModal', false)
}

function roleName() {
    const role = vuex_store.state.updateContract.editedPersonRole;
    if (role === 'delegatePerson') {
        return 'Aláíró személy';
    } else if (role === 'financialPerson') {
        return 'Pénzügyi kapcsolattartó';
    } else if (role === 'technicalPerson') {
        return 'Műszaki kapcsolattartó';
    }

    return '';
}



</script>

<style scoped>
.role {
    color: red
}
</style>
