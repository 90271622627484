<template>
    <div class="column center aligned">
        <div class="ui big blue message">
            <div class="header">
                {{ store_page.product ? store_page.product.name : '' }}
            </div>

            <p v-if="store_page.quotation">
                Az <strong>{{ store_page.quotation.registrationNumber }}</strong> árajánlat alapján
            </p>

        </div>
    </div>
</template>

<script setup>
import {usePageCreateContractStore} from "@/store-pinia/pages/pageCreateContract";

const store_page = usePageCreateContractStore();

</script>

<style scoped>

</style>