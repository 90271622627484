<template>
  <Teleport to="body">
    <modal :show="store_page.showModalAddToItems" :size="'modal-medium'"
           @close="onCloseModal">
      <template #header>
        <div class="ui sixteen width grid">
          <div class="eight wide column">
            Mennyit adjunk hozzá ebből?
          </div>
          <div class="eight wide center aligned column">
            {{ store_page.focusPriceListElement.name }}
          </div>
        </div>
      </template>

      <template #body>
        <div class="modal-body">
          <add-element-to-items/>

        </div>
      </template>
    </modal>
  </Teleport>
</template>

<script setup>

import Modal from "@/components/slots/modal.vue";
import AddElementToItems from "@/components/quotationUpdate/add-element-to-items.vue"
import {useUpdateQuotationPageStore} from "@/store-pinia/pages/updateQuotationPage";

const store_page = useUpdateQuotationPageStore();


function onCloseModal() {
  store_page.setFocusPriceListElement(null)
  store_page.setShowModalAddToItems(false);
}


</script>

<style scoped>
.modal-body {
  padding: 1rem;
  min-height: 5rem;
}
</style>