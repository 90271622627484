<template>

  <div class="ui sixteen width grid">
    <div class="six wide column" :class="{deprecated: !cost.contractPriceItem.selectable}">


      {{ cost.contractPriceItem.name }}
    </div>

    <div class="one wide column">
      <i v-if="cost.contractPriceItem.source===2" class="user plus icon"/>
      <i v-if="cost.contractPriceItem.isUsed" class="check green icon"/>
    </div>


    <div v-if="!store.editedChange" class="nine wide right aligned column">
      <price-history :cost="cost" :fields="props.fields"/>
    </div>
    <div v-else class="nine wide right aligned column">
      <edit-current-price :cost="cost"/>
    </div>
  </div>

</template>

<script setup>
import PriceHistory from "@/components/contract/update/contract-priceList/price-history.vue";
import EditCurrentPrice from "@/components/contract/update/contract-priceList/edit-current-price.vue";
import {defineProps} from "vue";
import {useContractPriceListStore} from "@/store-pinia/contractPriceList";


const props = defineProps(['cost', 'fields']);
const store = useContractPriceListStore();


</script>

<style scoped>
.deprecated {
  color: red;
  text-decoration: line-through;
}

</style>