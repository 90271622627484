export const PAGE_HOMEPAGE = 'HomePage';


export const PAGE_QUOTATION_CREATE = 'QuotationCreatePageOld';


export const PAGE_QUOTATION_EDIT = 'QuotationEditPage';

export const PAGE_QUOTATION_CREATED = 'QuotationCreateDPage';

export const PAGE_CONTRACT_CREATE = 'ContractCreatePage';
export const PAGE_CONTRACT_CREATED = 'ContractCreatedPage';

export const PAGE_CONTRACT_UPDATE = 'ContractUpdatePage';
export const PAGE_CONTRACT_ITEM_UPDATE = 'ContractItemUpdatePage';

export const PAGE_APPROVE_REQUEST = 'ApproveRequestPage';

