import axios from 'axios'
import router from '@/router'
import setupAuthHeaderFor from "@/_helper/setupAxiosHeader";
import {useOroStore} from "@/store-pinia/oro";


const state = () => ({
    products: [],
    serviceCompanies: [],
    salesMans: [],
    apiKey: null,
    user: null,
    currentUser: null,
    hostServer: null,
    isAuthenticated: false,
    hostServerParams: null,
    navigate: null,
    serviceAdminServer: null,
    preferredProductTypeId: null
})

const getters = {}

const mutations = {
    setProducts(state, products) {
        state.products = products
    },
    setServiceCompanies(state, serviceCompanies) {
        state.serviceCompanies = serviceCompanies
    },
    setSalesMans(state, salesMans) {
        state.salesMans = salesMans
    },
    setApiKey(state, apiKey) {
        state.apiKey = apiKey
    },
    setUser(state, user) {
        state.user = user
    },
    setHostServer(state, hostServer) {
        state.hostServer = hostServer
    },
    setIsAuthenticated(state, isAuthenticated) {
        state.isAuthenticated = isAuthenticated
    },
    setHostServerParams(state, params) {
        state.hostServerParams = params
    },
    setCurrentUser(state, currentUser) {
        state.currentUser = currentUser
    },
    setNavigate(state, navigate) {
        state.navigate = navigate
    },
    setServiceAdminServer(state, server_name) {
        state.serviceAdminServer = server_name
    },
    setPreferredProductTypeId(state, preferred_product_type_id) {
        state.preferredProductTypeId = preferred_product_type_id
    }

}

const actions = {

    async auth({dispatch}, encodedCredentials) {
        if (encodedCredentials) {
            try {
                const decodedData = atob(encodedCredentials)
                const decodedObject = JSON.parse(decodedData)

                if (decodedObject.baseURL && decodedObject.userName && decodedObject.token) {
                    await dispatch('login', decodedObject)
                }
            } catch (error) {
                console.error("Hiba történt a felhasználó azonosításakor", error);
            }
        }
    },

    async fetchProducts({commit, state}) {
        try {
            const axiosConfig = setupAuthHeaderFor('wsse');
            const url = `${state.hostServer}/api/adminproducttypeentities`; // URL összeállítása

            const response = await axios.get(url, axiosConfig);
            commit('setProducts', response.data);
        } catch (error) {
            console.error("Hiba történt a termékek lekérésekor:", error);
        }
    },


    async fetchServiceCompanies({commit, state}) {
        try {
            const axiosConfig = setupAuthHeaderFor('wsse');
            const url = `${state.hostServer}/api/adminservicecompanies`;
            const response = await axios.get(url, axiosConfig);
            commit('setServiceCompanies', response.data);
        } catch (error) {
            console.error("Hiba történt a szolgáltató cégek lekérésekor:", error);
        }
    },


    async fetchCurrentUser({commit, state}) {
        try {

            const axiosConfig = setupAuthHeaderFor('wsse');
            const url = `${state.hostServer}/api/userprofile`;
            const response = await axios.get(url, axiosConfig);
            commit('setCurrentUser', response.data);

            const store_oro = useOroStore();
            store_oro.setUserId(response.data.id)
            await store_oro.loginToAdmin3();
        } catch (error) {
            console.error("Hiba történt a felhasználói profil lekérésekor:", error);
        }
    },


    async login({commit, dispatch, state}, credentials) {
        const store_oro = useOroStore();


        // Állapotok beállítása
        commit('setUser', credentials.userName);
        commit('setHostServer', credentials.baseURL);
        commit('setApiKey', credentials.token);
        commit('setIsAuthenticated', true);
        commit('setHostServerParams', credentials.params);
        commit('setNavigate', credentials.navigate);
        commit('setServiceAdminServer', credentials.serviceAdminHost);
        commit('setPreferredProductTypeId', credentials.preferred_product_type_id);


        // Oro store állapotainak beállítása
        store_oro.setHost(credentials.baseURL);
        store_oro.setUser(credentials.userName);
        store_oro.setApiKey(credentials.token);
        store_oro.setAdmin3Host(credentials.serviceAdminHost);

        // Aszinkron műveletek indítása
        dispatch('fetchCurrentUser');
        dispatch('fetchProducts');
        dispatch('fetchServiceCompanies');

        // Navigáció a megadott útvonalra
        try {
            await router.push({path: state.navigate.to});
        } catch (error) {
            console.error("Navigációs hiba:", error);
        }
    },


    logout({commit}) {
        commit('setUser', null)
        commit('setHostServer', null)
        commit('setApiKey', null)
        commit('setIsAuthenticated', false)
        commit('setProducts', [])
        commit('setServiceCompanies', [])
        router.push({path: '/'})
    },


    async returnToCRM({state}) {
        window.location.href = state.navigate.return
    }

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
