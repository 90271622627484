<template>
    <vee-form v-if="props.person"
              class="ui form "
              @submit="onPersonFormSubmit"
              :validation-schema="schema"
              :initial-values="initialValues"
              v-slot="{errors}">

        <div class="two fields">
            <div class="field" :class="{error: `lastName` in errors}">
                <label>Vezetéknév</label>
                <div class="ui input">
                    <vee-field type="text" name="lastName"/>
                </div>
                <error-message name="lastName" class="error-message"/>
            </div>
            <div class="field" :class="{error: `firstName` in errors}">
                <label>Keresztnév</label>
                <div class="ui input">
                    <vee-field type="text" name="firstName"/>
                </div>
                <error-message name="firstName" class="error-message"/>
            </div>
        </div>

        <div class="field" :class="{error: `primaryEmail` in errors}">
            <label>E-mail cím</label>
            <div class="ui input">
                <vee-field type="email" name="primaryEmail"/>
            </div>
            <error-message name="primaryEmail" class="error-message"/>
        </div>

        <div class="field" :class="{error: `primaryPhone` in errors}">
            <label>Telefonszám</label>
            <div class="ui input">
                <vee-field type="text" name="primaryPhone"/>
            </div>
            <error-message name="primaryPhone" class="error-message"/>
        </div>


        <button class="ui blue submit button" type="submit">{{ props.action.label }}</button>

    </vee-form>
</template>


<script setup>
import {computed, defineProps} from 'vue';
import {ErrorMessage, Field as VeeField, Form as VeeForm} from 'vee-validate';

import {useStore as useVuexStore} from "vuex";
import * as yup from 'yup';
import {useApiPersonsStore} from "@/store-pinia/api/persons";

const vuex_store = useVuexStore();


const props = defineProps(['action', 'person']);


const initialValues = computed(() => ({
    firstName: props.person.firstName,
    lastName: props.person.lastName,
    primaryEmail: props.person.primaryEmail,
    primaryPhone: props.person.primaryPhone
}));


const schema = yup.object().shape(
    {
        firstName: yup.string().required('Keresztnév megadása szükséges'),
        lastName: yup.string().required('Vezetéknév megadása szükséges'),
        primaryEmail: yup.string().email('Ez nem megfelelő e-mail cím'),
        primaryPhone: yup.string()
    }
);

async function onPersonFormSubmit(values) {
    const role = vuex_store.state.updateContract.editedPersonRole;
    const account = vuex_store.state.updateContract.contract.customer.account.id;
    const accounts = [account];
    const contractId = vuex_store.state.updateContract.contract.id;
    const personId = props.person.id;

    const persons_store = useApiPersonsStore();

    console.log(role);

    try {
        if (personId === undefined) {
            const newPersonData = {...values, accounts};
            await persons_store.createContactPerson(newPersonData);
            // További logika a szerződés frissítéséhez
        } else {
            await persons_store.updateContactPerson(personId, values);
            // További logika a szerződés frissítéséhez
        }

        // Az egész contract frissítése
        vuex_store.dispatch('updateContract/fetchContract', contractId);


    } catch (error) {
        console.error("Hiba történt a személy létrehozásakor/módosításakor", error);
    } finally {
        vuex_store.commit('updateContract/setShowEditPersonModal', false);
    }
}


</script>

<style scoped>

.error {
    color: red;
}

</style>