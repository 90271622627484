<template>
    <vee-form class="ui form"
              @submit="onSubmit"
              :validation-schema="schema"
              :initial-values="initialValues"
              v-slot="{errors, fields}">


        <div class="ui equal width grid">
            <contract-general-form-fields :errors="errors" :fields="fields"/>
            <product-info/>
        </div>

        <div v-if="store_page.showPersonsList">
            <contact-persons-list/>
        </div>

        <div v-else>
            <div style="margin-top: 1rem">
                <customer-form-fields :errors="errors" :fields="fields"/>
                <dedicated-persons/>
            </div>
        </div>

        <div class="ui segment">
            <div class="ui equal width column grid">
                <div class="column">
                    <button class="ui basic green button"
                            type="button"
                            @click="toPrev">Vissza</button>
                </div>

                <div class="column right aligned">
                    <button class="ui green button" type="submit">Tovább</button>
                </div>
            </div>
        </div>


    </vee-form>

</template>

<script setup>

import * as yup from 'yup';
import CustomerFormFields from "@/components/contract/create/customerFormFields";
import ContractGeneralFormFields from "@/components/contract/create/contractGeneralFormFields";
import DedicatedPersons from "@/components/contract/create/step-b-dedicated-persons.vue";
import {usePageCreateContractStore} from "@/store-pinia/pages/pageCreateContract";
import {useOpportunitiesStore} from "@/store-pinia/api/opportunities";
import {Form as VeeForm} from 'vee-validate'

import ContactPersonsList from "@/components/contract/create/step-b-person-list.vue";

import ProductInfo from "@/components/contract/create/step-b-procuct-info.vue"


const store_page = usePageCreateContractStore()
const store_opportunity = useOpportunitiesStore()


const initialValues = {
    'dateOfContract': store_page.dateOfContract,
    'serviceCompany': store_page.serviceCompany,

    'postalCode': store_opportunity.opportunity ? store_opportunity.opportunity.account.customer.postingAddress.postalCode : '',
    'city': store_opportunity.opportunity ? store_opportunity.opportunity.account.customer.postingAddress.city : '',
    'street': store_opportunity.opportunity ? store_opportunity.opportunity.account.customer.postingAddress.street : '',

    'mainEmail': store_opportunity.opportunity ? store_opportunity.opportunity.account.customer.mainEmail : '',

    'delegatePerson': store_opportunity.opportunity ? store_opportunity.opportunity.account.customer.delegatePerson : null,
    'financialPerson': store_opportunity.opportunity ? store_opportunity.opportunity.account.customer.financialPerson : null,
    'technicalPerson': store_opportunity.opportunity ? store_opportunity.opportunity.account.customer.technicalPerson : null
}

const schema = yup.object({
    dateOfContract: yup.date().required("A szerződéskötés dátumát meg kell adni!"),
    serviceCompany: yup.string().required("A szolgáltatót meg kell adni!"),
    mainEmail: yup.string().required("Az e-mail címet meg kell adni!").email("Nem megfelelő e-mail-cím!"),

    postalCode: yup.string().required("Az irányítószámot meg kell adni!"),
    city: yup.string().required("A település nevét meg kell adni!"),
    street: yup.string().required("Az utcát, házszámot meg kell adni!"),
})

function onSubmit(values) {
    store_page.setDateOfContract(values.dateOfContract)
    store_page.setServiceCompany(values.serviceCompany)

    store_opportunity.setCustomerMainEmail(values.mainEmail)

    store_page.setActiveStep(3)

}

function toPrev(){
    store_page.setActiveStep(1)
}


</script>

<style scoped>

</style>
