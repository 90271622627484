<template>
  <vee-form class="ui form"
            @submit="onSubmit"
            :validation-schema="schema"
            :initial-values="initial_values"
            v-slot="{ errors}"
  >

    <div class="ui sixteen width grid">
      <div class="sixteen wide column">


        <div class="field" :class="{error: 'unitPrice' in errors}">
          <label for="unitPrice">Egységár</label>
          <vee-field type="number" placeholder="Egységára" name="unitPrice"/>
          <error-message name="unitPrice" class="error-message"/>
        </div>

      </div>

      <div class="six wide column">


        <div class="ui buttons">
          <button type="submit"
                  class="ui positive mini button">Mentés
          </button>
          <div class="or" data-text="?"></div>
          <button type="button"
                  @click="cancel"
                  class="ui negative mini  button">Mégsem
          </button>
        </div>
      </div>
    </div>

  </vee-form>


</template>

<script setup>
import {ErrorMessage, Field as VeeField, Form as VeeForm} from 'vee-validate'
import * as yup from "yup";
import {useApiQuotationStore} from "@/store-pinia/api/quotations";
import {useUpdateQuotationPageStore} from "@/store-pinia/pages/updateQuotationPage";

const store_page = useUpdateQuotationPageStore()
const store_api = useApiQuotationStore();


const initial_values = {
  unitPrice: store_page.focusPriceListElement.unitPrice
}


const schema = yup.object({

  unitPrice: yup.number('Ez nem megfelelő érték').min(0, 'Ugye nem mi fizetünk az ügyfélnek?').required('Megadása kötelező'),
})


function onSubmit(values) {

  const send_data = {
    unitPrice: parseInt(values.unitPrice),

  }

  store_api.editPriceListElement(store_page.focusPriceListElement.id, send_data);
  store_page.setShowModalEditPriceList(false);
}

function cancel() {
  store_page.setShowModalEditPriceList(false);
}

</script>

<style scoped>
.error-message {
  color: red
}
</style>