<template>
    <div class="ui container segment products">
        <h4 class="ui header">Cégünk termékei</h4>

        <div class="ui five cards">
            <div class="card" v-for="product in store_products.products" :key="product.id"
                 @click="selectProduct(product)">
                <div class="center aligned product">
                    {{ product.name }}
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>

import {usePageCreateContractStore} from "@/store-pinia/pages/pageCreateContract";
import {useProductsStore} from "@/store-pinia/api/products";


const store_page = usePageCreateContractStore()
const store_products = useProductsStore()

function selectProduct(product) {
    store_page.setProduct(product)
    store_page.setQuotation(null);
    store_page.setActiveStep(2)
}


</script>

<style scoped>
.product {
    cursor: pointer;
}

.products {
    background-color: blanchedalmond;
}


</style>
