import {defineStore} from "pinia";
import setupAuthHeaderFor from "@/_helper/setupAxiosHeader";
import axios from "axios";
import {useErrorsStore} from "@/store-pinia/errors";
import {useOroStore} from "@/store-pinia/oro";


export const useApiPersonsStore = defineStore({
    id: 'apiPersons',
    state: () => {
        return {
            person: null,
            people: []
        };
    },
    actions: {

        setPerson(person) {
            this.person = person
        },
        setPeople(people) {
            this.people = people
        },
        addPerson(person) {
            this.people.push(person)
        },


        async createContactPerson(data) {
            const store_oro = useOroStore();
            const store_errors = useErrorsStore();

            const axiosConfig = setupAuthHeaderFor('wsse');

            const url_contactPersons = `${store_oro.host}/api/contacts`;

            const send_data = {
                firstName: data.firstName,
                lastName: data.lastName,
                primaryEmail: data.primaryEmail,
                primaryPhone: data.primaryPhone,
                accounts: data.accounts
            };

            try {
                const response = await axios.post(url_contactPersons, send_data, axiosConfig);
                this.setPerson(response.data);
                return response.data;
            } catch (err) {
                const error = {
                    code: err.response?.status || 500,
                    message: err.message,
                    message_public: 'A személy létrehozása sikertelen!'
                };
                store_errors.addError(error);
                throw error;
            }
        },

        async updateContactPerson(personId, updatedData) {
            const store_oro = useOroStore();
            const store_errors = useErrorsStore();

            const axiosConfig = setupAuthHeaderFor('wsse');

            const url_contactPerson = `${store_oro.host}/api/contacts/${personId}`;

            try {
                const response = await axios.patch(url_contactPerson, updatedData, axiosConfig);
                this.setPerson(response.data);
                return response.data;
            } catch (err) {
                const error = {
                    code: err.response?.status || 500,
                    message: err.message,
                    message_public: 'A személy adatainak frissítése sikertelen!'
                };
                store_errors.addError(error);
                throw error;
            }
        }

    },
    getters: {},
});






