import axios from "axios";
import setupAuthHeaderFor from "@/_helper/setupAxiosHeader";

const state = () => ({})

const getters = {}

const mutations = {}

const actions = {

    async fetchCalculation({rootState}, data) {
        const axiosConfig = setupAuthHeaderFor('wsse');
        const url_price_calculation = rootState.app.hostServer + '/api/price-calculation'
        return await axios.post(url_price_calculation, data, axiosConfig)
            .then((response) => {
                return response.data
            })
    },

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
