<template>
    <div class="card pointer">
        <div class="content">
            <div class="header">
                <slot name="role">Munkakör</slot>
            </div>

            <div v-if="props.defined">
                <h4>
                    <slot name="personName"></slot>
                </h4>
                <p>
                    <slot name="email"></slot>
                </p>
                <p>
                    <slot name="phone"></slot>
                </p>
            </div>

            <div v-else class="ui negative message">
                <div class="header">Nincs megadva</div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {defineProps} from "vue";

const props = defineProps(['role', 'defined'])

</script>

<style scoped>
.pointer {
    cursor: pointer;
}

</style>
