<template>

  <vee-form class="ui form"
            @submit="onSubmit"
            :validation-schema="schema"
            :initial-values="initialValues"
            v-slot="{ errors, values}">

    <FieldArray name="need_items" v-slot="{ fields, push, remove }">
      <div v-for="(fields_row, idx) in fields" :key="fields_row.key">
        <detailing-form-row :remove="remove" :idx="idx" :errors="errors" @submit="onSubmit(values)" :values="values"/>
      </div>

      <div class="need_actions">
        <button
            class="ui basic green icon mini button"
            type="button" @click="push({ id: '', quantity: 1} )">
          <i class="plus green circle large icon"/>Új tétel
        </button>

        <button
            class="ui basic orange icon mini button"
            type="button"
            @click="onSubmit(values)">
          <i class="calculator orange large icon"/>Újraszámol
        </button>


      </div>


    </FieldArray>

    <div style="display:none">
      <pre>{{ errors }}</pre>
    </div>


  </vee-form>

</template>

<script setup>
import {FieldArray, Form as VeeForm} from "vee-validate";
import {useCostDetailingStore} from "@/store-pinia/costsDetailing";
import DetailingFormRow from "@/components/contract/update/modals/costs-detailing/detailing-form-row.vue";
import * as yup from "yup";


const store = useCostDetailingStore();


const schema = yup.object({
  need_items: yup.array().of(
      yup.object().shape(
          {
            id: yup.string().required('Megadása kötelező!'),
            quantity: yup.string().required('Megadása kötelező')
          })
  ),
})

const initialValues = {
  need_items: [
    {
      id: '',
      quantity: 1,
    }
  ],
}

function onSubmit(values) {
  store.calculate(values)
}


</script>


<style scoped>

.need_actions {
  padding-top: 1rem;
}

</style>