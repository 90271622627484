import { defineStore } from "pinia";

export const useErrorsStore = defineStore({
    id: 'errors',
    state: () => ({
        errors: []
    }),

    actions: {
        addError(error) {
            this.errors = [...this.errors, error];
            console.log(error);
            setTimeout(() => {
                this.clearErrors()
            }, 3000);
        },
        clearErrors() {
            this.errors = [];
        },
    },
});