<template>
    <div class="ui sixteen width grid site-info">

        <div class="six wide column">
            {{ props.siteAtPeriod.siteInfo.name }} {{ props.siteAtPeriod.siteInfo.address }}
        </div>
        <div class="two wide column">
            {{ siteStatusName(props.siteAtPeriod.siteInfo.status) }}
        </div>
        <div class="four wide column">
            {{ props.siteAtPeriod.siteInfo.activateDate }} -
            <template v-if="!props.siteAtPeriod.siteInfo.terminateDate">
                től
            </template>
            <template v-else>
                {{ props.siteAtPeriod.siteInfo.terminateDate }}
            </template>
        </div>
        <div class="four wide right aligned column">
            {{ numberFormat(props.siteAtPeriod.finalPrice, '0', 0) }} Ft
        </div>
    </div>

</template>


<script setup>
import {defineProps} from "vue";
import {siteStatusName} from "@/_helper/siteStatusName";
import {numberFormat} from "@/_helper/numberFormat";

const props = defineProps(['siteAtPeriod'])


</script>


<style scoped>
.site-info {
    font-size: larger;
    font-style: italic;
    color: red;
    margin-top: 1rem;
    background-color: #a8eaea;
}

</style>