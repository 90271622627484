<template>
  <div class="content"
       :class="{
        active: index === this.$store.state.updateContract.activeAccordionIndex,
        active_accordion: index === this.$store.state.updateContract.activeAccordionIndex}"
       @click="toggleActive(index)">

    <div class="transition visible" style="display: block !important;">

      <column-costs-list :costs="site.costs" :site="site"/>

    </div>
  </div>
</template>


<script>
import SiteContentColumnSiteCostsList from "@/components/contract/update/site-content-column-site-costs-list.vue";


export default {
  name: "site-content",
  props: ['site', 'index'],
  components: {
    ColumnCostsList: SiteContentColumnSiteCostsList
  },
  methods: {
    toggleActive(index) {
      if (this.$store.state.updateContract.activeAccordionIndex === index) {
        this.$store.commit('updateContract/setActiveAccordionIndex', -1)
      } else {
        this.$store.commit('updateContract/setActiveAccordionIndex', index)
      }
    },

  },

}
</script>
