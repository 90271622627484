<template>
    <div class="ui celled list mr-1">
        <div class="item">
            <div class="ui sixteen wide grid table-header">
                <div class="four wide column">Név</div>
                <div class="one wide right aligned  column">Menny.</div>
                <div class="two wide right aligned column">Havidíj</div>
                <div class="three wide column">Időszak</div>
                <div class="two wide right aligned column">Info</div>
                <div class="two wide left aligned column"></div>
                <div class="two wide right aligned column">Összesen</div>
            </div>
        </div>
        <template v-for="costSegment in displayedCostSegments()" :key="costSegment">
            <cost-segment-row :cost-segment="costSegment"/>
        </template>
    </div>

    <costs-summary  :siteAtPeriod="props.siteAtPeriod"   class="mr-1"/>


</template>

<script setup>

import {defineProps} from "vue";
const props = defineProps(['siteAtPeriod'])



import CostsSummary from "@/components/contract/update/modals/contract-sites-at-period/costs-summary.vue";
import CostSegmentRow from "@/components/contract/update/modals/site-costs-at-period/cost-segment-row.vue";
import {useContractStore} from "@/store-pinia/api/contract";


const contract_store  = useContractStore();


function displayedCostSegments() {
    return contract_store.sitesAtPeriod.showMergedCostSegments
        ? props.siteAtPeriod.mergedCostSegments
        : props.siteAtPeriod.costSegments
}

</script>


<style scoped>
.mr-1 {
    margin-right: 1rem;
}

.table-header {
    font-weight: bold;
    font-style: italic;
}

</style>