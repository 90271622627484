<template>
    <div class="ui placeholder segment">
        <h5>Választás a meglévő személyek közül</h5>
        <div class="ui three cards">
            <div v-for="person in vuex_store.state.updateContract.contract.customer.persons"
                 class="card pointer"
                 :class="{
                       active: person.id === vuex_store.state.updateContract.contract[role].id,
                       stagePerson: stagePerson && person.id === stagePerson.id
                       }"
                 :key="person.id"
                 @click="selectForRole(person)">
                <div class="content">
                    <div class="header">
                        {{ person.name }}
                    </div>
                    <div class="description">
                        <div class="ui divided right aligned list">
                            <div v-for="email in person.emails" :key="email">
                                {{ email }}
                            </div>
                            <div v-for="phone in person.phones" :key="phone">
                                {{ phone }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="actions" v-if="stagePerson && stagePerson.id !== vuex_store.state.updateContract.contract[role].id">
            <button class="ui primary button"
                    @click="changeRole">Csere
            </button>
        </div>
    </div>
</template>


<script setup>
import {useStore as useVuexStore} from "vuex";
import {ref} from "vue";

const vuex_store = useVuexStore();
const role = vuex_store.state.updateContract.editedPersonRole

const stagePerson = ref(null);

function selectForRole(person) {
    stagePerson.value = person;
}

async function changeRole() {

    try {
        const contract = {
            id: vuex_store.state.updateContract.contract.id,
            [role]: stagePerson.value.id
        }
        await vuex_store.dispatch('updateContract/updateContract', contract);
        await vuex_store.dispatch('updateContract/fetchContract', contract.id);

    } catch
        (error) {
        console.error("Hiba történt a személy szerepkörének beállításakor", error);
    } finally {
        vuex_store.commit('updateContract/setShowEditPersonModal', false);
    }

}


</script>


<style scoped>
.actions {
    margin-top: 1rem;
}

.segment {
    margin-right: 2rem;
}

.active {
    background-image: linear-gradient(
        to right,
        rgb(210, 234, 220),
        rgb(207, 243, 196)
    ) !important;
}

.stagePerson {
    background-image: linear-gradient(
        to right,
        rgb(241, 227, 160),
        rgb(241, 227, 160)
    ) !important;
}


.pointer {
    cursor: pointer;
}

</style>