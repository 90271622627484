<template>
    <Teleport to="body">
        <modal :show="show()" :size="'modal-medium'"
               @close="onCloseModal">
            <template #header>Szerződés beállítások - számlázás</template>
            <template #body>
                    <conditions-form-b/>
            </template>
        </modal>
    </Teleport>
</template>


<script setup>
import Modal from "@/components/slots/modal.vue"
import {useStore as useVuexStore} from "vuex";
import ConditionsFormB from "@/components/contract/update/modals/contract-conditions-b/conditions-form-b.vue";

const vuex_store = useVuexStore()

function show() {
    return vuex_store.state.updateContract.showEditConditionsBModal;
}

function onCloseModal() {
    vuex_store.commit('updateContract/setShowEditConditionsBModal', false)
}



</script>


