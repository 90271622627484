<template>

    <div class="actions">
        <button class="ui black deny button " @click="$emit('close')">
            Mégsem
        </button>

        <button type="button"
                :disabled="started_process"
                @click="saveNewCosts()"
                class="ui positive right labeled icon button">
            Mentés
            <i class="checkmark icon"></i>
        </button>

    </div>


</template>

<script setup>
import {useCostsStore} from "@/store-pinia/api/costs";

const store = useCostsStore()

let started_process = false;

function saveNewCosts() {
    started_process = true;
    store.callCreateNewCosts()
}


</script>

<style scoped>

</style>