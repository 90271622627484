import {createApp} from 'vue'
import {createPinia} from 'pinia';

import App from './App.vue'

import axios from 'axios';
import VueAxios from 'vue-axios';

import VeeValidatePlugin from '@/_helper/validation'

import router from './router';
import store from './store';



/////////////////////////////////////////////////////////////////////////
const pinia = createPinia();

const app = createApp(App);

app.use(pinia);

app.use(router);
app.use(store);


app.use(VueAxios, axios);
app.provide('axios', app.config.globalProperties.axios)

app.use(VeeValidatePlugin)






app.mount('#app');
