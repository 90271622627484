<template>

    <Teleport to="body">

        <modal :show="show()" :size="'modal-medium'"
               @close="onCloseModal">
            <template #header>Utoljára kiállított számla dátuma</template>
            <template #body>

                <div class="ui icon warning message">
                    <i class="exclamation triangle icon"></i>
                    <div class="content">
                        <div class="header">
                            Ez az információ jelenleg nem dolgozódik fel és nem valós.
                        </div>
                        <p>Kizárólag tesztelési célból!</p>
                    </div>
                </div>

                <div class="ui equal width grid">
                    <div class="column">
                        <site-setting-date-of-last-issued-invoice-form/>
                    </div>
                </div>
            </template>
        </modal>

    </Teleport>


</template>

<script setup>
import Modal from "@/components/slots/modal.vue"
import {useStore as useVuexStore} from "vuex";

import SiteSettingDateOfLastIssuedInvoiceForm
    from "@/components/contract/update/modals/site-date-of-last-issued-invoice/site-setting-dateOfLastIssuedInvoice-form.vue";

const vuex_store = useVuexStore()


function show() {
    return vuex_store.state.updateContract.showSiteSettingDateOfLastIssuedInvoiceModal;
}

function onCloseModal() {
    vuex_store.commit('updateContract/setShowSiteSettingDateOfLastIssuedInvoiceModal', false)
}


</script>


<style scoped>

.need_actions {
    padding-top: 1rem;
}

</style>

