import {defineStore} from "pinia";
import setupAuthHeaderFor from "@/_helper/setupAxiosHeader";


import axios from "axios";
import {useOroStore} from "@/store-pinia/oro";


export const useChkUserStore = defineStore({
    id: 'chkUser',
    state: () => {
        return {
            users: [],
        };
    },
    actions: {


        setUsers(users_arr) {
            this.users = users_arr
        },


        async fetchChkUsers() {
            try {
                const store_oro = useOroStore();

                const axiosConfig = setupAuthHeaderFor('jwt');
                const url = `${store_oro.admin3.host}/api/users`;

                const response = await axios.get(url, axiosConfig);
                this.setUsers(response.data)
            } catch (error) {
                console.error("Hiba történt a felhasználók lekérdezése közben.", error);
            }
        },


    },
    getters: {},
});

