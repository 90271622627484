<template>
  <div>
    <div class="ui grid ">
      <div class="fifteen wide column">
        <identity-segment :item="item" :errors="errors"/>
      </div>
    </div>

    <div class="ui segment">
      <div class="ui grid">
        <div class="eight wide column">
          <needs-segment :item="item"  :errors="errors"/>
        </div>
        <div class="eight wide column">
          <prices-segment :item="item" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import IdentitySegment from "@/components/contract/update/item-edited/identitySegment";
import NeedsSegment from "@/components/contract/update/item-edited/needsSegment";
import PricesSegment from "@/components/contract/update/item-edited/pricesSegment";

export default {
  name: "ItemWidgetComponent",
  components: {NeedsSegment, IdentitySegment, PricesSegment},
  props: ['item', 'remove', 'errors']

}
</script>

<style scoped>


</style>
