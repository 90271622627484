<template>
    <vee-form class="ui form"
              @submit="onSubmit"
              :validation-schema="schema"
              :initial-values="initialValues"
              v-slot="{ errors, values}">
        <FieldArray name="need_items" v-slot="{ fields, push, remove }">

            <div class="ui sixteen width middle aligned grid">
                <div class="ten wide column">
                    <div class="ui message">
                        <div class="ui sixteen  width grid">
                            <div class="twelve wide column">
                                <h3 class="ui header">Árajánlatkészítés {{ store_page.product.name }} termékre</h3>
                            </div>
                            <div class="four wide right aligned column">
                                <button
                                    class="ui basic green icon mini button"
                                    type="button" @click="push({ priceListItem: '', quantity: 1} )">
                                    <i class="plus green circle large icon"/>Új tétel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="six wide column">
                    <div v-if="loaded && store_page.priceList.source">
                        <div v-if="store_page.priceList.source === 'mother'" class="ui message">
                            Még nem rendelkezik <strong> {{ store_page.product.name }}</strong> szerződéssel.
                        </div>
                        <div v-else class="ui positive message">
                            Már van <strong>{{ store_page.product.name }}</strong> szerződése.
                            <p>
                                Szerződésszám: {{ store_page.contract && store_page.contract.registrationNumber }}
                            </p>
                        </div>
                    </div>
                    <div v-else>
                        Betöltés...
                    </div>
                </div>

            </div>


            <div class="ui icon yellow message" v-if="store_page.contract">
                <i class="exclamation triangle icon"></i>
                <div class="content">
                    <div class="header">
                        Az elkészült  árajánlat  módosítása nem lesz lehetséges, mivel már van szerződése!
                    </div>
                    <p>Ha más árra van szükséged, mint ami a listában elérhető, akkor azt a szerződés egyedi árlistájába
                        kell felvenni. (Annak, akinek van rá jogosultsága.</p>
                    <p>Vagyis ilyen esetben <strong>NE MENTS EL</strong>, mert fölösleges!</p>
                </div>
            </div>


            <div class="ui message">

                <div v-for="(fields_row, idx) in fields" :key="fields_row.key">
                    <add-price-list-item-to-calculation :remove="remove" :idx="idx" :errors="errors"
                                                        @submit="onSubmit(values)"
                                                        :values="values"/>
                </div>
            </div>

            <calculation-preview/>
            <step-b-actions/>
        </FieldArray>
    </vee-form>


    <div class="ui message" v-if="false">

  <pre>
    {{ store_calculation.needs }}
  </pre>
    </div>

</template>

<script setup>

import {useCreateQuotationPageStore} from "@/store-pinia/pages/createQuotationPage";
import {useCalculationStore} from "@/store-pinia/calculation";
import CalculationPreview from "@/components/calculation/calculation-preview.vue";
import StepBActions from "@/components/quotationCreate/step-b-actions.vue";
import {FieldArray, Form as VeeForm} from "vee-validate";
import * as yup from "yup";

import AddPriceListItemToCalculation from "@/components/calculation/add-price-list-item-to-calculation.vue"
import {onMounted, ref} from "vue";


const store_page = useCreateQuotationPageStore();

const store_calculation = useCalculationStore();


const loaded = ref(false);


onMounted(() => {

    store_page.fetchContractByCustomerAndProduct(store_page.customer.id, store_page.product.id)

    loaded.value = true
})


const schema = yup.object({
    need_items: yup.array().of(
        yup.object().shape(
            {
                priceListItem: yup.string().required('Megadása kötelező!'),
                quantity: yup.number().required('Megadása kötelező')
            })
    ),
})

const initialValues = {
    need_items: [
        {
            priceListItem: '',
            quantity: 1,
        }
    ],
}


function onSubmit(values) {
    store_page.setNeeds(values.need_items);
    store_calculation.setNeeds(values.need_items);
    store_calculation.calculate();
}

</script>

