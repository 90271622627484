<template>

    <div id="main" class="ui container page">
        <div class="ui grid">
            <div class="four wide column">
                <h2 class="ui header">Szerződés szerkesztése</h2>
            </div>

            <div class="four wide column">
                <div class="ui info mini message">
                    <div class="header">
                        <i class="question circle outline icon"></i>
                        Be vagy jelentkezve az admin-3-ba? Néhány esetben szükséged lehet rá!
                    </div>
                </div>
            </div>


            <div class="eight wide right aligned column">
                <button class="ui basic red  mini button"
                        @click="navigateToRecontract(contract.id)">
                    <i class="angle double right icon"></i>
                    Átszerződés másik cégtől ide
                </button>

                <button class="ui basic green  mini button" @click="refreshContract(contract)">
                    <i class="sync alternate icon"></i>
                    Frissítés
                </button>
                <button class="ui teal mini button" @click="returnToCRM">Szerződések listájához</button>
            </div>
        </div>


        <notification-messages/>
        <error-messages/>

        <div class="ui segment contract-block">
            <contract-info/>
            <contract-conditions />
        </div>


        <div class="ui segment sites" style="margin-bottom: 50px">

            <div class="ui sixteen wide grid">
                <div class="five wide column">
                    <h3 class="ui header">Telephelyek</h3>
                </div>


                <div class="five wide column">
                    <button @click.stop="toggleHideTerminatedSites"
                            class="ui teal basic icon fluid button">
                        <template v-if="contract && contract.hideTerminatedSites">
                            <i class="expand teal icon"/>
                            A megszűnt telephelyek elrejtve vannak
                        </template>
                        <template v-else>
                            <i class="check squared teal icon"/>
                            Minden telephely megjelenik
                        </template>
                    </button>
                </div>


                <div class="six wide right aligned column">
                    <button class="ui basic green button"
                            @click="showAddNewSiteModal(true)">
                        <i class="plus green icon"></i>
                        Új telephely hozzáadása
                    </button>
                </div>
            </div>


            <div class="ui styled accordion" style="width: 100%; margin-top: 1rem">
                <div v-for="(site, index) in sites" :key="site.id">
                    <template v-if="!contract.hideTerminatedSites || site.status !== 'terminated'">
                        <site-title :site="site" :index="index" @openEditSiteModal="showEditSiteModal = true"/>
                        <site-content :site="site" :index="index"/>
                    </template>
                </div>
            </div>


        </div>

        <modal-edit-site :show="showEditSiteModal" @onCloseClick="showEditSiteModal=false"/>



        <modal-edit-conditions-a />
        <modal-edit-conditions-b/>


        <modal-edit-customer/>

        <modal-edit-person/>


        <modal-add-site-cost/>


        <modal-site-terminate-request/>
        <modal-cost-terminate-request/>
        <modal-price-list/>
        <modal-costs-detailing/>
        <modal-create-new-site/>

        <modal-contract-sites-at-period/>
        <modal-site-costs-in-period/>



        <modal-cost-deep-testing/>

        <modal-site-setting-commissions/>
        <modal-site-setting-discounts/>
        <modal-site-setting-date-of-last-issued-invoice/>

    </div>

</template>

<script>


import * as yup from "yup";

import ContractConditions from "@/components/contract/update/contract-conditions.vue";
import ContractInfo from "@/components/contract/update/contractInfo";
import {mapState} from "vuex";


import SiteTitle from "@/components/contract/update/site-title.vue";
import SiteContent from "@/components/contract/update/site-content.vue";


import ModalEditSite from "@/components/contract/update/modals/modal-edit-site.vue";
import ModalSiteSettingCommissions from "@/components/contract/update/modals/site-setting-commissions/modal-site-setting-commissions.vue";
import ModalSiteSettingDateOfLastIssuedInvoice
    from "@/components/contract/update/modals/site-date-of-last-issued-invoice/modal-site-setting-date-of-last-issued-invoice.vue";
import ModalSiteSettingDiscounts from "@/components/contract/update/modals/site-setting-discounts/modal-site-setting-discounts.vue";


import ModalEditConditionsA
    from "@/components/contract/update/modals/contract-conditions-a/modal-contract-conditions-a.vue";
import ModalEditConditionsB
    from "@/components/contract/update/modals/contract-conditions-b/modal-contract-conditions-b.vue";


import ModalAddSiteCost from "@/components/contract/update/modals/modal-add-site-cost.vue";
import ModalSiteTerminateRequest from "@/components/contract/update/modals/modal-site-terminate-request.vue";
import ModalCostTerminateRequest from "@/components/contract/update/modals/modal-cost-terminate-request.vue";
import ModalPriceList from "@/components/contract/update/modals/modal-priceList.vue";
import ModalCostsDetailing from "@/components/contract/update/modals/modal-costs-detailing.vue";
import ModalCreateNewSite from "@/components/contract/update/modals/modal-create-new-site.vue";

import ModalEditCustomer from "@/components/contract/update/modals/modal-edit-customer.vue";
import ModalEditPerson from "@/components/contract/update/modals/modal-contact-persons.vue";

import ModalCostDeepTesting from "@/components/contract/update/modals/cost-deep-testing/modal-cost-deep-testing.vue";

import ModalSiteCostsInPeriod from "@/components/contract/update/modals/site-costs-at-period/modal-site-costs-in-period.vue";
import ModalContractSitesAtPeriod
    from "@/components/contract/update/modals/contract-sites-at-period/modal-contract-sites-at-period.vue";


import {useErrorsStore} from "@/store-pinia/errors";
import {onMounted} from "vue";
import {useRoute} from "vue-router";

import ErrorMessages from "@/components/ErrorComponent.vue";
import NotificationMessages from "@/components/NotificationsComponent.vue";
import {useContractPriceListStore} from "@/store-pinia/contractPriceList";


export default {
    name: "ContractUpdatePage",

    setup() {

        const store_errors = useErrorsStore();
        const store_priceList = useContractPriceListStore();
        onMounted(() => {

            const route = useRoute();
            store_priceList.fetchContractPriceList(route.params.contract_id);
        })
        return {
            store_errors
        }
    },


    data() {
        return {
            showEditSiteModal: false,
            showEditConditionsModal: false,
            schema: yup.object().shape(
                {
                    name: yup.string().required('A telepítés helyét el kell nevezni!'),
                    city: yup.string().required('A település nevét meg kell adni!'),
                    street: yup.string().required('Megadása kötelező!'),
                    costs: yup.array().of(
                        yup.object().shape({
                                priceListItem: yup.string().required('Meg kell adni!'),
                                quantity: yup.number('Csak szám lehet!').required('A mennyiséget meg kell adni!')
                            }
                        )
                    )
                }
            ),
            initialValues: {
                name: '',
                city: '',
                street: '',
                costs: [
                    {
                        priceListItem: '',
                        quantity: 1
                    }
                ]
            },
        }

    },
    components: {
        ModalEditConditionsA,
        ModalEditConditionsB,
        ModalSiteCostsInPeriod,
        ModalContractSitesAtPeriod,
        ModalEditPerson,
        ModalEditCustomer,

        ModalCostsDetailing,
        ModalPriceList,
        ContractInfo,
        SiteContent,
        SiteTitle,
        ContractConditions,
        ModalEditSite,
        ModalSiteSettingCommissions,
        ModalSiteSettingDateOfLastIssuedInvoice,
        ModalSiteSettingDiscounts,
        ModalAddSiteCost,
        ModalSiteTerminateRequest,
        ModalCostTerminateRequest,
        ModalCreateNewSite,
        ErrorMessages,
        NotificationMessages,
        ModalCostDeepTesting

    },
    computed: {
        ...mapState({
            contract: state => state.updateContract.contract,
            sites: state => state.updateContract.contract ? state.updateContract.contract.sites : [],
            transitionSites: state => state.updateContract.transitionSites,
            preferredProductTypeId: state => state.app.preferredProductTypeId,
            x: state => state.updateContract.contract ? state.updateContract.contract.hideTerminatedSites : true,
        })

    },
    methods: {

        onCreateNewContract() {
            this.$store.dispatch('updateContract/makeNewContractType')
        },

        returnToCRM() {
            window.location.href = this.$store.state.app.navigate.return
        },
        navigateToRecontract(contract_id) {
            window.location.href = this.$store.state.app.serviceAdminServer + '/site/re-contract-site-here/' + contract_id;
        },
        refreshContract(contract) {
            this.$store.dispatch('updateContract/fetchContract', contract.id)
        },

        onSendApproveRequest() {
            this.$router.push('/approve-request')
        },
        showAddNewSiteModal() {
            this.$store.commit('updateContract/setShowNewSiteModal', true)
        },


        toggleHideTerminatedSites() {
            this.$store.commit('updateContract/toggleHideTerminatedSites')
        }

    },

    created() {
        this.$store.dispatch('updateContract/fetchContract', this.$route.params.contract_id)
    }

}
</script>


<style>
.page {
    margin-top: 1rem;

}

.under-construction {
    border-bottom: 4px solid #cb66f3;
    padding-bottom: 5px;
}

.pointer {
    cursor: pointer;
}

</style>
