<template>
    <vee-form class="ui form "
              @submit="onCustomerSubmit"
              :validation-schema="schema"
              :initial-values="initialValues"
              v-slot="{errors}">

        <div class=" ui segment form-segment">
            <div class="two fields">
                <div class="field" :class="{error: `name` in errors}">
                    <label>Cégnév</label>
                    <div class="ui input">
                        <vee-field type="text" name="name"/>
                    </div>
                    <error-message name="name" class="error-message"/>
                </div>
                <div class="field" :class="{error: 'updateCustomerFromOfficialSource' in errors}">
                    <label>Frissítés (Opten/Bisnode)/</label>
                    <div class="ui input">
                        <vee-field as="select" class="ui fluid dropdown"
                                   name="updateCustomerFromOfficialSource">
                            <option value="true">Frissítve lesz</option>
                            <option value="false">Nincs frissítés</option>

                        </vee-field>
                    </div>
                    <error-message name="updateCustomerFromOfficialSource" class="error-message"/>
                </div>
            </div>

            <div class="three fields">
                <div class="field" :class="{error: 'taxNumber' in errors}">
                    <label>Adószám</label>
                    <div class="ui input">
                        <vee-field type="text" name="taxNumber"/>
                    </div>
                    <error-message name="taxNumber" class="error-message"/>
                </div>


                <div class="field" :class="{error: 'registrationNumber' in errors}">
                    <label>Cégjegyzékszám:</label>
                    <div class="ui input">
                        <vee-field type="text" name="registrationNumber"/>
                    </div>
                    <error-message name="registrationNumber" class="error-message"/>
                </div>


                <div class="field" :class="{error: 'mainEmail' in errors}">
                    <label>A cég hivatalos e-mail címe:</label>
                    <div class="ui input">
                        <vee-field type="text" name="mainEmail"/>
                    </div>
                    <error-message name="mainEmail" class="error-message"/>
                </div>
            </div>
        </div>


        <div class="ui equal width grid">
            <div class="column">
                <div class="ui segment form-segment">
                    <h4 class="ui header">Székhely</h4>

                    <div class="two fields">
                        <div class="field" :class="{error: 'mainAddress.postalCode' in errors}">
                            <label>Irányítószám:</label>
                            <div class="ui input">
                                <vee-field type="text" name="mainAddress.postalCode"/>
                            </div>
                            <error-message name="mainAddress.postalCode" class="error-message"/>
                        </div>

                        <div class="field" :class="{error: 'mainAddress.city' in errors}">
                            <label>Település:</label>
                            <div class="ui input">
                                <vee-field type="text" name="mainAddress.city"/>
                            </div>
                            <error-message name="mainAddress.city" class="error-message"/>
                        </div>
                    </div>

                    <div class="field" :class="{error: 'mainAddress.street' in errors}">
                        <label>Utca, házszám:</label>
                        <div class="ui input">
                            <vee-field type="text" name="mainAddress.street"/>
                        </div>
                        <error-message name="mainAddress.street" class="error-message"/>
                    </div>
                </div>
            </div>
            <div class="column">
                <div class="ui segment form-segment">
                    <h4 class="ui header">Levelezési cím</h4>
                    <div class="two fields">
                        <div class="field" :class="{error: 'postingAddress.postalCode' in errors}">
                            <label>Irányítószám:</label>
                            <div class="ui input">
                                <vee-field type="text" name="postingAddress.postalCode"/>
                            </div>
                            <error-message name="postingAddress.postalCode" class="error-message"/>
                        </div>
                        <div class="field" :class="{error: 'postingAddress.city' in errors}">
                            <label>Település:</label>
                            <div class="ui input">
                                <vee-field type="text" name="postingAddress.city"/>
                            </div>
                            <error-message name="postingAddress.city" class="error-message"/>
                        </div>
                    </div>

                    <div class="field" :class="{error: 'postingAddress.street' in errors}">
                        <label>Utca, házszám:</label>
                        <div class="ui input">
                            <vee-field type="text" name="postingAddress.street"/>
                        </div>
                        <error-message name="postingAddress.street" class="error-message"/>
                    </div>
                </div>
            </div>
        </div>


        <div class="ui segment">
            <div class="actions">
                <button class="ui black deny button" @click="onCloseClick">Mégsem</button>

                <button type="submit" class="ui positive right labeled icon button">
                    Mentés <i class="checkmark icon"></i>
                </button>
            </div>
        </div>

    </vee-form>
</template>


<script setup>
import {computed, defineEmits} from 'vue';
import {useStore as useVuexStore} from 'vuex';
import {ErrorMessage, Field as VeeField, Form as VeeForm} from 'vee-validate';
import * as yup from 'yup';
import {useCustomerStore} from "@/store-pinia/api/customer";

const vuex_store = useVuexStore();
const emit = defineEmits(['close']);

const customer = computed(() => vuex_store.state.updateContract.contract.customer);

const initialValues = computed(() => ({


    name: customer.value.name,
    updateCustomerFromOfficialSource: customer.value.updateCustomerFromOfficialSource,
    taxNumber: customer.value.taxNumber,
    registrationNumber: customer.value.registrationNumber,
    mainAddress: customer.value.mainAddress,

    postingAddress: customer.value.postingAddress,
    mainEmail: customer.value.mainEmail


}));

const schema = yup.object().shape(
    {
        name: yup.string(),
        updateCustomerFromOfficialSource: yup.boolean(),
        taxNumber: yup.string(),
        registrationNumber: yup.string(),
        mainAddress: yup.object().shape(
            {
                postalCode: yup.string(),
                city: yup.string(),
                street: yup.string()
            }
        ),
        postingAddress: yup.object().shape(
            {
                postalCode: yup.string(),
                city: yup.string(),
                street: yup.string()
            }
        ),
        mainEmail: yup.string().email('Ez nem  jó e-mail cím!').required('Az email cím megadása kötelező'),

    }
);


async function onCustomerSubmit(values) {
    const storeCustomer = useCustomerStore();
    const contractId = vuex_store.state.updateContract.contract.id;

    try {
        await storeCustomer.updateCustomer(vuex_store.state.updateContract.contract.customer.id, values)
        await vuex_store.dispatch('updateContract/fetchContract', contractId);

    } catch (error) {
        console.error("Hiba történt a cég adatainak módosításakor", error);
    } finally {
        vuex_store.commit('updateContract/setShowEditCustomerModal', false)
    }

}


function onCloseClick() {
    vuex_store.commit('updateContract/setShowEditCustomerModal', false)
    emit('close')
}


</script>


<style scoped>
.form {
    margin-right: 2rem;
}


.form-segment {
    background-image: linear-gradient(
        45deg,
        rgb(249, 250, 251),
        rgb(236, 240, 243)
    );
}


.error {
    color: red;
}

</style>