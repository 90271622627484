<template>

    <div class="ui segment persons" style="margin-top: 2rem;">
        <div class="ui four cards" v-if="!store_page.showNewPersonForm">
            <div v-for="person in store_opportunity.account.persons" :key="person.id" class="ui card pointer"
                 @click="select(person)">
                <div class="content">
                    <div class="header">
                        {{ person.name }}
                    </div>

                    <p v-for="(email, email_idx) in person.emails" :key="email_idx">{{ email }}</p>

                </div>
            </div>
        </div>

        <div v-if="!store_page.showNewPersonForm" style="margin-top: 2rem;">
            <button class="ui teal button"
                    type="button"
                    @click="store_page.setShowNewPersonForm(true)"> Új személy felvétele
            </button>

            <button
                    class="ui basic black button"
                    type="button"
                    @click="cancel">Mégsem
            </button>
        </div>


        <div v-else>
            <contact-person-form/>
        </div>
    </div>


</template>

<script setup>
import ContactPersonForm from "@/components/contactPerson/contactPersonForm";
import {usePageCreateContractStore} from "@/store-pinia/pages/pageCreateContract";
import {useOpportunitiesStore} from "@/store-pinia/api/opportunities";

const store_opportunity = useOpportunitiesStore()

const store_page = usePageCreateContractStore()

function select(person) {
    if (store_page.intentSelectPerson === 'delegatePerson') {
        store_opportunity.setDelegatePerson(person)
    } else if (store_page.intentSelectPerson === 'financialPerson') {
        store_opportunity.setFinancialPerson(person)
    } else if (store_page.intentSelectPerson === 'technicalPerson') {
        store_opportunity.setTechnicalPerson(person)
    }

    store_page.setIntentSelectPerson(null);
    store_page.setShowPersonsList(false)
}


function cancel() {
    store_page.setIntentSelectPerson(null);
    store_page.setShowPersonsList(false)
}

</script>

<style scoped>
.pointer {
    cursor: pointer
}

.persons {
    background-color: cornsilk;
}

</style>
