<template>

  <div class="actions">
    <button class="ui black deny button " @click="$emit('close')">
      Mégsem
    </button>

    <button type="button"
            :disabled="!store.isEqualMonthly || !store.isEqualOneTime"
            @click="callDetailing()"
            class="ui positive right labeled icon button">
      Csere
      <i class="checkmark icon"></i>
    </button>

  </div>


</template>

<script setup>
import {useCostDetailingStore} from "@/store-pinia/costsDetailing";


const store = useCostDetailingStore()



function callDetailing(){
  store.callDetailingCost()
}


</script>

<style scoped>

</style>