import {defineStore} from "pinia";
import axios from "axios";
import {useErrorsStore} from "@/store-pinia/errors";
import {useOroStore} from "@/store-pinia/oro";
import setupAuthHeaderFor from "@/_helper/setupAxiosHeader";


export const useOpportunitiesStore = defineStore({
    id: 'opportunity',
    state: () => {
        return {
            opportunity: null,
            opportunities: []
        };
    },
    actions: {

        setOpportunity(opportunity) {
            this.opportunity = opportunity
        },

        setDelegatePerson(person) {
            this.opportunity.account.customer.delegatePerson = person
        },
        setFinancialPerson(person) {
            this.opportunity.account.customer.financialPerson = person
        },
        setTechnicalPerson(person) {
            this.opportunity.account.customer.technicalPerson = person
        },

        setCustomerMainEmail(main_email) {
            this.opportunity.account.customer.mainEmail = main_email
        },

        async fetchOpportunity(opportunity_id) {
            const store_oro = useOroStore();
            const store_errors = useErrorsStore();
            const apiUrl = `${store_oro.host}/api/opportunity-info/${opportunity_id}`;

            const axiosConfig = setupAuthHeaderFor('wsse');

            try {
                const response = await axios.get(apiUrl, axiosConfig);
                this.setOpportunity(response.data);
            } catch (err) {
                const error = {
                    code: err?.response?.status || 500,
                    message: err.message,
                    message_public: 'Az opportunity lekérése sikertelen!'
                };
                store_errors.addError(error);
            }
        }


    },

    getters: {
        customer: (state) => {
            return state.opportunity ? state.opportunity.account.customer : null;
        },
        account: (state) => {
            return state.opportunity ? state.opportunity.account : null;
        },
        persons: (state) => {
            return state.opportunity ? state.opportunity.account.persons : [];
        },
        quotations: (state) => {
            return state.opportunity ? state.opportunity.quotations : [];
        },
        activeQuotations: (state) => {
            if (!state.opportunity || !state.opportunity.quotations) {
                return [];
            }
            return state.opportunity.quotations.filter(quotation => !quotation.isExpired);
        },
        expiredQuotations: (state) => {
            if (!state.opportunity || !state.opportunity.quotations) {
                return [];
            }
            return state.opportunity.quotations.filter(quotation => quotation.isExpired);
        }


    },
});

