<template>
    <div>
        <h3 class="ui header">Új személy felvétele</h3>

        <vee-form class="ui form"
                  @submit="onSubmit"
                  :validation-schema="schema"
                  :initial-values="initialValues"
                  v-slot="{errors}">

            <div class="ui three column grid">
                <div class="column">
                    <div class="field" :class="{error: 'lastName' in errors}">
                        <label>Vezetéknév</label>
                        <div class="ui input">
                            <vee-field name="lastName" placeholder="Vezetéknév" type="text"/>
                        </div>
                        <error-message name="lastName" class="error-message"/>
                    </div>

                    <div class="field" :class="{error: 'firstName' in errors}">
                        <label>Keresztnév</label>
                        <div class="ui input">
                            <vee-field name="firstName" placeholder="Keresztnév" type="text"/>
                        </div>
                        <error-message name="firstName" class="error-message"/>
                    </div>
                </div>

                <div class="column">
                    <h4 class="ui header">E-mail címek</h4>
                    <FieldArray name="emails" v-slot="{ fields, push, remove}">
                        <div v-for="(email, idx_emails) in fields" :key="email.key">
                            <div class="ui grid">
                                <div class="row">
                                    <div class="fourteen wide column">
                                        <div class="field" :class="{error: `emails[${idx_emails}]` in errors}">
                                            <div class="ui input">
                                                <vee-field :fields="fields" :remove="remove"
                                                           :idx="idx_emails"
                                                           :name="`emails[${idx_emails}]`"
                                                           placeholder="E-mail cím"/>
                                            </div>
                                            <error-message :name="`emails[${idx_emails}]`" class="error-message"
                                                           :errors="errors"/>
                                        </div>

                                    </div>
                                    <div class="two wide column">
                                        <i class="trash icon" @click="remove(idx_emails)"/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style="margin-top: 10px">
                            <button type="button" class="ui basic teal button"
                                    @click="push( '' )">Új e-mail cím
                            </button>
                        </div>
                    </FieldArray>
                </div>


                <div class="column">
                    <h4 class="ui header">Telefonszámok</h4>
                    <FieldArray name="phones" v-slot="{ fields, push, remove}">
                        <div v-for="(phone, idx_phones) in fields" :key="phone.key">
                            <div class="ui grid">
                                <div class="row">
                                    <div class="fourteen wide column">
                                        <div class="field" :class="{error: `phones[${idx_phones}]` in errors}">
                                            <div class="ui input">
                                                <vee-field :fields="fields" :remove="remove"
                                                           :idx="idx_phones" :name="`phones[${idx_phones}]`"
                                                           placeholder="Telefonszám"/>
                                            </div>
                                            <error-message :name="`phones[${idx_phones}]`" class="error-message"
                                                           :errors="errors"/>
                                        </div>
                                    </div>
                                    <div class="two wide column">
                                        <i class="trash icon" @click="remove(idx_phones)"/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style="margin-top: 10px">
                            <button type="button" class="ui basic teal button"
                                    @click="push( '' )">Új telefonszám
                            </button>
                        </div>
                    </FieldArray>
                </div>
            </div>


            <div style="margin-top: 1rem">
                <button class="ui teal button"
                        :disabled="sending"><i class="save icon"></i>A személy rögzítése
                </button>
                <button type="button"
                        class="ui black button"
                        :disabled="sending"
                        @click="cancelSubmit">Nem rögzítjük a személyt
                </button>

                <waiting-component v-if="sending"/>

            </div>


        </vee-form>


    </div>
</template>

<script>
import {ErrorMessage, Field, FieldArray, Form} from "vee-validate";
import * as yup from "yup";
import WaitingComponent from "@/components/waitingComponent";
import {useOpportunitiesStore} from "@/store-pinia/api/opportunities";
import {usePageCreateContractStore} from "@/store-pinia/pages/pageCreateContract";


export default {
    name: "contactPersonForm",
    components: {
        VeeForm: Form,
        VeeField: Field,
        FieldArray,
        ErrorMessage,
        WaitingComponent

    },
    data() {
        return {
            sending: false,
            schema: yup.object({
                firstName: yup.string().required("Megadása kötelező"),
                lastName: yup.string().required("Megadása kötelező"),
                emails: yup.array().of(yup.string().required("Megadása kötelező").email("Hibás e-mail formátum")),
                phones: yup.array().of(yup.string().required("Megadása kötelező"))

            }),
            initialValues: {
                firstName: '',
                lastName: '',
                emails: [''],
                phones: ['']
            }
        }
    },

    methods: {
        async onSubmit(values) {
            const store_opportunity = useOpportunitiesStore()
            const store_page = usePageCreateContractStore()

            this.sending = true
            const accounts = [];
            accounts.push(store_opportunity.opportunity.account.id);
            values = {...values, accounts}
            const created_contact_person = await this.$store.dispatch('customer/createContactPerson', values)

            this.$store.commit('createContract/addContactPerson', created_contact_person)
            this.$store.commit('createContract/setShowPersonsList', false)
            this.sending = false

            await store_opportunity.fetchOpportunity(store_opportunity.opportunity.id)

            store_page.setShowNewPersonForm(false)

        },
        cancelSubmit() {
            this.$store.commit('createContract/setShowPersonsList', false)
            this.sending = false
        }
    }
}
</script>

<style scoped>
.error-message {
    color: red;
}
</style>
