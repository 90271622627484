<template>
  <Teleport to="body">

    <modal :show="show" :size="'modal-large'"
           @close="onCloseModal">
      <template #header>
        <div class="ui equal width grid">
          <div class="column">
            Új telephely hozzáadása
          </div>
          <div class="column">
          </div>

        </div>
      </template>

      <template #body>
        <notifications-component/>
        <new-site-form/>
        <new-site-costs/>
      </template>
    </modal>

  </Teleport>
</template>


<script>
import Modal from "@/components/slots/modal.vue";
import {mapState} from "vuex";
import NewSiteCosts from "@/components/contract/update/modals/create-new-site/new-site-calculation-result.vue";
import NewSiteForm from "@/components/contract/update/modals/create-new-site/new-site-form.vue";

import NotificationsComponent from "@/components/NotificationsComponent.vue";


export default {
  name: "modal-create-new-site",

  components: {
    NewSiteForm,

    NewSiteCosts,
    Modal,
    NotificationsComponent
  },
  methods: {
    onCloseModal() {
      this.$store.commit('updateContract/setShowNewSiteModal', false)
    },


  },
  computed: {
    ...mapState({
      show: state => state.updateContract.showNewSiteModal,
    })

  }

}

</script>
