<template>
  <div class="ui icon message" >
    <i class="notched circle loading icon"></i>
    <div class="content">
      <div class="header">
        Egy pillanat türelemet kérek!
      </div>
      <p>Az oroCRM-re várunk... Ez akár hosszúra is nyúlhat...</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "WaitingComponent"
}
</script>

<style scoped>

</style>
