import {defineStore} from "pinia";

import {sumBy} from "lodash-es";

export const useCalculationStore = defineStore({
    id: "calculation",
    state: () => ({
        priceList: [],
        needs: [],
        result: [],
    }),
    actions: {
        setPriceList(price_list) {
            this.priceList = price_list;
        },
        setNeeds(needs) {
            this.needs = needs;
        },
        setResult(result) {
            this.result = result;
        },
        calculate() {
            if (!this.needs.length) {
                this.setResult([]);
                return;
            }

            const getPriceListItemById = (search_id) =>
                this.priceList.find((el) => el.id === search_id);

            const calculatedNewCosts = this.needs.map((need_item) => {
                const foundPriceListItem = getPriceListItemById(need_item.priceListItem);

                return {
                    priceListItem_id: foundPriceListItem?.priceListItem,
                    quantity: need_item.quantity,
                    name: foundPriceListItem?.name,
                    unitPriceOneTime: foundPriceListItem?.unitPriceOneTime,
                    unitPriceMonthly: foundPriceListItem?.unitPriceMonthly,
                    priceOneTime: foundPriceListItem?.unitPriceOneTime * need_item.quantity,
                    priceMonthly: foundPriceListItem?.unitPriceMonthly * need_item.quantity,
                    category: foundPriceListItem?.categoryName,
                };
            });

            this.setResult(calculatedNewCosts);
        },
    },
    getters: {
        availableCosts: ({priceList}) => (product_id) =>
            priceList
                .filter(el =>
                    (el.categoryName === "monthly_fee" || el.categoryName === "install_cost")
                    && el.selectable
                )
                .filter((el) => el.product.id === product_id)
        ,
        serviceCosts: ({priceList}) =>
            priceList.filter(({categoryName}) => categoryName === "service_cost"),
        sumMonthly: ({result}) => sumBy(result, "priceMonthly"),
        sumOneTime: ({result}) => sumBy(result, "priceOneTime"),
    },
});