<template>
    <div v-if="contract" class="ui segment conditions-block pointer">
        <div class="ui sixteen width grid">
            <div class="eight wide column">
                <contract-conditions-a @click="showModalA"/>
            </div>
            <div class="eight wide column">
                <contract-conditions-persons/>
            </div>
            <div class="eight wide column">
                <contract-conditions-b @click="showModalB"/>
            </div>
            <div class="eight wide column">
                <contract-period-for-appendix/>
            </div>
        </div>
    </div>
</template>

<script setup>
import {computed} from 'vue';
import {useStore as useVuexStore} from 'vuex';

import ContractConditionsA from "@/components/contract/update/contract-conditions-a.vue";
import ContractConditionsB from "@/components/contract/update/contract-conditions-b.vue";
import ContractConditionsPersons from "@/components/contract/update/contract-conditions-persons.vue";
import ContractPeriodForAppendix from "@/components/contract/update/contract-period-for-appendix.vue";

const store_vuex = useVuexStore();
const contract = computed(() => store_vuex.state.updateContract.contract);

function showModalA() {
    store_vuex.commit('updateContract/setShowEditConditionsAModal', true)
}

function showModalB() {
    store_vuex.commit('updateContract/setShowEditConditionsBModal', true)
}


</script>


<style scoped>
.conditions-block {
    background-image: linear-gradient(to right, rgb(229, 255, 249), #f0f0f0);
}

.pointer {
    cursor: pointer;
}
</style>
