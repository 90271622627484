<template>

    <costs-block :siteAtPeriod="props.siteAtPeriod"/>

    <discounts-block :siteAtPeriod="props.siteAtPeriod"/>

</template>


<script setup>
import {defineProps} from "vue";
import CostsBlock from "@/components/contract/update/modals/contract-sites-at-period/costs-block.vue";
import DiscountsBlock from "@/components/contract/update/modals/contract-sites-at-period/discounts-block.vue";

const props = defineProps(['siteAtPeriod'])

</script>


<style scoped>

</style>