<template>
    <table class="ui celled table">
        <thead>
        <tr>
            <th>Megnevezés</th>
            <th class="right aligned">Mennyiség</th>
            <th class="right aligned">Egyszeri díj</th>
            <th class="right aligned">Havidíj</th>
        </tr>
        </thead>
        <tbody>

        <tr v-for="(cost, index) in store_calculation.result" :key="index">
            <td data-label="Megnevezés">
                <div class=" ui sixteen width grid">
                    <div class="six wide column">
                        {{ cost.name }}
                    </div>
                    <div class="five wide right aligned column">
                        Egyszeri díj:{{ numberFormat(cost.unitPriceOneTime, '0') }} Ft/db
                    </div>
                    <div class="five wide right aligned column">
                        Havidíj: {{ numberFormat(cost.unitPriceMonthly, '0') }} Ft/hó
                    </div>
                </div>

            </td>
            <td data-label="Megnevezés" class="right aligned ">{{ cost.quantity }} db</td>
            <td data-label="Egyszeri díj" class="right aligned">{{ numberFormat(cost.priceOneTime, '0') }} Ft</td>
            <td data-label="Havidíj" class="right aligned">{{ numberFormat(cost.priceMonthly, '0') }} Ft/hó</td>
        </tr>
        </tbody>
        <tfoot class="full-width">
        <tr>
            <th class="sum_row"></th>
            <th class="sum_row"></th>
            <th class="sum_row right aligned">{{ numberFormat(store_calculation.sumOneTime, '0') }} Ft</th>
            <th class="sum_row right aligned">{{ numberFormat(store_calculation.sumMonthly, '0') }} Ft/hó</th>
        </tr>
        </tfoot>


    </table>
</template>

<script setup>

import {numberFormat} from "@/_helper/numberFormat";

import {useCalculationStore} from "@/store-pinia/calculation";


const store_calculation = useCalculationStore()

</script>

<style scoped>
.error {
    color: red;
    font-size: 1.3rem;
}

</style>