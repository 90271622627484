import {defineStore} from "pinia";
import setupAuthHeaderFor from "@/_helper/setupAxiosHeader";
import axios from "axios";
import {useErrorsStore} from "@/store-pinia/errors";
import {useOroStore} from "@/store-pinia/oro";

import router from "@/router";


const url_contract_info = '/api/contract-info/';


export const useContractStore = defineStore({
    id: 'contract',
    state: () => {
        return {
            sending: false,
            fetching: false,
            contract: null,
            contractId: null,
            sitesAtPeriod: null
        };
    },
    actions: {
        setSending(is_sending) {
            this.sending = is_sending
        },
        setFetching(is_fetching) {
            this.fetching = is_fetching
        },
        setContract(contract) {
            this.contract = contract
        },
        setContractId(contract_id) {
            this.contractId = contract_id
        },
        setSitesAtPeriod(sites_at_period_obj) {
            this.sitesAtPeriod = sites_at_period_obj
        },


        async fetchContract(contract_id) {
            const store_oro = useOroStore();
            const store_errors = useErrorsStore();

            this.setFetching(true)
            const axiosConfig = setupAuthHeaderFor('wsse');
            const url_contract = store_oro.host + url_contract_info + contract_id

            await axios.get(url_contract, axiosConfig)
                .then(resp => {
                    this.setContract(resp.data);
                })
                .catch(err => {
                    const error = {
                        code: err.response.status,
                        message: err.message,
                        message_public: 'A szerződés lekérése sikertelen!'
                    }
                    store_errors.addError(error)
                })

            this.setFetching(false)
        },


        async createSimpleContract(contract_data) {
            const store_oro = useOroStore();
            const store_errors = useErrorsStore();
            const apiUrl = `${store_oro.host}/api/create-simple-contract`;

            this.setFetching(true);

            try {
                const axiosConfig = setupAuthHeaderFor('wsse');
                const response = await axios.post(apiUrl, contract_data, axiosConfig);
                await this.fetchContract(response.data.contract_id);
                await router.push(`/contract/update/${this.contract.id}`);
            } catch (err) {
                const error = {
                    code: err?.response?.status || 500,
                    message: err.message,
                    message_public: 'A szerződés létrehozása sikertelen!'
                };
                store_errors.addError(error);
            } finally {
                this.setFetching(false);
            }
        },

        async fetchSitesAtPeriod(period_data) {
            try {

                this.setSending(true);

                const store_oro = useOroStore();
                const contract_id = this.contract.id;
                const url = `${store_oro.admin3.host}/show-contract-sites-at-period/${contract_id}`;

                const axiosConfig = setupAuthHeaderFor('jwt');
                const response = await axios.post(url, period_data, axiosConfig);

                this.setSitesAtPeriod(response.data);


            } catch (err) {
                const store_errors = useErrorsStore();
                const error = {
                    message: err.message,
                    message_public: 'Az időszakba eső telephelyek lekérdezése sikertelen!'
                };
                store_errors.addError(error);
            } finally {
                this.setSending(false);
            }
        },


        async downloadPDF(contract_id, pdf_type, payload_data) {
            try {

                const store_oro = useOroStore();

                let url = '';
                if (pdf_type === 'reverse') {
                    url = `${store_oro.admin3.host}/pdf-contract-sites-at-period/reverse/${contract_id}`;
                }

                if (pdf_type === 'normal') {
                    url = `${store_oro.admin3.host}/pdf-contract-sites-at-period/normal/${contract_id}`;
                }

                if (pdf_type === 'view') {
                    url = `${store_oro.admin3.host}/pdf-contract-sites-at-period/view/${contract_id}`;
                }

                const axiosConfig = setupAuthHeaderFor('jwt');
                axiosConfig.headers['Content-Type'] = 'application/json';
                axiosConfig.responseType = 'blob';
                const response = await axios.post(url, payload_data, axiosConfig);

                const blob = new Blob([response.data], {type: 'application/pdf'});

                // Blob URL létrehozása és letöltés indítása
                const downloadUrl = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.setAttribute('download', 'downloaded.pdf'); // A letöltendő fájl neve
                document.body.appendChild(link);
                link.click();

                link.remove(); // Link elem eltávolítása a DOM-ból
                window.URL.revokeObjectURL(downloadUrl); // Blob URL felszabadítása


            } catch (err) {
                const store_errors = useErrorsStore();
                const error = {
                    message: err.message,
                    message_public: 'Sikertelen PDF letöltés'
                };
                store_errors.addError(error);
            } finally {
                this.setSending(false);
            }
        },


    },
    getters: {
        totalFinalPrice() {
            if (!this.sitesAtPeriod || !Array.isArray(this.sitesAtPeriod.sites)) {
                return 0;
            }
            return this.sitesAtPeriod.sites.reduce((accumulator, site) => accumulator + (site.finalPrice || 0), 0);
        }


    },
});

