<template>
  <div class="ui segment">
    <div class="ui grid">
      <FieldArray name="needs" v-slot="{ fields, push, remove}">
        <div class="thirteen wide column">
          <h3 class="ui header">Telephely tétel</h3>
        </div>
        <div class="three wide column">
          <i class="calculator orange large icon" @click="calculate(fields)"/>
          <i class="plus green circle large icon" @click="push({ costType: '', quantity: 1} )"/>
        </div>
        <div class="sixteen wide column">
          <div v-for="(need, idx_needs) in fields" :key="need.key">
            <need-widget-component :remove="remove"  :idx_needs="idx_needs"
                                   @calculate="calculate(fields)"/>
          </div>
        </div>
      </FieldArray>
    </div>
  </div>
</template>

<script>
import NeedWidgetComponent from "@/components/contract/update/item-edited/needWidgetComponent";
import {FieldArray} from 'vee-validate'

export default {
  name: "NeedsSegment",
  props: ['item', 'errors'],
  components: {
    NeedWidgetComponent,
    FieldArray
  },
  methods: {
    async calculate(fields) {

      const loyalty = true

      const priceList = {
            entity: "contract",
            entityId: this.$store.state.updateContract.contract.id,
            loyalty: loyalty
          }



      const send_data = {
        uid: 123,
        mergeCosts: false,
        priceList,
        needs: []
      }

      for (let i = 0; i < fields.length; i++) {
        const need = {
          costType: fields[i].value.costType,
          quantity: parseInt(fields[i].value.quantity)
        }
        send_data.needs.push(need)
      }

      const result = await this.$store.dispatch('calculation/fetchCalculation', send_data)
      //console.log('A számítás eredménye kötvetlenül az érkeztetés után:', result)

      this.$store.commit('updateContract/setEditedCalculationResult', result)

    }
  }
}
</script>

<style scoped>

</style>
