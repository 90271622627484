<template>
    <MainMenu v-if="isAuth"/>

    <router-view/>
</template>


<script setup>

import {computed} from 'vue';
import {useStore as useVuexStore} from 'vuex';
import MainMenu from "@/components/MainMenu";

const vuex_store = useVuexStore();

const isAuth = computed(() => vuex_store.state.app.isAuthenticated);

const params = new URLSearchParams(window.location.search);
vuex_store.dispatch('app/auth', params.get('data'));
</script>


