<template>
  <div class="ui warning message">
    <div class="header">
      Figyelem!
    </div>
    <p>
      A telephely lemondása <strong> havidíj változásával jár.</strong>
    </p>
    <p>
      Leghamarabb
      <strong>
        {{ this.$store.state.updateContract.acceptTerminateDays }} nap
      </strong>
      elteltével kerül ki a számlázásból, de csak akkor, ha az átadott eszközök visszakerültek hozzánk.
    </p>
    <p>
      Ha nem került vissza bármely okból, akkor a számlázásból nem kerül ki. A visszavételt <strong>leszereléses
      munkalapon</strong>
      rögzítjük!
    </p>

  </div>

  <div class="ui warning message">
    <div class="header">
      Fontos!
    </div>
    <p>
      Legyél <strong>bejelentkezve</strong> az Admin új verziójába, különben
      <strong> nem fog</strong> sikerülni!
    </p>

  </div>


</template>

<script setup>

</script>

<style scoped>

</style>
