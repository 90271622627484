import axios from "axios";
import router from '../../router'

const state = () => ({

    contract: null,
   // sent: false,
    sending: false,
    sendingError: false,
    approveRequestInfo: null

})

const getters = {}

const mutations = {
    setContract(state, contract) {
        state.contract = contract
    },
    // setSent(state, sent) {
    //     state.sent = sent
    // },
    setSending(state, sending) {
        state.sending = sending
    },
    setSendingError(state, sending_error) {
        state.sendingError = sending_error
    },
    setApproveRequestInfo(state, data) {
        state.approveRequestInfo = data
    }
}

const actions = {

    async sendApproveRequest({rootState, state, commit, dispatch}, approve_request_data) {

        let url = '';
        let sending_data = {}

        if ('contract' in state.approveRequestInfo) {
            url = rootState.app.serviceAdminServer + '/approve-request-contract'
            sending_data = {contract: state.approveRequestInfo.contract.id, ...approve_request_data}
        } else if ('site' in state.approveRequestInfo) {
            url = rootState.app.serviceAdminServer + '/approve-request-site'
            sending_data = {site: state.approveRequestInfo.site.id, ...approve_request_data}
        } else if ('cost' in state.approveRequestInfo) {
            url = rootState.app.serviceAdminServer + '/approve-request-cost'
            sending_data = {cost: state.approveRequestInfo.cost.id, ...approve_request_data}
        }

        commit('setSending', true)

        await axios.post(url, sending_data)
            .then((response) => {
              //  commit('setSent', true)
                commit('setSending', false)
                commit('setSendingError', false)
                return response.data.contract_id
            })
            .then((contract_id) => {
                dispatch('updateContract/fetchContract', contract_id)
                router.push({path: 'contract/update'})
            })

            .catch((err) => {
                commit('setSending', false)
                commit('setSendingError', true)
                console.error(err)
            })
    },

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
