
export function numberFormat(value, NaN_info = '---', decimalPrecision = 0) {

  if (isNaN(value) || typeof value !== 'number') {
    return NaN_info;
  }
  if (isNaN(decimalPrecision) || typeof decimalPrecision !== 'number' || decimalPrecision < 0) {
    console.error('A tizedes pontosság érvénytelen!');
    return '???';
  }

  const formatter = new Intl.NumberFormat('hu-HU', {
    minimumFractionDigits: decimalPrecision,
    maximumFractionDigits: decimalPrecision,
  });

  return formatter.format(value);
}

