<template>
    <vee-form
            class="ui form"
            @submit="onSubmit"
            :initial-values="initial_values"
            :validation-schema="schema"
            v-slot="{ errors}">
        <div>
            <div class="ui equal width grid">
                <div class="column">
                    <div class="field" :class="{error: 'quantity' in errors}">
                        <div class="ui input">
                            <vee-field type="number" placeholder="Hány darabot" name="quantity"/>
                        </div>
                    </div>
                    <error-message name="quantity" class="error-message"/>
                </div>

                <div class="column">
                    <div class="ui buttons">
                        <button type="submit" class="ui positive mini button">Mentés</button>

                        <div class="or" data-text="?"></div>

                        <button class="ui negative mini  button"
                                @click="cancel">Mégsem
                        </button>
                    </div>
                </div>
            </div>
        </div>

    </vee-form>
</template>

<script setup>
import {ErrorMessage, Field as VeeField, Form as VeeForm} from 'vee-validate'
import {useUpdateQuotationPageStore} from "@/store-pinia/pages/updateQuotationPage";
import * as yup from "yup";
import {useApiQuotationStore} from "@/store-pinia/api/quotations";

const initial_values = {
    quantity: 1
}


const schema = yup.object({
    quantity: yup.number('Ez nem megfelelő szám!').min(1, 'Legálább 1 db-ot hozzá kell adni').required('Megadása kötelező!'),
})


const store_page = useUpdateQuotationPageStore()
const store_api = useApiQuotationStore();

function onSubmit(values) {
    const send_data = {
        quantity: parseInt(values.quantity),
        priceList: parseInt(store_page.focusPriceListElement.id)
    }
    store_api.addToQuotationItems(store_api.quotation.id, send_data)
    store_page.setShowModalAddToItems(false);
}

function cancel() {
    store_page.setFocusPriceListElement(null);
    store_page.setShowModalAddToItems(false);
}

</script>

<style scoped>
.error-message {
    color: red;
}
</style>