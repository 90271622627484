<template>
    <table class="ui celled table">
        <thead>
        <tr>
            <th>Megnevezés</th>
            <th class="right aligned">Mennyiség</th>
            <th class="right aligned">Egyszeri díj</th>
            <th class="right aligned">Havidíj</th>
        </tr>
        </thead>
        <tbody>

        <tr v-for="(cost, index) in store_calculation.result" :key="index">
            <td data-label="Megnevezés">{{ cost.name }}</td>
            <td data-label="Megnevezés" class="right aligned ">{{ cost.quantity }} db</td>
            <td data-label="Egyszeri díj" class="right aligned">{{ numberFormat(cost.priceOneTime, '0') }} Ft</td>
            <td data-label="Havidíj" class="right aligned">{{ numberFormat(cost.priceMonthly, '0') }} Ft/hó</td>
        </tr>
        </tbody>
        <tfoot class="full-width">
        <tr>
            <th class="sum_row"></th>
            <th class="sum_row"></th>
            <th class="sum_row right aligned">{{ numberFormat(store_calculation.sumOneTime, '0') }} Ft</th>
            <th class="sum_row right aligned">{{ numberFormat(store_calculation.sumMonthly, '0') }} Ft/hó</th>
        </tr>
        </tfoot>


    </table>

    <pre v-if="false">
      <p>
      'new-site-calculation-result.vue'

      </p>
         {{ store_calculation.needs }}
        {{ store_calculation.priceList }}
      {{ store_calculation.result }}
  </pre>

</template>

<script setup>


import {numberFormat} from "@/_helper/numberFormat";

import {useCalculationStore} from "@/store-pinia/calculation";


const store_calculation = useCalculationStore()

</script>

<style scoped>
.error {
    color: red;
    font-size: 1.3rem;
}

</style>