<template>
    <div class="item">
        <div class="ui sixteen width grid">
            <div class="four wide column">
                {{ props.discountSegment.discount.name }}
            </div>
            <div class="three wide right aligned column">
                {{ props.discountSegment.discount.amountRate }} %
            </div>
            <div class="six wide column">
                {{ props.discountSegment.periodFirstDay }} - {{ props.discountSegment.periodLastDay }}
            </div>
            <div class="three wide right aligned column">
                {{ numberFormat(props.discountSegment.periodDiscount, '0', 1) }} Ft
            </div>
        </div>
    </div>
</template>


<script setup>
import {defineProps} from "vue";
import {numberFormat} from "@/_helper/numberFormat";

const props = defineProps(['discountSegment'])


</script>

