import crypto from "crypto";

export default function wsseToken(username, password) {

    const now = new Date();
    now.setMinutes(now.getMinutes() - 2);

    const created_str = now.toISOString();
    
    const random = crypto.randomBytes(16)
        .toString('hex')
        .substring(0, 16)

    const nonce = base64_encode(random)

    const digest_start = random + created_str + password

    const digest_sha1 = crypto.createHash('sha1')
        .update(digest_start)
        .digest();

    const digest = base64_encode(digest_sha1)

    return `UsernameToken Username="${username}", PasswordDigest="${digest}", Nonce="${nonce}", Created="${created_str}"`
}


function base64_encode(value) {
    return Buffer.from(value).toString('base64')
}
