<template>
    <form @submit.prevent="onSubmit" class="ui form">
        <div class=" ui segment">
            <div class="ui sixteen width center aligned grid">
                <div class="eight wide column">
                    <div class="field" :class="{error: `endDateOfLastBilledPeriod` in errors}">
                        <div class="ui input">
                            <vee-field type="date" name="endDateOfLastBilledPeriod"/>
                        </div>
                        <error-message name="endDateOfLastBilledPeriod" class="error-message"/>
                    </div>
                </div>

                <div class="eight wide column">
                    <button type="button" class="ui basic blue button" @click="clearDate">
                        Beállítás, hogy még <strong>NEM VOLT</strong> kiszámlázva
                    </button>
                </div>
            </div>
        </div>

        <div class="ui segment">
            <div class="actions">
                <button type="button"
                        class="ui black deny button"
                        @click="cancel">Mégsem
                </button>

                <button type="submit"
                        class="ui positive right labeled icon button">
                    Rögzítés <i class="checkmark icon"></i>
                </button>

            </div>
        </div>
    </form>
</template>


<script setup>
import {computed, defineEmits} from 'vue';
import {useStore} from 'vuex';
import {useSitesStore} from "@/store-pinia/api/site";
import {ErrorMessage, Field as VeeField, useForm} from 'vee-validate';
import * as yup from 'yup';

const vuex_store = useStore();
const store_site = useSitesStore();

const emit = defineEmits(['close']);


const site = computed(() => vuex_store.state.updateContract.settingSite);

const initialValues = computed(() => ({
    endDateOfLastBilledPeriod: site.value.endDateOfLastBilledPeriod,
}));


const schema = yup.object({
    endDateOfLastBilledPeriod: yup.date().nullable(),

});

const {handleSubmit, errors, setFieldValue} = useForm({
    validationSchema: schema,
    initialValues: initialValues
});


const clearDate = () => {
    setFieldValue('endDateOfLastBilledPeriod', null);
};


const onSubmit = handleSubmit(async (values) => {
    await store_site.endDateOfLastBilledPeriod(vuex_store.state.updateContract.settingSite.id, values)
    vuex_store.commit('updateContract/setShowSiteSettingDateOfLastIssuedInvoiceModal', false)
    emit('close')
});

function cancel(){
    vuex_store.commit('updateContract/setShowSiteSettingDateOfLastIssuedInvoiceModal', false)
    emit('close')
}

</script>


<style scoped>
.form {
    margin-right: 2rem;
    margin-bottom: 2rem;
}

</style>