<template>
    <customer-info/>
    <select-service-company/>
    <select-product/>

    <button v-if="store_page.product && store_page.serviceCompany"
            @click="store_page.setActiveStep(2)"
            class="ui primary button">Tovább
    </button>
</template>

<script setup>
import CustomerInfo from "@/components/quotationCreate/stepA/customerInfo.vue";


import SelectProduct from "@/components/quotationCreate/selectProduct.vue";
import {useCreateQuotationPageStore} from "@/store-pinia/pages/createQuotationPage";
import SelectServiceCompany from "@/components/quotationCreate/stepA/select-service-company.vue";

const store_page = useCreateQuotationPageStore();
</script>

<style scoped>

</style>
