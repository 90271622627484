<template>
  <div class="ui sixteen width grid">
    <div class="five wide column">
      <h3 class="ui header">Havidíjak</h3>
    </div>
    <div class="six wide right aligned column">
      <strong> Árelemlés mértéke: </strong>
      <div class="ui right labeled input">
        <input v-model="increasePercent" type="text" placeholder="Áremelés mértéke">
        <div class="ui basic label">%</div>
      </div>
    </div>
    <div class="five wide right aligned column">
      <button type="button" class="ui primary basic button"
              @click="applyIncrease">
        <i class="share square icon"/>
        Havidíjak másolása
      </button>
    </div>
  </div>
</template>

<script setup>
import {ref} from 'vue';
import {useContractPriceListStore} from "@/store-pinia/contractPriceList";

const store_contractPrices = useContractPriceListStore();

const increasePercent = ref(9);

const applyIncrease = () => {
  const parsedPercent = parseInt(increasePercent.value);
  if (!isNaN(parsedPercent)) {
    store_contractPrices.copyingIncreasedPrices('monthly_fee', parsedPercent);
  } else {
    alert("Kérjük, adj meg egy érvényes százalékot a havidíjak emeléséhez");
  }
}
</script>


<style scoped>
</style>


