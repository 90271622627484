<template>
  <div class="ui sixteen width grid">
    <div class="twelve wide column">

      <div class="ui equal width grid">
        <div v-for="(change, index) in  props.cost.priceChanges"
             class=" right aligned column"
             :key="change.id">
          <span :class="{isModified: change.isModified}">{{ numberFormat(change.price, '0') }}</span>

          <i v-if="index === props.cost.priceChanges.length-1"
             @click="store.setEditedContractPrice(cost)"
             class=" edit icon space pointer"/>
        </div>

        <div
            v-if="store.editedContractPrice
            && store.editedContractPrice.contractPriceItem.id === props.cost.contractPriceItem.id"
            class="ten wide right aligned column">
          <edit-current-contract-price :contractPrice="props.cost"/>
        </div>
      </div>
    </div>

    <div class="four wide column">
      <div class="ui mini input">
        <input type="text" :value="props.cost.newPrice">
      </div>
    </div>


  </div>
</template>

<script setup>

import {defineProps} from "vue";
import {useContractPriceListStore} from "@/store-pinia/contractPriceList";

import EditCurrentContractPrice from "@/components/contract/update/contract-priceList/edit-current-price.vue";
import {numberFormat} from "@/_helper/numberFormat";

const props = defineProps(['cost', 'fields']);
const store = useContractPriceListStore();


</script>

<style scoped>

.isModified {
  color: red;
}

.space {
  margin-left: 1rem;
}

.pointer {
  cursor: pointer;
}
</style>