<template>
    <Teleport to="body">
        <Modal :show="show()" :size="'modal-large'" @close="onCloseClick">
            <template #header>
                <div>&nbsp;</div>
            </template>

            <template #body>
                <div v-if="contract_store.sitesAtPeriod !== null"
                     style="margin-right: 1rem;">
                    <div class="ui sixteen width grid">
                        <div class="eight wide column">
                            <request-period-info/>
                        </div>
                        <div class="eight wide column">
                            <modal-actions/>
                        </div>
                    </div>

                    <div class="ui segment" style="width: 100%;">
                        <div class="ui container">

                            <div style="width: 100%">
                                <div v-for="siteAtPeriod in contract_store.sitesAtPeriod.sites"
                                     :key="siteAtPeriod.sites"
                                     style="margin-top: 1rem; margin-bottom: 2rem;">
                                    <site-accordion-title :siteAtPeriod="siteAtPeriod"/>
                                    <site-accordion-content :siteAtPeriod="siteAtPeriod"/>
                                </div>

                                <div style="margin-top: 2rem; margin-bottom: 2rem;">
                                    <hr/>
                                    <div class="ui sixteen width grid">
                                        <div class="eight wide column">
                                            <h2 class="ui header">
                                                Időszak végösszege:
                                            </h2>
                                        </div>
                                        <div class="eight wide right aligned column">
                                            <h2 class="ui header">
                                                {{ numberFormat(contract_store.totalFinalPrice, '0', 0) }} Ft
                                            </h2>
                                        </div>
                                    </div>
                                    <hr/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </Modal>
    </Teleport>
</template>


<script setup>
import Modal from "@/components/slots/modal.vue";
import {useStore as useVuexStore} from "vuex";
import {useContractStore} from "@/store-pinia/api/contract";
import ModalActions from "@/components/contract/update/modals/contract-sites-at-period/modal-actions.vue";
import SiteAccordionTitle from "@/components/contract/update/modals/contract-sites-at-period/site-accordion-title.vue";
import SiteAccordionContent
    from "@/components/contract/update/modals/contract-sites-at-period/site-accordion-content.vue";
import RequestPeriodInfo from "@/components/contract/update/modals/contract-sites-at-period/request-period-info.vue";
import {numberFormat} from "@/_helper/numberFormat";


const vuex_store = useVuexStore();

const contract_store = useContractStore()


function onCloseClick() {
    vuex_store.commit('updateContract/setShowSitesInPeriodModal', false)
}


function show() {
    return vuex_store.state.updateContract.showSitesInPeriodModal;
}


</script>


<style scoped>


</style>



