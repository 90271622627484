import {defineStore} from "pinia";
import setupAuthHeaderFor from "@/_helper/setupAxiosHeader";
import axios from "axios";
import {useErrorsStore} from "@/store-pinia/errors";
import {useOroStore} from "@/store-pinia/oro";
import {useCostDetailingStore} from "@/store-pinia/costsDetailing";
import {useCostsStore} from "@/store-pinia/api/costs";
import {useCalculationStore} from "@/store-pinia/calculation";


const url_contract_price_list = '/api/contract-price-list';


export const useContractPriceListStore = defineStore({
        id: 'contractPriceList',
        state: () => {
            return {
                contractId: null,
                contract: null,
                priceList: [],
                priceList_v1: [],
                editedChange: null,
                editedContractPrice: null,

                request: {
                    startingFromDate: null,
                    changesFromDate: null
                },

                showNew: {
                    monthly_fee: false,
                    install_cost: false,
                    service_fee: false
                }
            }
        },
        actions: {
            setContractId(contract_id) {
                this.contractId = contract_id
            },

            setContract(contract) {
                this.contract = contract
            },


            setPriceList(price_list) {
                this.priceList = price_list
            },

            setPriceList_v1(price_list) {
                this.priceList_v1 = price_list
            },


            setShowNewValue(categoryName, show_value) {

                if (categoryName === 'monthly_fee') {
                    this.showNew.monthly_fee = show_value
                } else if (categoryName === 'install_cost') {
                    this.showNew.install_cost = show_value
                }
                if (categoryName === 'service_fee') {
                    this.showNew.service_fee = show_value
                }
            },
            setHideAllShowNewValues() {
                this.setShowNewValue('monthly_fee', false);
                this.setShowNewValue('install_cost', false);
                this.setShowNewValue('service_fee', false);
            },

            setEditedChange(edited_change_item) {
                this.editedChange = edited_change_item;
            },
            setEditedContractPrice(contract_price) {
                this.editedContractPrice = contract_price;
            },


            setRequestStartingFromDate(request_starting_date) {
                this.request.startingFromDate = request_starting_date
            },

            setRequestChangesFromDate(changes_from_date) {
                this.request.changesFromDate = changes_from_date
            },


            prepareForPriceChange() {
                this.priceList_v1.map(el => el.newPriceRequest = null)
            },


            //############################ Árak a megadott százalékkal megemelve

            copyingIncreasedPrices(sectionName, increasedPercent) {
                if (this.priceList.sections[sectionName]) { // Ellenőrzi, hogy létezik-e ilyen szekció
                    this.priceList.sections[sectionName].forEach(item => {
                        const lastPriceChange = item.priceChanges[item.priceChanges.length - 1];
                        if (lastPriceChange) {
                            // Az árat megnöveli az increasedPercent értékével
                            const increasedPrice = lastPriceChange.price * (1 + (increasedPercent / 100));
                            // Hozzáadja az új árat a newPrice változóhoz
                            item['newPrice'] = parseInt(increasedPrice);
                        }
                    });
                }
            },

            //############################              Az árlista lekérdezése             #############################
            async fetchContractPriceList(contract_id) {

                const store_oro = useOroStore();
                const store_errors = useErrorsStore();

                try {
                    this.setContractId(contract_id);

                    const axiosConfig = setupAuthHeaderFor('wsse');

                    const url = `${store_oro.host}${url_contract_price_list}/${contract_id}`;
                    const resp = await axios.get(url, axiosConfig);
                    this.setPriceList(resp.data.v2);

                    const priceList_v1 = resp.data.v1;
                    const store_cost_detailing = useCostDetailingStore();
                    const store_costs = useCostsStore();
                    const store_calculation = useCalculationStore();
                    store_cost_detailing.setPriceList(priceList_v1);
                    store_costs.setPriceList(priceList_v1);
                    store_calculation.setPriceList(priceList_v1);

                    this.prepareForPriceChange();
                } catch (err) {
                    const error = {
                        // code: err.response.status,
                        message: err.message,
                        message_public: 'Az árlista elérése sikertelen!'
                    };

                    store_errors.addError(error);
                }
            },


// xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx              ÁRVÁLTOZÁS beállítása           xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx

            async callCreateContractPriceChange(date) {

                const store_oro = useOroStore();
                const store_errors = useErrorsStore();

                const axiosConfig = setupAuthHeaderFor('wsse');

                const contract_id = this.contractId;

                const url = `${store_oro.host}/api/create-contract-price-change/${contract_id}`;


                const extractChangesFromSection = (section) => {
                    return section.map(el => ({
                        id: parseInt(el.contractPriceItem.id),
                        newPrice: parseInt(el.newPrice)
                    }));
                };

                const sectionsToProcess = ['monthly_fee', 'install_cost', 'service_fee'];
                const contractPrices = [];

                sectionsToProcess.forEach(sectionName => {
                    const changes = extractChangesFromSection(this.priceList.sections[sectionName]);
                    contractPrices.push(...changes);
                });


                const send_data = {
                    validFromDate: date.dateOfChange,
                    contractPrices: contractPrices
                }


                try {
                    await axios.post(url, send_data, axiosConfig);
                    this.fetchContractPriceList(contract_id);
                } catch (err) {
                    const error = {
                        code: err.response.status,
                        message: err.message,
                        message_public: 'Az árváltozás beállítása sikertelen!'
                    }
                    store_errors.addError(error)
                }


            },

// xxxxxxxxxxxxxxxxxxxxxxxxxxxxx

            async patchPriceListChange(patch_data) {
                const store_oro = useOroStore();
                const store_errors = useErrorsStore();

                const axiosConfig = setupAuthHeaderFor('wsse');

                const url = `${store_oro.host}/api/update-price-change-item/${this.editedContractPrice.contractPriceItem.id}`;

                try {
                    await axios.post(url, patch_data, axiosConfig);

                    this.setEditedChange(null);
                    this.setEditedContractPrice(null);

                    await this.fetchContractPriceList(this.contractId);
                } catch (err) {
                    const error = {
                        code: err.response.status,
                        message: err.message,
                        message_public: 'Az árlista-bejegyzés frissítése sikertelen!'
                    };
                    store_errors.addError(error);
                }
            },

//############################
            async createNewPriceListItem(new_priceListItem) {

                const store_oro = useOroStore();
                const store_errors = useErrorsStore();

                const axiosConfig = setupAuthHeaderFor('wsse');

                const url = `${store_oro.host}/api/create-new-contract-price/${this.contractId}`;

                await axios.post(url, new_priceListItem, axiosConfig)
                    .then(() => {
                        this.fetchContractPriceList(this.contractId)
                    })
                    .then(() => {
                            this.setEditedChange(null)
                            this.setHideAllShowNewValues();
                        }
                    )

                    .catch(err => {
                        const error = {
                            code: err.response.status,
                            message: err.message,
                            message_public: 'Az árlista-bejegyzés hozzáadása sikertelem!'
                        }
                        store_errors.addError(error)
                    })
            },

        },

        getters:
            {
                monthlyFees: (state) => {
                    return state.priceList.sections.monthly_fee
                },
                installCosts:
                    (state) => {
                        return state.priceList.sections.install_cost
                    },
                serviceFees:
                    (state) => {
                        return state.priceList.sections.service_fee
                    },
                monthlyFees_v1:
                    (state) => {
                        return (state.priceList_v1.filter((el) => el.categoryName === 'monthly_fee'))
                    },
                installCosts_v1:
                    (state) => {
                        return (state.priceList_v1.filter((el) => el.categoryName === 'install_cost'))
                    },
                serviceFees_v1:
                    (state) => {
                        return (state.priceList_v1.filter((el) => el.categoryName === 'service_fee'))
                    },
            }
    })
;


