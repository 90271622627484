<template>
    <div class="ui segment">
        <div class="ui equal width middle aligned grid">
            <div class="center aligned column">
                <button class="ui red tiny button"
                        @click="showPDF('reverse')">Helyesbítő melléklet
                </button>
            </div>

            <div class="center aligned column">
                <button class="ui yellow tiny button"
                        @click="showPDF('view')">Megtekintés
                </button>
            </div>

            <div class="center aligned  column">
                <button class="ui green tiny button"
                        @click="showPDF('normal')">Számla melléklet
                </button>
            </div>
        </div>


    </div>
</template>


<script setup>
import {useContractStore} from "@/store-pinia/api/contract";

const store_contract = useContractStore();


function showPDF(pdfType) {
    const contract_id = store_contract.contract.id
    const period = {
        periodStart: store_contract.sitesAtPeriod.requestedPeriod.periodStart,
        periodEnd: store_contract.sitesAtPeriod.requestedPeriod.periodEnd
    }
    store_contract.downloadPDF(contract_id, pdfType, period)
}


</script>

