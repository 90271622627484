<template>
    <table class="ui celled table" v-if="store.quotation">
        <thead>
        <tr>
            <th>Megnevezés</th>
            <th class="right aligned">Mennyiség</th>
            <th class="right aligned">Egyszeri díj</th>
            <th class="right aligned">Havidíj</th>
            <th class="right aligned">Egyszeri díj</th>
            <th class="right aligned">Havidíj</th>
            <th class="right aligned"></th>
        </tr>
        </thead>

        <tbody>
        <tr v-for="(item, index) in store.quotation.items" :key="index">
            <td data-label="Megnevezés">{{ item.name }}</td>
            <td data-label="Megnevezés" class="right aligned ">{{ item.quantity }} db</td>
            <td data-label="Egyszeri díj" class="right aligned">{{ numberFormat(item.unitPriceOneTime, '0') }} Ft</td>
            <td data-label="Havidíj" class="right aligned">{{ numberFormat(item.unitPriceMonthly, '0') }} Ft/hó</td>
            <td data-label="Egyszeri díj" class="right aligned">{{ numberFormat(item.priceOneTime, '0') }} Ft</td>
            <td data-label="Havidíj" class="right aligned">{{ numberFormat(item.priceMonthly, '0') }} Ft/hó</td>
            <td class="center aligned">
                <i class="red trash icon"
                   @click="deleteItemFromQuotation(item.id)"></i>
            </td>
        </tr>
        </tbody>

        <tfoot class="full-width">
        <tr>
            <th class="sum_row">Összesen:</th>
            <th class="sum_row"></th>
            <th class="sum_row"></th>
            <th class="sum_row"></th>
            <th class="sum_row right aligned">{{ numberFormat(store.quotation.priceOneTime, '0') }} Ft</th>
            <th class="sum_row right aligned">{{ numberFormat(store.quotation.priceMonthly, '0') }} Ft/hó</th>
            <th></th>
        </tr>
        </tfoot>
    </table>
</template>

<script setup>

import {useApiQuotationStore} from "@/store-pinia/api/quotations";
import {numberFormat} from "@/_helper/numberFormat";

const store = useApiQuotationStore();


function deleteItemFromQuotation(item_id) {
    store.deleteQuotationItem(item_id)
}

</script>

<style scoped>

</style>