<template>

  <div class="ui container">

    <h2 class="ui header">Jóváhagyás elküldése az ügyfélnek</h2>

    <approve-request-info :info="approve.approveRequestInfo"/>



    <div v-if="approve.sent">
      <div v-if="!approve.sendingError" class="ui success message">
        <div class="header">
          A jóváhagyó e-mail elküldésre került
        </div>
        <p>Most már visszatérhet a OroCRM oldalhoz, vagy nyugodtan bezárhatja ezt a böngésző fület.</p>
      </div>


      <div v-else class="ui negative message">
        <div class="header">
          Az e-mail kiküldése sikertelen!
        </div>
      </div>
    </div>


    <div v-else>
      <approve-form :sending="approve.sending"/>
    </div>

    <div v-if="approve.sent" class="returnToCRM">
      <button
          class="ui black button"
          type="button"
          @click="this.$store.dispatch('app/returnToCRM', {}, {root: true})">
        Vissza a szerződések listájához
      </button>
    </div>


  </div>
</template>

<script>
import ApproveForm from "@/components/contract/approve-request/approveForm";
import ApproveRequestInfo from "@/components/contract/approve-request/approveRequestInfo";
import {mapState} from "vuex";

export default {
  name: "ApproveRequestPage",
  components: {
    ApproveForm,
    ApproveRequestInfo
  },
  computed: {
    ...mapState({
      approve: state => state.approve,
    }),
  }

}
</script>

<style scoped>
.returnToCRM {
  margin-top: 3rem
}
</style>
