import {defineStore} from "pinia";
import setupAuthHeaderFor from "@/_helper/setupAxiosHeader";
import axios from "axios";
import {useErrorsStore} from "@/store-pinia/errors";
import {useOroStore} from "@/store-pinia/oro";


export const useCustomerStore = defineStore({
    id: 'apiCustomer',
    state: () => ({}),
    actions: {

        async updateCustomer(customerId, payload) {
            const store_oro = useOroStore();
            const url_customer = `${store_oro.host}/api/customer-update/${customerId}`;
            const store_errors = useErrorsStore();

            const axiosConfig = setupAuthHeaderFor('wsse');

            try {
                const resp = await axios.post(url_customer, payload, axiosConfig);
                return resp.data;
            } catch (err) {
                const error = {
                    code: err.response.status,
                    message: err.message,
                    message_public: 'Az előfizető frissítése sikertelen!'
                };
                store_errors.addError(error);
            }
        },

    },
    getters: {},
});
