<template>
  <div class="ui message">
    <div v-if="'contract' in info">
      <p>A <strong>{{ info.contract.registrationNumber }}</strong> szerződésen a módosítások jóváhagyási kérése</p>
    </div>

    <div v-if="'site' in info">
      <div>A <strong>{{ info.site.nameAtContract }}</strong> nevű telephelyen
        <span> ({{ info.site.address.city }}, {{ info.site.address.street }})</span>
        a kért változtatások jóváhagyásának kérése
      </div>
    </div>


    <div v-if="'cost' in info">
      <div>A <strong>{{ info.cost.name }}&nbsp;</strong>
        <span v-if="info.cost.status==='new'">felszerelésének jóváhagyásási kérése</span>
        <span v-if="info.cost.terminateStatus==='requested'">leszerelésének jóváhagyási kérése {{
            info.cost.terminateRequestDate
          }} nappal</span>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "approveRequestInfo",
  props: ['info']
}
</script>

<style scoped>

</style>
