<template>

    <table class="ui celled table">
        <thead>
        <tr>
            <th>Megnevezés</th>
            <th class="right aligned">Mennyiség</th>
            <th class="right aligned">Egyszeri díj</th>
            <th class="right aligned">Havidíj</th>
        </tr>
        </thead>
        <tbody>

        <tr v-for="(cost, index) in store.result" :key="index">
            <td data-label="Megnevezés">{{ cost.name }}</td>
            <td data-label="Megnevezés" class="right aligned ">{{ cost.quantity }} db</td>
            <td data-label="Egyszeri díj" class="right aligned">{{ numberFormat(cost.priceOneTime, '0') }} Ft</td>
            <td data-label="Havidíj" class="right aligned">{{ numberFormat(cost.priceMonthly, '0') }} Ft/hó</td>
        </tr>
        </tbody>
        <tfoot class="full-width">
        <tr>
            <th class="sum_row">
                <div class="ui sixteen widht grid">
                    <div class="eight wide column">
                        Összesen:
                    </div>
                    <div class="eight wide center aligned column">
                    </div>
                </div>
            </th>
            <th class="sum_row"></th>
            <th class="sum_row">
                <div class="ui equal width grid">
                    <div class="center aligned column">

                    </div>
                    <div class="right aligned column">
                        <strong>{{ numberFormat(store.sumOneTime, '0') }} Ft </strong>
                    </div>
                </div>
            </th>
            <th class="right aligned sum_row">
                <div class="ui equal width grid">

                    <div class="right aligned column">
                        <strong> {{ numberFormat(store.sumMonthly, '0') }} Ft/hó</strong>
                    </div>
                </div>
            </th>
        </tr>

        </tfoot>


    </table>

    <pre v-if="false">
      {{ store.result }}
  </pre>
</template>

<script setup>

import {numberFormat} from "@/_helper/numberFormat";
import {useCalculationStore} from "@/store-pinia/calculation";


const store = useCalculationStore()

</script>

<style scoped>
.error {
    color: red;
    font-size: 1.3rem;
}

</style>