<template>

    <vee-form class="ui form"
              @click.stop
              @submit="onShowSiteAppendix"
              :validation-schema="schema"
              :initial-values="initialValues"
              v-slot="{errors}">


        <div class="ui sixteen wide  middle aligned grid">

            <div class="six wide column">
                <div class="field" :class="{error: 'periodStart' in errors}">
                    <label>Időszak kezdete</label>
                    <div class="ui input">
                        <vee-field type="date" name="periodStart"/>
                    </div>
                    <error-message name="periodStart" class="error-message"/>
                </div>
            </div>

            <div class="six wide column">
                <div class="field" :class="{error: 'periodEnd' in errors}">
                    <label>Időszak vége</label>
                    <div class="ui input">
                        <vee-field type="date" name="periodEnd"/>
                    </div>
                    <error-message name="periodEnd" class="error-message"/>
                </div>
            </div>

            <div class="four wide  right aligned column">
                <button type="submit" class="ui primary mini basic button">Megtekint</button>
            </div>

        </div>


    </vee-form>
</template>


<script setup>
import {ErrorMessage, Field as VeeField, Form as VeeForm} from 'vee-validate';
import * as yup from "yup";
import {computed, defineProps} from "vue";
import {useSitesStore} from "@/store-pinia/api/site";
import {useStore as useVuexStore} from 'vuex';


const props = defineProps(['site']);
const vuex_store = useVuexStore();
const store_site = useSitesStore();

const initialValues = computed(() => ({
    periodStart: '',
    periodEnd: ''
}));


const schema = yup.object().shape({
        periodStart: yup.date()
            .typeError('???')
            .required('Megadása kötelező!'),
        periodEnd: yup.date()
            .typeError('???')
            .required('Megadása kötelező!')
            .min(yup.ref('periodStart'), 'A befejező dátum nem lehet korábbi, mint a kezdő dátum!'),
    }
);


function onShowSiteAppendix(values) {

    vuex_store.commit('updateContract/setShowSiteCostsInPeriodModal', true);
    store_site.siteCostsAtPeriod(props.site.id, values);
}


</script>


<style scoped>
.error {
    color: red;
}

</style>
