import {defineStore} from "pinia";
import axios from "axios";
//import setupAuthHeaderFor from "@/_helper/setupAxiosHeader";

export const useOroStore = defineStore({
    id: 'oro',
    state: () => {
        return {
            host: null,
            user: null,
            userId: null,
            apiKey: null,
            admin3: {
                host: null,
                token: null,
            }
        };
    },

    actions: {
        setHost(host) {
            this.host = host
        },
        setUser(user) {
            this.user = user;
        },
        setApiKey(api_key) {
            this.apiKey = api_key;
        },

        setAdmin3Host(admin3Host) {
            this.admin3.host = admin3Host;
        },
        setAdmin3Token(token) {
            this.admin3.token = token;
        },
        setUserId(oro_userId) {
            this.userId = oro_userId;
        },

        async loginToAdmin3() {

            try {
                const url = `${this.admin3.host}/oro-user-login`;
                const admin_3_login_data = {
                    oroUserApiKey: this.apiKey,
                    userId: this.userId
                }
                
                const response = await axios.post(url, admin_3_login_data);
                this.setAdmin3Token(response.data.token);
            } catch (err) {
                console.log(err)
            }
        },
    },

    getters: {},
});
