<template>
    <i class="trash grey icon pointerCursor"
       v-if="props.cost.serviceLicenseName !=='service_fee'
                   && props.cost.status !== 'active'
                   && props.cost.status !== 'terminated'
                   && props.cost.status !== 'completed' "
       @click.stop="deleteCost(props.cost)"></i>


    <i class="thumbs down outline red icon pointerCursor"
       v-if="!props.cost.isTerminateRequested && props.cost.status === 'active'"
       @click.stop="costTerminateRequest(props.cost)"></i>


    <i v-if="props.cost.categoryType === 'monthly' &&  isGrantedDeepTesting() "
       @click.stop="costDeepTesting(props.cost)"
       class="ui flask red icon"
    />

    <pre v-if="false">
        {{ props.cost }}
    </pre>

</template>

<script setup>
import {defineProps} from "vue";
import {useStore as useVuexStore} from 'vuex';


const props = defineProps(['cost']);
const vuex_store = useVuexStore()


function deleteCost(cost) {
    vuex_store.dispatch('updateContract/deleteCost', cost.id);
}

function isGrantedDeepTesting() {
    const allow_oro_users = [135, 146, 600] //Csak ezeknek a személynek szabad végrehajtani, senki másnak!
    return allow_oro_users.includes(vuex_store.state.app.currentUser.id)
}


function costTerminateRequest(cost) {
    vuex_store.commit('updateContract/setEditedCost', cost)
    vuex_store.commit('updateContract/setShowCostTerminateRequestModal', true)
}

function costDeepTesting(cost) {
    vuex_store.commit('updateContract/setEditedCost', cost)
    vuex_store.commit('updateContract/setShowCostDeepTestingModal', true)
}


</script>
