<template>
    <div class="ui segment contracts">
        <h4 class=" ui header">Szerződések listája</h4>
        <div v-if="store_opportunity.opportunity">
            <div class="ui five cards" v-if="store_opportunity.opportunity.contracts.length > 0">
                <div v-for="contract in store_opportunity.opportunity ? store_opportunity.opportunity.contracts : []"
                     :key="contract.id"
                     class="card pointer"
                     @click="navigateToEditContract(contract)">

                    <div class="content">
                        <div class="header">
                            {{ contract.product.name }}
                        </div>
                        <div class="meta">
                            Készült: {{ contract.dateOfContract }}
                        </div>
                        <div class="description">
                            {{ contract.registrationNumber }}
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="ui message">
                    A {{ store_opportunity.opportunity.account.customer.name }} nevű cégnek (Adószám:
                    {{ store_opportunity.opportunity.account.taxNumber }}) még nincsenek szerződései
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {useOpportunitiesStore} from "@/store-pinia/api/opportunities";
import router from "@/router";

const store_opportunity = useOpportunitiesStore()


function navigateToEditContract(contract) {
    router.push(`/contract/update/${contract.id}`)
}


</script>

<style scoped>
.pointer {
    cursor: pointer
}

.contracts{
    background-color: ghostwhite;
}

</style>