<template>
    
    <vee-field placeholder="ID"
               :class="{error: `discounts[${props.idx}].id` in props.errors}"
               :name="`discounts[${props.idx}].id`"
               style="display: none">
    </vee-field>

    <div class="ui sixteen wide grid ">
        <div class="five wide column">
            <div class="field">
                <div class="ui input">
                    <vee-field placeholder="A kedvezmény neve"
                               :class="{error: `discounts[${props.idx}].name` in props.errors}"
                               :name="`discounts[${props.idx}].name`">
                    </vee-field>
                </div>
                <error-message :name="`discounts[${props.idx}].name`" class="error-message"/>
            </div>
        </div>

        <div class="three wide column">
            <div class="field">
                <div class="ui input">
                    <vee-field type="date"
                               :class="{error: `discounts[${props.idx}].fromDate` in props.errors}"
                               :name="`discounts[${props.idx}].fromDate`">
                    </vee-field>
                </div>
                <error-message :name="`discounts[${props.idx}].fromDate`" class="error-message"/>
            </div>
        </div>

        <div class="three wide column">
            <div class="field">
                <div class="ui input">
                    <vee-field type="date"
                               :class="{error: `discounts[${props.idx}].toDate` in props.errors}"
                               :name="`discounts[${props.idx}].toDate`">
                    </vee-field>
                </div>
                <error-message :name="`discounts[${props.idx}].toDate`" class="error-message"/>
            </div>
        </div>

        <div class="three wide column">
            <div class="field">
                <div class="ui input">
                    <vee-field type="number" step="0.1"
                               :class="{error: `discounts[${props.idx}].amountRate` in props.errors}"
                               :name="`discounts[${props.idx}].amountRate`">
                    </vee-field>
                </div>
                <error-message :name="`discounts[${props.idx}].amountRate`" class="error-message"/>
            </div>
        </div>

        <div class="one wide center aligned column">
            <i class="trash red  icon" @click="removeItem(props.idx)"/>
        </div>
    </div>


</template>


<script setup>
import {ErrorMessage, Field as VeeField} from "vee-validate";
import {defineProps} from "vue";

const props = defineProps(['remove', 'idx', 'errors', 'values']);


function removeItem(idx) {
    props.remove(idx)
}


</script>


<style scoped>

.error {
    color: red;
}

</style>

