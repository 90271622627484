<template>
    <div class="ui cards">
        <select-person-card @click="selectDelegatePerson" :defined="!!store_opportunity.customer.delegatePerson">
            <template v-slot:role>Aláíró személy</template>
            <template v-slot:personName>
                {{ store_opportunity.customer.delegatePerson ? store_opportunity.customer.delegatePerson.name : '' }}
            </template>
        </select-person-card>

        <select-person-card @click="selectFinancialPerson" :defined="!!store_opportunity.customer.financialPerson">
            <template v-slot:role>Pénzügyi kapcsolattartó</template>
            <template v-slot:personName>
                {{ store_opportunity.customer.financialPerson ? store_opportunity.customer.financialPerson.name : '' }}
            </template>
        </select-person-card>

        <select-person-card @click="selectTechnicalPerson" :defined="!!store_opportunity.customer.technicalPerson">
            <template v-slot:role>Műszaki kapcsolattartó</template>
            <template v-slot:personName>
                {{ store_opportunity.customer.technicalPerson ? store_opportunity.customer.technicalPerson.name : '' }}
            </template>
        </select-person-card>
    </div>
</template>


<script setup>
import SelectPersonCard from "@/components/contract/create/step-b-dedicated-person-info.vue";
import {useOpportunitiesStore} from "@/store-pinia/api/opportunities";
import {usePageCreateContractStore} from "@/store-pinia/pages/pageCreateContract";

const store_opportunity = useOpportunitiesStore()

const store_page = usePageCreateContractStore()

function selectDelegatePerson() {
    store_page.setShowPersonsList(true)
    store_page.setIntentSelectPerson('delegatePerson')
}


function selectFinancialPerson() {
    store_page.setShowPersonsList(true)
    store_page.setIntentSelectPerson('financialPerson')
}

function selectTechnicalPerson() {
    store_page.setShowPersonsList(true)
    store_page.setIntentSelectPerson('technicalPerson')
}


console.log(store_page)

// export default {
//     name: "CustomerPersons",
//     components: {
//         SelectPersonCard
//     },
//     computed: {
//
//         delegatePerson() {
//             const person = this.$store.state.customer.customer.delegatePerson
//             return this.$store.state.createContract.contactPersons.find((el) => el.id === person)
//         },
//         financialPerson() {
//             const person = this.$store.state.customer.customer.financialPerson
//             return this.$store.state.createContract.contactPersons.find((el) => el.id === person)
//         },
//         technicalPerson() {
//             const person = this.$store.state.customer.customer.technicalPerson
//             return this.$store.state.createContract.contactPersons.find((el) => el.id === person)
//         },
//     },
//     methods: {
//         selectDelegatePerson() {
//             this.$store.commit('createContract/setIntentSelectPerson', 'delegatePerson')
//             this.$store.commit('createContract/setShowPersonsList', true)
//         },
//         selectFinancialPerson() {
//             this.$store.commit('createContract/setIntentSelectPerson', 'financialPerson')
//             this.$store.commit('createContract/setShowPersonsList', true)
//         },
//         selectTechnicalPerson() {
//             this.$store.commit('createContract/setIntentSelectPerson', 'technicalPerson')
//             this.$store.commit('createContract/setShowPersonsList', true)
//         },
//         customer() {
//             const store_opportunity = useOpportunitiesStore()
//             return store_opportunity.opportunity ? store_opportunity.opportunity.account.customer : null
//         }
//
//     }
// }
</script>

<style scoped>

</style>
