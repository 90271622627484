<template>

    <vee-form class="ui form"
              @submit="onSubmit"
              :validation-schema="schema"
              :initial-values="initialValues"
              v-slot="{ errors, values}">


        <new-site-identity-segment :errors="errors"/>


        <div class="ui segment">
            <h3 class="ui header">Költségek</h3>

            <FieldArray name="need_costs" v-slot="{ fields, push, remove }">
                <div v-for="(fields_row, idx) in fields" :key="fields_row.key">
                    <new-site-form-cost-row :remove="remove" :idx="idx" :errors="errors"
                                            :values="values"/>
                </div>

                <div class="need_actions">
                    <button
                            class="ui basic green icon mini button"
                            type="button" @click="push({ priceListItem: '', quantity: 1} )">
                        <i class="plus green circle large icon"/>Új tétel
                    </button>

                    <button
                            class="ui basic orange icon mini button"
                            type="button"
                            @click="store_calculation.calculate()">
                        <i class="calculator orange large icon"/>Újraszámol
                    </button>
                </div>


            </FieldArray>
        </div>


        <div v-if="false">
            <pre>{{ values }}</pre>
            <pre>{{ errors }}</pre>
            <pre>{{ store_calculation.priceList }}</pre>
        </div>

        <new-site-actions/>
    </vee-form>

</template>

<script setup>
import {FieldArray, Form as VeeForm} from "vee-validate";
import NewSiteFormCostRow from "@/components/contract/update/modals/create-new-site/new-site-form-cost-row.vue";
import NewSiteIdentitySegment from "@/components/contract/update/modals/create-new-site/new-site-identity-segment.vue";
import NewSiteActions from "@/components/contract/update/modals/create-new-site/new-site-actions.vue";
import * as yup from "yup";
import {useCalculationStore} from "@/store-pinia/calculation";
import {useSitesStore} from "@/store-pinia/api/site";
import {useContractPriceListStore} from "@/store-pinia/contractPriceList";

const store_calculation = useCalculationStore()


const schema = yup.object().shape(
    {
        name: yup.string().required('A telepítés helyét el kell nevezni!'),
        city: yup.string().required('A település nevét meg kell adni!'),
        street: yup.string().required('Megadása kötelező!'),
        need_costs: yup.array().of(
            yup.object().shape({
                    priceListItem: yup.string().required('Meg kell adni!').min(1, 'Ki kell tölteni'),
                    quantity: yup.number('Csak szám lehet!').required('A mennyiséget meg kell adni!')
                }
            )
        )
    }
)


const initialValues = {
    name: '',
    city: '',
    street: '',
    need_costs: [
        {
            priceListItem: '',
            quantity: 1
        }
    ]
}


function onSubmit(values) {

    const store_site = useSitesStore()

    const store_contractPriceList = useContractPriceListStore()

    const send_values = {
        ...values, costs: values.need_costs
    }

    delete send_values['need_costs']

    store_site.createSite(store_contractPriceList.contract.id, send_values)

}

</script>


<style scoped>

.need_actions {
    padding-top: 1rem;
}

</style>