<template>
  <vee-form class="ui form"
            @submit="onIdentitySubmit"
            :validation-schema="schema"
            :initial-values="initialValues"
            v-slot="{errors}">


    <div class="ui three fields">
      <div class="field" :class="{error: `name` in errors}">
        <label>Név</label>
        <div class="ui input">
          <vee-field type="text" name="name"/>
        </div>
        <error-message name="name" class="error-message"/>
      </div>

      <div class="field" :class="{error: 'city' in errors}">
        <label>Település</label>
        <div class="ui input">
          <vee-field type="text" name="city"/>
        </div>
        <error-message name="city" class="error-message"/>
      </div>

      <div class="field" :class="{error: `street` in errors}">
        <label>Utca, házszám</label>
        <div class="ui input">
          <vee-field type="text" name="street"/>
        </div>
        <error-message name="street" class="error-message"/>
      </div>

    </div>

    <div>
      <div class="actions">
        <button class="ui black deny button"
                @click="$emit('close')">
          Mégsem
        </button>

        <button type="submit"
                class="ui positive right labeled icon button">
          Mentés
          <i class="checkmark icon"></i>
        </button>

      </div>
    </div>


  </vee-form>

</template>


<script setup>
import {  defineEmits } from 'vue';
import { useStore as useVuexStore } from 'vuex';
import { Form as VeeForm, Field as VeeField} from 'vee-validate';
import * as yup from 'yup';

const vuex_store = useVuexStore();
const emit = defineEmits(['close']);

const schema = yup.object().shape({
    name: yup.string().required('A telepítés helyét el kell nevezni!'),
    city: yup.string().required('A település nevét meg kell adni!'),
    street: yup.string().required('Megadása kötelező!'),
});

const initialValues = {
    name: vuex_store.state.updateContract.editedSite.nameAtContract,
    city: vuex_store.state.updateContract.editedSite.address.city,
    street: vuex_store.state.updateContract.editedSite.address.street
};


async function onIdentitySubmit(values) {
    const send_data_site = {
        id: vuex_store.state.updateContract.editedSite.id,
        nameAtContract: values.name,
        address: {
            id: vuex_store.state.updateContract.editedSite.address.id,
            city: values.city,
            street: values.street
        }
    };

    vuex_store.dispatch('updateContract/updateItem', send_data_site);
    emit('close');
}
</script>
