<template>
    <div class="ui celled list mr-1">
        <template v-for="discountSegment in props.siteAtPeriod.discountSegments" :key="discountSegment.id">
            <discount-segment-row :discountSegment="discountSegment"/>
        </template>
    </div>
</template>


<script setup>
import DiscountSegmentRow from "@/components/contract/update/modals/site-costs-at-period/discount-segment-row.vue";
import {defineProps} from "vue";

const props = defineProps(['siteAtPeriod'])

</script>

<style scoped>
.mr-1 {
    margin-right: 1rem;
    margin-bottom: 2rem;
}

</style>