<template>

    <Teleport to="body">

        <modal :show="show()" :size="'modal-medium'" @close="onCloseModal">
            <template #header>
                A költség mély tesztelése

            </template>
            <template #body>
                <div class="ui info message">
                    Legyél bejelentkezve az <strong>ADMIN-3</strong>-ba!
                    <p>Csak olyan telephelyet használj, amelyet <strong>tesztelés céjlából</strong> vettünk fel!</p>
                </div>

                <cost-deep-testing-form/>
            </template>
        </modal>

    </Teleport>


</template>

<script setup>
import Modal from "@/components/slots/modal.vue"
import {useStore as useVuexStore} from "vuex";
import CostDeepTestingForm from "@/components/contract/update/modals/cost-deep-testing/cost-deep-testing-form.vue";

const vuex_store = useVuexStore()


function show() {
    return vuex_store.state.updateContract.showCostDeepTestingModal;
}

function onCloseModal() {
    vuex_store.commit('updateContract/setShowCostDeepTestingModal', false)
}


</script>


<style scoped>

.need_actions {
    padding-top: 1rem;
}

</style>