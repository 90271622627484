<template>

  <table class="ui celled table">
    <thead>
    <tr>
      <th>Megnevezés</th>
      <th class="right aligned">Mennyiség</th>
      <th class="right aligned">Egyszeri díj</th>
      <th class="right aligned">Havidíj</th>
    </tr>
    </thead>
    <tbody>

    <tr v-for="(cost, index) in store.newCosts" :key="index">
      <td data-label="Megnevezés">{{ cost.name }}</td>
      <td data-label="Megnevezés" class="right aligned ">{{ cost.quantity }} db</td>
      <td data-label="Egyszeri díj" class="right aligned">{{ numberFormat(cost.priceOneTime, '0') }} Ft</td>
      <td data-label="Havidíj" class="right aligned">{{ numberFormat(cost.priceMonthly, '0') }} Ft/hó</td>
    </tr>
    </tbody>
    <tfoot class="full-width">
    <tr>
      <th class="sum_row">
        <div class="ui sixteen widht grid">
          <div class="eight wide column">
            Kibontás UTÁN összesen:
          </div>
          <div class="eight wide center aligned column">
            <span v-if="!store.isEqualMonthly || !store.isEqualOneTime"
                class="error">Eltérés van az összegekben!</span>
          </div>
        </div>
      </th>
      <th class="sum_row"></th>
      <th class="sum_row">
        <div class="ui equal width grid">
          <div class="center aligned column">
            <i v-if="store.isEqualOneTime" class="large check green icon"/>
            <i v-else class="large exclamation triangle yellow icon"/>
          </div>
          <div class="right aligned column">
            <strong>{{ numberFormat(store.sumOneTime, '0') }} Ft </strong>
          </div>
        </div>
      </th>
      <th class="right aligned sum_row">
        <div class="ui equal width grid">
          <div class="center aligned column">
            <i v-if="store.isEqualMonthly" class="large check green icon"/>
            <i v-else class="large exclamation triangle yellow icon"/>
          </div>
          <div class="right aligned column">
            <strong> {{ numberFormat(store.sumMonthly, '0') }} Ft/hó</strong>
          </div>
        </div>
      </th>
    </tr>
    <tr>
      <th class="sum_row">Kibontás ELŐTT összesen:</th>
      <th class="sum_row"></th>
      <th class="right aligned sum_row">{{ numberFormat(store.site.priceOneTime, '0') }} Ft</th>
      <th class="right aligned sum_row">{{ numberFormat(store.site.priceMonthly, '0') }} Ft/hó</th>
    </tr>
    </tfoot>


  </table>
</template>

<script setup>

import {useCostDetailingStore} from "@/store-pinia/costsDetailing";
import {numberFormat} from "@/_helper/numberFormat";

const store = useCostDetailingStore()


</script>

<style scoped>
.error {
  color: red;
  font-size: 1.3rem;
}

</style>