<template>
  <div class="ui sixteen width grid" v-if="store_page.priceList">
    <div class="seven wide column" s></div>
    <div class="six wide right aligned column">

      <div class="ui equal width grid">
        <div v-for="priceChangeDate in store_page.priceList.priceChangeDates" :key="priceChangeDate"
             class="column">
          <strong>{{ priceChangeDate }}</strong>
        </div>
      </div>
    </div>
    <div class="three wide column"></div>
  </div>
</template>


<script setup>

import {useContractPriceListStore} from "@/store-pinia/contractPriceList";

const store_page = useContractPriceListStore()

</script>


<style scoped>

</style>