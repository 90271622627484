<template>

    <vee-field placeholder="ID"
               :class="{error: `commissions[${props.idx}].id` in props.errors}"
               :name="`commissions[${props.idx}].id`"
               style="display: none">
    </vee-field>

    <div class="ui sixteen wide grid ">
        <div class="five wide column">
            <div class="field">
                <div class="ui input">
                    <vee-field as="select"
                               class="ui fluid dropdown"
                               :class="{error: `commissions[${props.idx}].salesManId` in props.errors}"
                               :name="`commissions[${props.idx}].salesManId`">

                        <option value=""></option>
                        <option v-for="user in store_chkUser.users" :key="user.id"
                                :value="`${user.oroUser.id}`">
                            {{ user.fullName }}
                        </option>

                    </vee-field>
                </div>
                <error-message :name="`commissions[${props.idx}].salesManId`" class="error-message"/>
            </div>
        </div>

        <div class="four wide column">
            <div class="field">
                <div class="ui input">
                    <vee-field type="date"
                               :class="{error: `commissions[${props.idx}].fromDate` in props.errors}"
                               :name="`commissions[${props.idx}].fromDate`">
                    </vee-field>
                </div>
                <error-message :name="`commissions[${props.idx}].fromDate`" class="error-message"/>
            </div>
        </div>

        <div class="four wide column">
            <div class="field">
                <div class="ui input">
                    <vee-field type="date"
                               :class="{error: `commissions[${props.idx}].toDate` in props.errors}"
                               :name="`commissions[${props.idx}].toDate`">
                    </vee-field>
                </div>
                <error-message :name="`commissions[${props.idx}].toDate`" class="error-message"/>
            </div>
        </div>

        <div class="two wide column">
            <div class="field">
                <div class="ui input">
                    <vee-field type="number" step="0.1"
                               :class="{error: `commissions[${props.idx}].amountRate` in props.errors}"
                               :name="`commissions[${props.idx}].amountRate`">
                    </vee-field>
                </div>
                <error-message :name="`commissions[${props.idx}].amountRate`" class="error-message"/>
            </div>
        </div>

        <div class="one wide center aligned column">
            <i class="trash red  icon" @click="removeItem(props.idx)"/>
        </div>
    </div>


</template>


<script setup>
import {ErrorMessage, Field as VeeField} from "vee-validate";
import {defineProps} from "vue";
import {useChkUserStore} from "@/store-pinia/api/chkUser";

const props = defineProps(['remove', 'idx', 'errors', 'values']);

const store_chkUser = useChkUserStore();


function removeItem(idx) {
    props.remove(idx)
}


</script>


<style scoped>

.error {
    color: red;
}

</style>