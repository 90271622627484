<template>
    <div id="main" class="ui container">
        <h1 class="ui header">Szerződés készítése</h1>
        <div class="ui segment">
            <step-header/>
        </div>
        <div class="ui segment">
            <step-a v-if="store_page.activeStep===1"/>
            <step-b v-if="store_page.activeStep===2"/>
            <step-c v-if="store_page.activeStep===3"/>
        </div>
    </div>
</template>

<script setup>

import {onMounted} from "vue";
import {useRoute} from "vue-router";
import StepHeader from "@/components/contract/create/stepHeader";
import StepA from "@/components/contract/create/step-a.vue";
import StepB from "@/components/contract/create/step-b.vue";
import StepC from "@/components/contract/create/step-c.vue";
import {useOpportunitiesStore} from "@/store-pinia/api/opportunities";
import {usePageCreateContractStore} from "@/store-pinia/pages/pageCreateContract";
import {useServiceCompanyStore} from "@/store-pinia/api/serviceCompany";
import {useProductsStore} from "@/store-pinia/api/products";


const store_page = usePageCreateContractStore();
const store_service_company = useServiceCompanyStore();
const store_products = useProductsStore()

onMounted(() => {
    const route = useRoute();
    const store_opportunity = useOpportunitiesStore()
    store_opportunity.fetchOpportunity(route.params.opportunity_id)
    store_service_company.fetchServiceCompanies();
    store_products.fetchProducts();

})


</script>


<style scoped>

</style>
