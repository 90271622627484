

export function siteStatusName(site_status_name) {
    let statusName = site_status_name
    if (site_status_name === 'new') {
        statusName = 'Új'
    } else if (site_status_name === 'active') {
        statusName = 'Aktív'
    } else if (site_status_name === 'terminated') {
        statusName = 'Megszűnt'
    }

    return statusName
}

