<template>
    <Teleport to="body">
        <Modal :show="show()" :size="'modal-medium'" @close="onCloseClick">
            <template #header>Ügyfél adatok szerkesztése</template>
            <template #body>

                <pre v-if="false">
                    {{ vuex_store.state.updateContract.contract.customer }}
                </pre>

                <div class="ui warning message" v-if="false">
                    A cég neve, székhelye, cégjegyzékszáma felülírásra fog kerülni az új számlázás indításával.
                </div>


                <customer-edit-form/>


            </template>
        </Modal>
    </Teleport>
</template>

<script setup>

import Modal from "@/components/slots/modal.vue";
import {useStore as useVuexStore} from "vuex";
import CustomerEditForm from "@/components/contract/update/customer-edit-form.vue";

const vuex_store = useVuexStore();


function show() {
    return vuex_store.state.updateContract.showEditCustomerModal;
}


</script>

