<template>
    <div class="person-name">
        {{ props.person.name }}
    </div>

    <div class="ui list">
        <div v-for="email in props.person.emails" :key="email"
             class="item">
            {{ email }}
        </div>

    </div>

    <div v-for="phone in props.person.phones" :key="phone"
         class="item">
        {{ phone }}
    </div>
</template>


<script setup>

import {defineProps} from 'vue';

const props = defineProps({
    person: {
        type: Object,
        required: true
    }
});
</script>


<style scoped>
.person-name {
    font-weight: bolder;
}

</style>