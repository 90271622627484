import {defineStore} from "pinia";
import setupAuthHeaderFor from "@/_helper/setupAxiosHeader";


import axios from "axios";
import {useErrorsStore} from "@/store-pinia/errors";
import {useOroStore} from "@/store-pinia/oro";
import {useNotificationsStore} from "@/store-pinia/notifications";
import {useCalculationStore} from "@/store-pinia/calculation";


import vuex_store from '@/store'


export const useSitesStore = defineStore({
    id: 'site',
    state: () => {
        return {
            priceList: [],
            sending: false,
            fetching: false,
            site: null,
            sites: [],

            createdSite: null,
            newCosts: [],
            costsInPeriod: null

        };
    },
    actions: {
        setPriceList() {
            const store_calculation = useCalculationStore()
            this.priceList = store_calculation.priceList;
        },
        setSending(is_sending) {
            this.sending = is_sending
        },
        setFetching(is_fetching) {
            this.fetching = is_fetching
        },
        setSite(site) {
            this.site = site
        },
        setSites(sites_arr) {
            this.sites = sites_arr
        },

        setCreatedSite(created_site) {
            this.createdSite = created_site
        },
        setNewCosts(new_costs_arr) {
            this.newCosts = new_costs_arr
        },
        setCostsInPeriod(costs_in_period) {
            this.costsInPeriod = costs_in_period
        },


        async createSite(contract_id, site_data) {
            try {
                const store_oro = useOroStore();
                const store_notifications = useNotificationsStore();

                const url = `${store_oro.host}/api/create-site/${contract_id}`;
                this.setSending(true);

                const axiosConfig = setupAuthHeaderFor('wsse');
                const response = await axios.post(url, site_data, axiosConfig);
                this.setCreatedSite(response.data);
                store_notifications.addNotification('A telephely létrehozása sikeres');

                await this.refreshContract(contract_id);
            } catch (err) {
                const store_errors = useErrorsStore();
                const error = {
                    message: err.message,
                    message_public: 'A telephely létrehozása sikertelen volt!'
                };
                store_errors.addError(error);
            } finally {
                this.setSending(false);
            }
        },


        async updateSite(contract_id, site_id, site_data) {
            try {
                const store_oro = useOroStore();
                const store_notifications = useNotificationsStore();

                const url = `${store_oro.host}/api/site-update/${site_id}`;
                this.setSending(true);

                const axiosConfig = setupAuthHeaderFor('wsse');
                const response = await axios.post(url, site_data, axiosConfig);
                this.setCreatedSite(response.data);
                store_notifications.addNotification('A telephely frissítése sikeres');

                await this.refreshContract(contract_id);
            } catch (err) {
                const store_errors = useErrorsStore();
                const error = {
                    message: err.message,
                    message_public: 'A telephely frissítése sikertelen volt!'
                };
                store_errors.addError(error);
            } finally {
                this.setSending(false);
            }
        },


        async siteCostsAtPeriod(site_id, period_data) {
            try {

                this.setSending(true);

                const store_oro = useOroStore();
                const url = `${store_oro.admin3.host}/show-site-costs-at-period/${site_id}`;

                const axiosConfig = setupAuthHeaderFor('jwt');
                const response = await axios.post(url, period_data, axiosConfig);

                this.setCostsInPeriod(response.data);


            } catch (err) {
                const store_errors = useErrorsStore();
                const error = {
                    message: err.message,
                    message_public: 'Az időszakba eső költségek lekérdezése sikertelen!'
                };
                store_errors.addError(error);
            } finally {
                this.setSending(false);
            }
        },

        async updateSiteDiscounts(site_id, discounts_arr_data) {
            try {
                this.setSending(true);

                const store_oro = useOroStore();
                const url = `${store_oro.admin3.host}/api/site-discounts/${site_id}`;

                const axiosConfig = setupAuthHeaderFor('jwt');
                const response = await axios.post(url, discounts_arr_data, axiosConfig);

                const contract_id = vuex_store.state.updateContract.contract.id;
                await this.refreshContract(contract_id);

                const store_notifications = useNotificationsStore();
                store_notifications.addNotification('A telephely kedvezményeinek beállítása sikeres');

                return response.data;

            } catch (err) {
                let errorMessage = 'A telephely kedvezményeinek beállítása sikertelen!';
                let statusCode = err.response ? err.response.status : null;

                const error = {
                    code: statusCode,
                    message: err.message,
                    message_public: errorMessage
                };
                const store_errors = useErrorsStore();
                store_errors.addError(error);
            } finally {
                this.setSending(false);
            }
        },

        async updateSiteCommissions(site_id, commissions_arr_data) {
            try {

                this.setSending(true);

                const store_oro = useOroStore();
                const url = `${store_oro.admin3.host}/api/site-commissions/${site_id}`;

                const axiosConfig = setupAuthHeaderFor('jwt');
                const response = await axios.post(url, commissions_arr_data, axiosConfig);

                const contract_id = vuex_store.state.updateContract.contract.id;
                await this.refreshContract(contract_id);

                const store_notifications = useNotificationsStore();
                store_notifications.addNotification('A telephely jutalék beállítása sikeres');
                return response.data;

            } catch (err) {
                let errorMessage = 'A telephely kedvezményeinek beállítása sikertelen!';
                let statusCode = err.response ? err.response.status : null;
                const error = {
                    code: statusCode,
                    message: err.message,
                    message_public: errorMessage
                };
                const store_errors = useErrorsStore();
                store_errors.addError(error);
            } finally {
                this.setSending(false);
            }
        },

        async endDateOfLastBilledPeriod(site_id, payload_data) {
            try {

                this.setSending(true);

                const store_oro = useOroStore();
                const url = `${store_oro.admin3.host}/api/sites/${site_id}`;

                const axiosConfig = setupAuthHeaderFor('jwt');
                axiosConfig.headers['Content-Type'] = 'application/merge-patch+json';
                const response = await axios.patch(url, payload_data, axiosConfig);

                const contract_id = vuex_store.state.updateContract.contract.id;
                await this.refreshContract(contract_id);

                return response.data;

            } catch (err) {
                const store_errors = useErrorsStore();
                const error = {
                    message: err.message,
                    message_public: 'Sikertelen beállítás: A telephely utolsó kiállított számlájának dátuma'
                };
                store_errors.addError(error);
            } finally {
                this.setSending(false);
            }
        },

        async downloadPDF(site_id, pdf_type, payload_data) {
            try {

                const store_oro = useOroStore();

                let url = '';
                if(pdf_type === 'site-reverse'){
                    url = `${store_oro.admin3.host}/pdf-site-costs-at-period/reverse/${site_id}`;
                }

                if(pdf_type === 'site-appendix'){
                    url = `${store_oro.admin3.host}/pdf-site-costs-at-period/normal/${site_id}`;
                }

                if(pdf_type === 'site-view'){
                    url = `${store_oro.admin3.host}/pdf-site-costs-at-period/view/${site_id}`;
                }

                
                const axiosConfig = setupAuthHeaderFor('jwt');
                axiosConfig.headers['Content-Type'] = 'application/json';
                axiosConfig.responseType = 'blob';
                const response = await axios.post(url, payload_data, axiosConfig);

                const blob = new Blob([response.data], { type: 'application/pdf' });

                // Blob URL létrehozása és letöltés indítása
                const downloadUrl = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.setAttribute('download', 'downloaded.pdf'); // A letöltendő fájl neve
                document.body.appendChild(link);
                link.click();

                link.remove(); // Link elem eltávolítása a DOM-ból
                window.URL.revokeObjectURL(downloadUrl); // Blob URL felszabadítása


            } catch (err) {
                const store_errors = useErrorsStore();
                const error = {
                    message: err.message,
                    message_public: 'Sikertelen PDF letöltés'
                };
                store_errors.addError(error);
            } finally {
                this.setSending(false);
            }
        },


        async refreshContract(contract_id) {
            vuex_store.commit('updateContract/setShowNewSiteModal', false, {root: true});
            await vuex_store.dispatch('updateContract/fetchContract', contract_id, {root: true})
        }

    },
    getters: {},
});

