<template>
  <vee-form
      class="ui form"
      @submit="onSubmit"
      :initial-values="initialValues"
      :validation-schema="schema">

    <div
        v-if="store.editedContractPrice
        && store.editedContractPrice.contractPriceItem.id === props.contractPrice.contractPriceItem.id">

      <div class="field">
        <div class="ui mini input">
          <vee-field type="text" placeholder="Új név" name="name"/>
        </div>
        <error-message name="name" class="error-message"/>
      </div>

      <div class="field">
        <div class="ui mini input">
          <vee-field type="number" placeholder="Új ár" name="price"/>
        </div>
        <error-message name="price" class="error-message"/>
      </div>

      <div class="ui buttons">
        <button type="submit" class="ui positive mini button">Mentés</button>
        <div class="or" data-text="?"></div>
        <button class="ui negative mini  button"
                @click="store.setEditedContractPrice(null)">Mégsem
        </button>
      </div>

    </div>
  </vee-form>
</template>

<script setup>
import {useContractPriceListStore} from "@/store-pinia/contractPriceList";
import {defineProps} from "vue";
import {ErrorMessage, Field as VeeField, Form as VeeForm} from 'vee-validate'
import * as yup from "yup";

const props = defineProps(['contractPrice']);
const store = useContractPriceListStore();


const initialValues = {
  name: store.editedContractPrice.contractPriceItem.name,
  price: store.editedContractPrice.priceChanges[store.editedContractPrice.priceChanges.length - 1].price
}

const schema = yup.object({
  name: yup.string().required('Megadása kötelező!'),
  price: yup.number().required('meg kell adni!').min(0, 'Ugye nem mi fogunk fizetni?')
})


function onSubmit(values) {

  const send_data = {
    name: values.name,
    price: parseInt(values.price),
  }
  store.patchPriceListChange(send_data)
}


</script>

<style scoped>
.form {
  margin: 15px 0;
}

.error-message {
  color: red
}

</style>