<template>
  <div class="ui segment">
    <h3>Költségek</h3>
    <prices-table :calculation="calculation" />
  </div>
</template>

<script>
import PricesTable from "@/components/contract/update/item-edited/pricesTable";

export default {
  name: "PricesSegment",
  props: ['item'],
  components: {
    PricesTable
  },
  computed:{
     calculation(){
       return this.$store.state.updateContract.editedCalculationResult
     }
  }
}
</script>

<style scoped>

</style>
