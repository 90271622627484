import {defineStore} from "pinia";
import axios from "axios";
import {useErrorsStore} from "@/store-pinia/errors";
import {useOroStore} from "@/store-pinia/oro";
import setupAuthHeaderFor from "@/_helper/setupAxiosHeader";


export const useApiQuotationStore = defineStore({
    id: 'apiQuotation',
    state: () => {
        return {
            XHR: false,
            quotation: null,
            quotations: []
        };
    },
    actions: {

        setXHR(xhr_value) {
            this.XHR = xhr_value
        },
        setQuotation(quotation) {
            this.quotation = quotation
        },
        setQuotations(quotations) {
            this.quotations = quotations
        },


        async createQuotation(data) {

            this.setXHR(true);

            const store_oro = useOroStore();
            const store_errors = useErrorsStore();

            const url = `${store_oro.host}/api/create-simple-quotation/${data.opportunity}`

            const axiosConfig = setupAuthHeaderFor('wsse');
            await axios.post(url, data, axiosConfig)
                .then((resp) => {
                    this.setQuotation(resp.data)
                    return resp.data
                })
                .catch(err => {
                    const error = {
                        code: err.response.status,
                        message: err.message,
                        message_public: 'Az árajánlat létrehozása sikertelen!'
                    }
                    store_errors.addError(error)
                })

            this.setXHR(false);
        },

        async fetchQuotation(quotation_id) {

            const store_oro = useOroStore();
            const store_errors = useErrorsStore();

            this.setXHR(true)
            const axiosConfig = setupAuthHeaderFor('wsse');
            const url_quotation = store_oro.host + '/api/simple-quotation-info/' + quotation_id

            await axios.get(url_quotation, axiosConfig)
                .then(resp => {
                    this.setQuotation(resp.data);
                })
                .catch(err => {
                    const error = {
                        code: err.response.status,
                        message: err.message,
                        message_public: 'Az árajánlat lekérése sikertelen!'
                    }
                    store_errors.addError(error)
                })
            this.setXHR(false)
        },

        async addNewPriceListElement(quotation_id, data) {

            const store_oro = useOroStore();
            const store_errors = useErrorsStore();

            this.setXHR(true)
            const axiosConfig = setupAuthHeaderFor('wsse');
            const url_quotation = store_oro.host + '/api/create-new-quotation-pricelist-item/' + quotation_id

            await axios.post(url_quotation, data, axiosConfig)
                .then(() => {
                    this.fetchQuotation(quotation_id);
                })
                .catch(err => {
                    const error = {
                        code: err.response.status,
                        message: err.message,
                        message_public: 'Az új tétel hozzáadása az árajánlat árlistájához sikertelen!'
                    }
                    store_errors.addError(error)
                })
            this.setXHR(false)
        },


        async editPriceListElement(priceList_item_id, data) {

            const store_oro = useOroStore();
            const store_errors = useErrorsStore();

            this.setXHR(true)
            const axiosConfig = setupAuthHeaderFor('wsse');
            const url = store_oro.host + '/api/edit-quotation-priceList-item/' + priceList_item_id

            await axios.post(url, data, axiosConfig)
                .then((response) => {
                    this.fetchQuotation(response.data.quotation_id);
                })
                .catch(err => {
                    const error = {
                        code: err.response.status,
                        message: err.message,
                        message_public: 'Az árajánlathoz tartozó árlista-tétel módosítása sikertelen!'
                    }
                    store_errors.addError(error)
                })
            this.setXHR(false)
        },


        async addToQuotationItems(quotation_id, data) {

            const store_oro = useOroStore();
            const store_errors = useErrorsStore();

            this.setXHR(true)
            const axiosConfig = setupAuthHeaderFor('wsse');
            const url = store_oro.host + '/api/add-to-quotation/' + quotation_id

            await axios.post(url, data, axiosConfig)
                .then(() => {
                    this.fetchQuotation(quotation_id);
                })
                .catch(err => {
                    const error = {
                        code: err.response.status,
                        message: err.message,
                        message_public: 'Az új tétel hozzáadása a árajánlathoz sikertelen!'
                    }
                    store_errors.addError(error)
                })
            this.setXHR(false)
        },


        async deleteQuotationItem(quotation_item_id) {

            const store_oro = useOroStore();
            const store_errors = useErrorsStore();

            this.setXHR(true)
            const axiosConfig = setupAuthHeaderFor('wsse');
            const url = store_oro.host + '/api/delete-quotation-item/' + quotation_item_id

            await axios.delete(url, axiosConfig)
                .then(() => {
                    this.fetchQuotation(this.quotation.id);
                })
                .catch(err => {
                    const error = {
                        code: err.response.status,
                        message: err.message,
                        message_public: 'Az árajánlat-tétel törlése sikertelen!'
                    }
                    store_errors.addError(error)
                })
            this.setXHR(false)
        },

    },
    getters: {

        availableCosts: (store) =>
            store.quotation.quotationPriceListItems.filter(
                ({categoryName}) =>
                    categoryName === "monthly_fee" || categoryName === "install_cost"
            )


    },
});

