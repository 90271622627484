<template>

  <div>


    <div class="field" :class="{error: 'personName' in errors}">
      <label>Személy neve:</label>
      <div class="ui input">
        <vee-field type="text" placeholder="Személy neve:" name="personName"/>
      </div>
      <error-message name="personName" class="error-message"/>
    </div>

    <div class="field" :class="{error: 'email' in errors}">
      <label>E-mail:</label>
      <div class="ui input">
        <vee-field type="text" placeholder="E-mail cím:" name="email"/>
      </div>
      <error-message name="email" class="error-message"/>
    </div>

    <div class="field" :class="{error: 'phone' in errors}">
      <label>Telefonszám:</label>
      <div class="ui input">
        <vee-field type="text" placeholder="Telefonszám:" name="phone"/>
      </div>
      <error-message name="phone" class="error-message"/>
    </div>


  </div>


</template>

<script>
import {Field, ErrorMessage} from "vee-validate";

export default {
  name: "ApproveFormFields",
  props: ['errors', 'fields'],
  components: {
    VeeField: Field,
    ErrorMessage

  }
}
</script>

<style scoped>

</style>
