<template>

  <Teleport to="body">

    <modal :show="show" :size="'modal-large'"
           @close="onCloseModal">
      <template #header>
        <div class="ui sixteen widht grid">
          <div class="eight wide column">
            Új költség hozzáadása
          </div>
          <div class="four wide conter aligned column">
            {{ site().nameAtContract }}
          </div>
          <div class="four wide column">
            {{ site().address.city }}, {{ site().address.street }}
          </div>
        </div>
      </template>

      <template #body>

        <notifications-component/>

        <add-new-cost-form/>

        <new-costs/>



        <add-new-costs-actions @close="onCloseModal"/>


      </template>
    </modal>

  </Teleport>
</template>


<script>
import Modal from "@/components/slots/modal.vue";
import {mapState} from "vuex";

import AddNewCostForm from "@/components/contract/update/modals/add-new-cost/add-new-cost-form.vue";
import NewCosts from "@/components/contract/update/modals/add-new-cost/add-new-cost-new-costs.vue"
import AddNewCostsActions from "@/components/contract/update/modals/add-new-cost/add-now-cost-actions.vue"

import NotificationsComponent from "@/components/NotificationsComponent.vue";
import {useCostsStore} from "@/store-pinia/api/costs";


export default {
  name: "modal-costs-detailing",


  components: {
    NewCosts,
    Modal,
    NotificationsComponent,
    AddNewCostForm,
    AddNewCostsActions
  },
  methods: {
    onCloseModal() {
      this.$store.commit('updateContract/setShowAddCostModal', false)
      this.$store.commit('updateContract/setEditedSite', null)
      this.$emit('close')
    },

    site() {
      const store_costs = useCostsStore();
      return store_costs.site
    }
  },
  computed: {
    ...mapState({
      show: state => state.updateContract.showAddCostModal,
    }),

  }

}

</script>


<style scoped>

</style>
