import {defineStore} from "pinia";
import {useCalculationStore} from "@/store-pinia/calculation";
import {useOroStore} from "@/store-pinia/oro";
import {useGeneralPriceListStore} from "@/store-pinia/api/generalPriceList";
import setupAuthHeaderFor from "@/_helper/setupAxiosHeader";
import axios from "axios";

export const useCreateQuotationPageStore = defineStore({
    id: 'createQuotationPage',
    state: () => {
        return {
            sending: false,
            success: false,
            product: null,
            serviceCompany: null,
            activeStep: 1,
            checked: false,
            needs: [],
            priceList: {
                source: null,
                items: []
            },
            customer: null,
            contract: null
        };
    },
    actions: {

        setSending(sending_value) {
            this.sending = sending_value
        },
        setSuccess() {
            this.success = true
        },
        setProduct(product) {
            this.product = product
        },
        setServiceCompany(service_company) {
            this.serviceCompany = service_company
        },
        setActiveStep(step) {
            this.activeStep = step
        },
        setChecked(is_checked) {
            this.checked = is_checked
        },
        setNeeds(needs_arr) {
            this.needs = needs_arr
        },
        setPriceList(price_list) {
            this.priceList = price_list
        },
        setCustomer(customer) {
            this.customer = customer
        },
        setContract(contract) {
            this.contract = contract
        },


        async fetchContractByCustomerAndProduct(customer_id, product_id) {
            try {
                const storeCalculation = useCalculationStore();
                const storeMotherPriceList = useGeneralPriceListStore();
                const storeOro = useOroStore();

                const endpoint = '/api/contract-by-customer-and-product';

                const axiosConfig = setupAuthHeaderFor('wsse');
                const urlContract = `${storeOro.host}${endpoint}/${customer_id}/${product_id}`;

                const response = await axios.get(urlContract, axiosConfig);

                const priceList = {}

                if (response.data.id) {
                    priceList.source = 'contract'
                    priceList.items = response.data.priceList
                    this.setContract(response.data)
                } else {
                    priceList.source = 'mother'
                    priceList.items = storeMotherPriceList.priceList;
                    this.setContract(null)
                }

                this.setPriceList(priceList)
                storeCalculation.setPriceList(priceList.items)

            } catch (error) {
                console.error("Hiba történt a szerződés lekérdezése közben:", error);
            }
        },

    },
    getters: {},
});

