<template>
  <div class="ui segment">
    <h3>Melyik szolgáltató készíti az árajánlatot?</h3>

    <div class=" ui five cards">
      <div v-for="serviceCompany in store_service_company.serviceCompanies" :key="serviceCompany.id"
           class="card pointer"
           :class="{selected: store_page.serviceCompany && serviceCompany.id === store_page.serviceCompany.id}"
           @click="store_page.setServiceCompany(serviceCompany)">
        <div class="content center aligned">
          {{ serviceCompany.name }}
        </div>
      </div>
    </div>


  </div>


</template>

<script setup>
import {useCreateQuotationPageStore} from "@/store-pinia/pages/createQuotationPage";
import {useServiceCompanyStore} from "@/store-pinia/api/serviceCompany";

const store_service_company = useServiceCompanyStore()
const store_page = useCreateQuotationPageStore()


</script>

<style scoped>
.pointer {
  cursor: pointer;
}

.selected {
  font-size: larger;
  font-weight: bolder;
  color: red;
}
</style>