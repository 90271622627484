<template>
    <vee-form class="ui form"
              @submit="onSubmit"
              :validation-schema="schema"
              :initial-values="initialValues"
              v-slot="{errors}">

        <div class="ui sixteen widht grid">
            <div class="twelve wide column">

                <div class="fields">
                    <div class="field" :class="{error: 'name' in errors}">

                        <div class="ui input">
                            <vee-field type="text" placeholder="Neve:" name="name"/>
                        </div>
                        <error-message name="name" class="error-message"/>
                    </div>

                    <div class="field" :class="{error: 'price' in errors}">
                        <div class="ui input">
                            <vee-field type="number" placeholder="Egységára:" name="price"/>
                        </div>
                        <error-message name="price" class="error-message"/>
                    </div>

                </div>

            </div>

            <div class="four wide column">


                <div class="ui buttons">
                    <button type="submit"
                            class="ui positive mini button">Mentés
                    </button>
                    <div class="or" data-text="?"></div>
                    <button type="button"
                            class="ui negative mini  button"
                            @click="store.setShowNewValue(props.categoryName, false)">Mégsem
                    </button>
                </div>
            </div>
        </div>

    </vee-form>


</template>

<script setup>
import {ErrorMessage, Field as VeeField, Form as VeeForm} from 'vee-validate'
import {useContractPriceListStore} from "@/store-pinia/contractPriceList";
import {defineProps} from "vue";
import * as yup from "yup";

const props = defineProps(['categoryName']);
const store = useContractPriceListStore()


const initialValues = {
    name: '',
    price: 0

}


const schema = yup.object().shape(
    {
        name: yup.string().required('Megadása kötelező'),
        price: yup.number().required('Megadása kötlező').min(1, 'Ingyen nem adhatjuk!'),
    }
);


function onSubmit(values) {

    const send_data = {
        name: values.name,
        price: parseInt(values.price),
        categoryName: props.categoryName
    }

    store.createNewPriceListItem(send_data);

}


</script>

<style scoped>
.error {
    color: red;
}
</style>