<template>
  <div class="ui six wide column">

    <h3>Levelezési cím</h3>
    <div class="field" :class="{error: 'postalCode' in errors}">
      <label>Irányítószám:</label>
      <div class="ui input">
        <vee-field type="text" placeholder="Irányítószám" name="postalCode"/>
      </div>
      <error-message name="postalCode" class="error-message"/>
    </div>


    <div class="field" :class="{error: 'city' in errors}">
      <label>Település:</label>
      <div class="ui input">
        <vee-field type="text" placeholder="Település" name="city"/>
      </div>
      <error-message name="city" class="error-message"/>
    </div>

    <div class="field" :class="{error: 'street' in errors}">
      <label>Utca, házszám</label>
      <div class="ui input">
        <vee-field type="text" placeholder="utca, házszám" name="street"/>
      </div>
      <error-message name="street" class="error-message"/>
    </div>
  </div>
</template>

<script>

import {Field, ErrorMessage} from 'vee-validate'

export default {
  name: "CustomerPostingAddressesFormFields",
  props: ['errors', 'fields'],
  components: {
    VeeField: Field, ErrorMessage
  }
}
</script>

<style scoped>
.error-message {
  color: red;
}
</style>
