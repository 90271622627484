<template>
  <Transition name="modal">

    <div v-if="show" class="modal-mask">

      <div class="modal-wrapper">
        <div class=" modal-container" :class="size">


          <div class="modal-header">
            <div class="ui grid">
              <div class="fifteen wide column ">
                <h3 class="ui header">
                  <slot name="header">Feljéc</slot>
                </h3>
              </div>

              <div class="one wide column">
                <i class="close red icon" @click="$emit('close')"></i>
              </div>
            </div>

          </div>


          <div class="modal-body">
            <slot name="body">default body</slot>
          </div>


          <div class="modal-footer">

          </div>
        </div>
      </div>
    </div>

  </Transition>
</template>


<script>
export default {
  name: "ModalComponent",
  props: {
    show: Boolean,
    size: String
  }
}
</script>


<style>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  margin: 0 auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}


.modal-small {
  width: 500px;
}


.modal-medium {
  width: 900px;
}

.modal-large {
  width: 1200px;
}


.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
  max-height: 40rem;
  overflow-y: auto;
  overflow-x: hidden;

}

.modal-default-button {
  float: right;
}

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  transform: scale(3);
}
</style>
