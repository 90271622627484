<template>


    <div class="ui segment">
        <div class="ui middle aligned divided list">
            <condition-item>
                <template v-slot:name>Szerződéskötési dátum</template>
                <template v-slot:value>{{ contract ? contract.dateOfContract : '' }}</template>
            </condition-item>

            <condition-item>
                <template v-slot:name>Próba szerződés?</template>
                <template v-slot:value>
                    <span v-if="contract && contract.isTrial">Igen, próbaszerződés</span>
                    <span v-else>Nem</span>
                </template>
            </condition-item>

            <condition-item>
                <template v-slot:name>Hűségidő</template>
                <template v-slot:value>{{ loyaltyName }}</template>
            </condition-item>


            <condition-item>
                <template v-slot:name>Üzletkötők</template>
                <template v-slot:value>
                    <span class="under-construction">Üzletkötő  #1; Üzletkötő #2</span>
                </template>
            </condition-item>

            <condition-item>
                <template v-slot:name>A cégadatok frissítése</template>
                <template v-slot:value>
                    {{ contract.updateCustomerFromOfficialSource ? 'Igen' : 'Nem' }}
                </template>
            </condition-item>

            <condition-item>
                <template v-slot:name>A cég hivatalos e-mail címe</template>
                <template v-slot:value>
                    {{ contract.customer.mainEmail }}
                </template>
            </condition-item>


        </div>
    </div>


</template>


<script setup>
import ConditionItem from "@/components/contract/create/checkOneCondition";
import {useStore as useVuexStore} from "vuex";
import {computed} from "vue";


const store_vuex = useVuexStore();

const contract = computed(() => store_vuex.state.updateContract.contract);

const loyaltyName = computed(() => {
    let name = '???';
    if (!contract.value) {
        name = '';
    } else if (contract.value.loyalty === 'loyalty_year_1') {
        name = '1 év';
    } else if (contract.value.loyalty === 'loyalty_year_2') {
        name = '2 év';
    } else if (contract.value.loyalty === 'loyalty_year_3') {
        name = '3 év';
    }
    return name;
});


</script>


<style scoped>

</style>