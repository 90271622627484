import {defineStore} from 'pinia';
import axios from 'axios';
import setupAuthHeaderFor from "@/_helper/setupAxiosHeader";
import {useErrorsStore} from '../errors';
import {useOroStore} from '@/store-pinia/oro';

const url_general_price_list = '/api/general-price-list';

export const useGeneralPriceListStore = defineStore({
    id: 'generalPriceList',
    state: () => ({
        priceList: [],
    }),
    actions: {
        setPriceList(priceList) {
            this.priceList = priceList;
        },
        async fetchPriceList() {
            const store_oro = useOroStore();
            const store_errors = useErrorsStore();

            const url = `${store_oro.host}${url_general_price_list}`;

            const axiosConfig = setupAuthHeaderFor('wsse');

            try {
                const {data} = await axios.get(url, axiosConfig);
                this.setPriceList(data);
            } catch (error) {
                store_errors.addError({
                    message: error.message,
                    message_public: 'Az árlista elérése sikertelen!',
                });
            }
        },
    },
    getters: {
        productPriceList: (state) => (product_id) =>
            state.priceList.filter((el) => el.product.id === product_id),
    },
});