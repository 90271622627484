import {defineStore} from "pinia";

export const useUpdateQuotationPageStore = defineStore({
    id: 'page-update-quotation',
    state: () => {
        return {
            focusPriceListElement: null,
            showModalCreatePriceList: false,
            showModalEditPriceList: false,
            showModalAddToItems: false,
            sending: false,
        }
    },
    actions: {
        setFocusPriceListElement(priceList_element) {
            this.focusPriceListElement = priceList_element
        },
        setSending(sending_value) {
            this.sending = sending_value
        },
        setShowModalCreatePriceList(show_value) {
            this.showModalCreatePriceList = show_value
        },
        setShowModalEditPriceList(show_value) {
            this.showModalEditPriceList = show_value
        },
        setShowModalAddToItems(show_value) {
            this.showModalAddToItems = show_value
        },

    },
    getters: {},
});

